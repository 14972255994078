<template>
  <div>
    <div class="table-box">
      <div class="d-flex justify-space-between">
        <div class="max-w-230">
          <h3 class="text-center">Mandatory Requirements - P0</h3>
        </div>
        <div class="max-w-230">
          <h3 class="text-center">Additional Suggestions - P1</h3>
        </div>
        <div class="max-w-230">
          <h3 class="text-center">Tips</h3>
        </div>
      </div>
      <div class="d-flex justify-space-between">
        <div class="max-w-230">
          <div class="custom-textbox mt-1">
            <em class="last-update">Last Updated: {{ lastUpdatedStr }}</em>
            <div class="custom-textbox-list">
              <div>
                <Textbox
                  @change="
                    recruiterNotes.mandatoryRequirements = $event;
                    notesChanged = true;
                  "
                  :items="recruiterNotes.mandatoryRequirements"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="max-w-230">
          <div class="custom-textbox mt-1">
            <em class="last-update">Last Updated: {{ lastUpdatedStr }}</em>
            <div class="custom-textbox-list">
              <Textbox
                @change="
                  recruiterNotes.additionalSuggestions = $event;
                  notesChanged = true;
                "
                :items="recruiterNotes.additionalSuggestions"
              />
            </div>
          </div>
        </div>
        <div class="max-w-230">
          <div class="custom-textbox mt-1">
            <em class="last-update">Last Updated: {{ lastUpdatedStr }}</em>
            <div class="custom-textbox-list">
              <div class="top-list">
                <Textbox
                  @change="
                    recruiterNotes.tips = $event;
                    notesChanged = true;
                  "
                  :items="recruiterNotes.tips"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-4">
      <v-tooltip v-model="saveSuccess" right>
        <template v-slot:activator="{}">
          <v-btn
            class="save-btn"
            height="32"
            elevation="0"
            @click="onSave"
            :class="{ active: notesChanged }"
          >
            Save
          </v-btn>
        </template>
        <span>Saved</span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import Textbox from "../customs/Textbox";
import { mapGetters } from "vuex";
import { get, post } from "@/utils";

export default {
  name: "RecruiterNotes",
  components: {
    Textbox,
  },
  data() {
    return {
      recruiterNotes: {
        mandatoryRequirements: [],
        additionalSuggestions: [],
        tips: [],
      },
      lastUpdatedDate: "",
      saveSuccess: false,
      notesChanged: false,
      loading: true,
    };
  },
  mounted() {
    this.fillData();
  },
  computed: {
    ...mapGetters("operations", ["projectSuccessData"]),
    lastUpdatedStr() {
      if (this.lastUpdatedDate === undefined || this.lastUpdatedDate === "")
        return "N/A";
      console.log(this.lastUpdatedDate);
      const dateOptions = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      const dateSplit = this.lastUpdatedDate.split(/[^0-9]/);
      let utcDate = new Date(
        Date.UTC(
          dateSplit[0],
          dateSplit[1] - 1,
          dateSplit[2],
          dateSplit[3],
          dateSplit[4],
          dateSplit[5]
        )
      );
      return utcDate.toLocaleDateString("en-US", dateOptions);
    },
  },

  methods: {
    async fillData() {
      const res = await get(
        `/projects/hard_requirements?project_id=${this.projectSuccessData.data.project_id}`
      );
      if (res.status !== 200 || !res.data.recruiter_notes) {
        console.log(res);
        return;
      }
      const recruiterNotesData = res.data.recruiter_notes;
      this.recruiterNotes = {
        mandatoryRequirements: recruiterNotesData.mandatory_requirements,
        additionalSuggestions: recruiterNotesData.additional_suggestions,
        tips: recruiterNotesData.tips,
      };
      this.lastUpdatedDate = res.data.recruiter_notes_updated_at;
    },

    async onSave() {
      const payload = {
        recruiter_notes: {
          mandatory_requirements: this.recruiterNotes.mandatoryRequirements,
          additional_suggestions: this.recruiterNotes.additionalSuggestions,
          tips: this.recruiterNotes.tips,
        },
        project_id: this.projectSuccessData.data.project_id,
      };
      const res = await post(`/projects/hard_requirements`, payload);
      if (res.status !== 200) return;
      this.lastUpdatedDate = res.data;
      this.notesChanged = false;
      this.saveSuccess = true;
      setTimeout(() => {
        this.saveSuccess = false;
      }, 2500);
    },
  },
};
</script>
<style lang="scss" scoped>
.table-box {
  padding: 16px 40px 28px;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  position: relative;
  overflow-y: auto;
  font-size: 14px;
  color: #111827;
  font-weight: 500;
  min-height: 100%;
  h3 {
    font-size: 14px;
    font-weight: 700;
    color: #111827;
    text-transform: uppercase;
  }
}
.custom-textbox {
  width: 100%;
  .last-update {
    font-size: 10px;
    color: #384955;
  }
}
.max-w-230 {
  max-width: 230px;
  width: 100%;
}
.save-btn {
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  font-size: 14px;
  color: #6b7280;
  background-color: transparent !important;
  text-transform: none;
  &.active {
    background-color: #4646ab !important;
    color: #fff;
  }
}
.v-tooltip__content {
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 600;
  background: #111827;
  box-shadow: none;
  border-radius: 2px !important;
  min-width: 80px;
  text-align: center;
  line-height: 14px !important;
}
.custom-textbox-list {
  border: 1px solid #d8d8d8;
  padding: 6px 12px;
  height: 250px;
  overflow: scroll;
}
</style>
