<template>
  <div class="panel__container">
    <v-progress-circular
      v-if="projectLoading || changeProjectInfoLoading"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <v-expansion-panels
      v-model="panel"
      multiple
      v-if="!projectLoading && !showJobSubmission && !changeProjectInfoLoading"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="panel__title">General Information</div>
          <template v-slot:actions>
            <v-icon class="panel-icon" color="#384955">mdi-plus</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ProjectInfo @change-trial="changeTrial" :date="date" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="panel__title">Email Information</div>
          <template v-slot:actions>
            <v-icon class="panel-icon" color="#384955">mdi-plus</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <EmailInfo :date="date" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="panel__title">Uninterested Candidates Breakdown</div>
          <template v-slot:actions>
            <v-icon class="panel-icon" color="#384955">mdi-plus</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <RejectionBreakdown :date="date" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="panel__title">Hirefly Rejection - Analysis</div>
          <template v-slot:actions>
            <v-icon class="panel-icon" color="#384955">mdi-plus</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <HireflyRejection :date="date" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="panel__title">Hiring Manager Rejection - Analysis</div>
          <template v-slot:actions>
            <v-icon class="panel-icon" color="#384955">mdi-plus</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <HmRejection :date="date" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="panel__title">Hiring Manager Acceptance - Analysis</div>
          <template v-slot:actions>
            <v-icon class="panel-icon" color="#384955">mdi-plus</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <AccenptanceBreakdown :date="date" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="panel__title">Advanced Information</div>
          <template v-slot:actions>
            <v-icon class="panel-icon" color="#384955">mdi-plus</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <TechnicalInfo :date="date" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="panel__title">Recruiter Notes</div>
          <template v-slot:actions>
            <v-icon class="panel-icon" color="#384955">mdi-plus</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <RecruiterNotes />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels
      v-model="panel"
      multiple
      v-if="!projectLoading && showJobSubmission"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="panel__title">Job Submission</div>
          <template v-slot:actions>
            <v-icon class="panel-icon" color="#384955">mdi-plus</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <JobSubmission :date="date" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color" top>
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
import ProjectInfo from "./DataItems/ProjectInformationV2";
import EmailInfo from "./DataItems/EmailInformation";
import RejectionBreakdown from "./DataItems/RejectionBreakdown";
import HmRejection from "./DataItems/HmRejection";
import HireflyRejection from "./DataItems/HireflyRejection";
import AccenptanceBreakdown from "./DataItems/AcceptanceBreakdown";
import TechnicalInfo from "./DataItems/TechnicalInformation";
import { mapGetters } from "vuex";
import JobSubmission from "./DataItems/JobSubmission";
import RecruiterNotes from "./DataItems/RecruiterNotes.vue";
import { put, post } from "../utils";

export default {
  name: "project-data",
  props: {
    date: {
      type: String,
      default: "last_7",
    },
    showJobSubmission: {
      type: Boolean,
      default: false,
    },
    activeTabName: String,
  },
  data() {
    return {
      panel: [],
      openStack: false,
      openFlush: false,
      openSendMail: false,
      snackbar: false,
      message: "",
      timeout: 4000,
      color: "green",
      sendMailOptions: [
        {
          title: "Fix Job description Link",
          value: "hm_broken_jd",
        },
        {
          title: "Review Pending candidates",
          value: "hm_review_pending",
        },
      ],
      selectedOption: "",
    };
  },
  computed: {
    hiringTeam() {
      if (
        this.projectSuccessData &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].general_information &&
        this.projectSuccessData.data[this.date].general_information
          .hiring_team_information
      ) {
        return this.projectSuccessData.data[this.date].general_information
          .hiring_team_information;
      }
      return {};
    },
    ...mapGetters("operations", [
      "projectLoading",
      "projectSuccessData",
      "changeProjectInfoLoading",
    ]),
  },
  components: {
    ProjectInfo,
    EmailInfo,
    RejectionBreakdown,
    HmRejection,
    AccenptanceBreakdown,
    TechnicalInfo,
    HireflyRejection,
    JobSubmission,
    RecruiterNotes,
  },
  methods: {
    changeTrial(payload) {
      this.$emit("change-trial", payload);
    },
    async flushHandle() {
      const res = await put("/operations_dash/data", {
        project_id: this.projectSuccessData?.data?.project_id,
        flush_cq: true,
      });
      this.openFlush = false;
      if (res.status === 200) {
        this.color = "green";
        this.snackbar = true;
        this.message = "Flushing Queue";
      } else {
        this.color = "red";
        this.snackbar = true;
        this.message = "Error - operation failed, please try again later";
      }
    },
    async stackHandle() {
      const res = await put("/operations_dash/data", {
        project_id: this.projectSuccessData?.data?.project_id,
        atcs: true,
      });
      this.openStack = false;
      if (res.status === 200) {
        this.color = "green";
        this.snackbar = true;
        this.message = "Adding to stack";
      } else {
        this.color = "red";
        this.snackbar = true;
        this.message = "Error - operation failed, please try again later";
      }
    },
    async sendMailHandle() {
      const payload = {
        email_type: this.selectedOption,
        project_id: this.projectSuccessData?.data?.project_id,
      };
      const res = await post("/email", payload);
      this.openSendMail = false;
      if (res.status === 200) {
        this.color = "green";
        this.snackbar = true;
        this.message = "Email successfully sent to Hiring Manager";
      } else {
        this.color = "red";
        this.snackbar = true;
        this.message = "Error: Email was not send, please try again later";
      }
    },
  },
  watch: {
    panel(v) {
      const icons = document.getElementsByClassName("panel-icon");
      for (let i = 0; i < icons.length; i++) {
        if (v.includes(i)) {
          icons[i].classList.add("mdi-minus");
          icons[i].classList.remove("mdi-plus");
        } else {
          icons[i].classList.add("mdi-plus");
          icons[i].classList.remove("mdi-minus");
        }
      }
    },
    projectSuccessData(v) {
      if (v) {
        this.panel = [0];
      }
    },
  },
  mounted() {
    this.panel = [0];
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";
.panel {
  &__container {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
  }
  &__title {
    color: $gray-dark;
    font-size: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 500;
  }
}

.v-expansion-panel {
  margin-top: 24px !important;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px !important;
  overflow: hidden;
}
.v-expansion-panel:first-child {
  margin-top: 12px !important;
  border-radius: 4px !important;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
.v-expansion-panel-header {
  padding: 24px !important;
}
::v-deep {
  .v-expansion-panel-content__wrap {
    padding-bottom: 68px !important;
  }
  .v-label {
    color: #384955;
    font-size: 14px;
  }
}
.primary-btn {
  background: #384955 !important;
  color: #fff !important;
  text-transform: none;
  font-size: 12px;
}
.confirm-popup {
  text-align: center;
  padding: 12px 40px;
  position: relative;
  color: #111827;
  font-size: 14px;
}
.close-btn {
  position: absolute;
  top: 0px;
  right: 10px;
}
.confirm-title {
  color: #111827;
  font-size: 16px;
  font-weight: 600;
}
.confirm-btn {
  color: #384955;
  font-size: 14px;
  text-transform: none;
  border: 2px solid #d8d8d8;
  margin-top: 12px;
}
.confirm-btn2 {
  text-transform: none;
  padding-right: 30px !important;
  padding-left: 30px !important;
  margin-top: 12px;
}
</style>
