<template>
  <div class="panel__container">
    <v-progress-circular
      v-if="changeLogLoading"
      indeterminate
      color="primary"
      class="custom-loader"
    ></v-progress-circular>
    <v-expansion-panels v-else v-model="panel" multiple>
      <v-expansion-panel
        v-for="(candidate, i) in changesList"
        :key="`candidate_${i}`"
      >
        <v-expansion-panel-header>
          <div class="panel__header">
            <div class="d-flex align-center w-full max-w-300 min-w-300">
              <div class="panel__header--avatar">
                <img
                  width="42px"
                  height="42px"
                  :src="candidate.user_image"
                  v-if="candidate.user_image"
                />
                <div v-else class="panel__header--avatar__no-image">
                  {{ userAvatarLetters(candidate) }}
                </div>
              </div>
              <div class="panel__header--username">
                {{ candidate.user_name }}
              </div>
              <div class="panel__header--role">Role: Staff: Owner</div>
            </div>
            <div class="panel__header--changed">
              <ChangeLogItemName :name="changesFiles(candidate.changes)" />
            </div>
            <div class="panel__header--date w-full max-w-250 min-w-250">
              {{ candidate.date }}
            </div>
          </div>
          <template v-slot:actions>
            <v-icon class="change-log-icon" color="#384955">mdi-plus</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ChangeLogItem :candidate="candidate" :projectId="projectId" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChangeLogItem from "./ChangeLogItem.vue";
import { fieldNames } from "@/utils/contants";
import ChangeLogItemName from "./ChangeLogItemName.vue";

export default {
  name: "change-log",
  props: {
    projectId: String,
  },
  data() {
    return {
      panel: [],
    };
  },
  components: {
    ChangeLogItem,
    ChangeLogItemName,
  },
  watch: {
    panel(v) {
      const icons = document.getElementsByClassName("change-log-icon");
      for (let i = 0; i < icons.length; i++) {
        if (v.includes(i)) {
          icons[i].classList.add("mdi-minus");
          icons[i].classList.remove("mdi-plus");
        } else {
          icons[i].classList.add("mdi-plus");
          icons[i].classList.remove("mdi-minus");
        }
      }
    },
  },
  computed: {
    ...mapGetters("operations", [
      "changeLogLoading",
      "changeLogSuccessData",
      "changeLogFailureData",
    ]),
    changesList() {
      let arr = this.changeLogSuccessData || [];
      // arr.map((item) => {
      //   item.changes = item.changes.filter(
      //     (c) => c.field_name !== "outreach_templates"
      //   );
      //   return item;
      // });
      arr = arr.filter((item) => item.changes.length);
      return arr.sort((a, b) => {
        const c = new Date(a.date).getTime();
        const d = new Date(b.date).getTime();
        if (c > d) {
          return -1;
        }
        if (c < d) {
          return 1;
        }
        if (c === d) {
          const e = this.changeLogSuccessData.indexOf(a);
          const f = this.changeLogSuccessData.indexOf(b);
          if (e > f) {
            return -1;
          }
          if (e < f) {
            return 1;
          }
        }
        return 0;
      });
    },
  },
  methods: {
    userAvatarLetters(candidate) {
      return candidate.user_name.match(/\b(\w)/g).join("");
    },
    changesFiles(changes) {
      let changesFiles = [];
      if (changes) {
        changes.forEach((element) => {
          changesFiles.push(
            fieldNames[element.field_name]
              ? fieldNames[element.field_name]
              : element.field_name
                  ?.replaceAll("_", " ")
                  ?.replaceAll("val", "Importance")
          );
        });
      }
      return changesFiles.join(", ");
    },
    changes(changes) {
      let beforeChanges = [],
        afterChanges = [];
      beforeChanges = JSON.parse(changes.before);
      afterChanges = JSON.parse(changes.after);
      return {
        before: beforeChanges,
        after: afterChanges,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";

.panel {
  &__container {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 24px);
    &--avatar {
      width: 42px;
      height: 42px;
      img {
        border-radius: 50%;
      }
      &__no-image {
        width: 42px;
        height: 42px;
        color: #fff;
        background-color: $gray-dark;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }
    }
    &--username {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      color: $gray-dark;
      margin-left: 12px;
    }
    &--role {
      font-size: 12px;
      color: $gray-dark;
      margin-left: 24px;
    }
    &--changed {
      max-width: calc(100% - 582px);
      text-align: left;
      width: 100%;
    }
    &--date {
      text-transform: uppercase;
      font-size: 12px;
      color: $gray-dark;
      margin-left: 12px;
      margin-right: 19px;
      text-align: right;
    }
  }
}
.v-expansion-panel {
  margin-top: 6px !important;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2) !important;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
.v-expansion-panel-header {
  padding: 13px 20px !important;
}

.custom-loader {
  margin-top: 30px;
}
.w-full {
  width: 100%;
}
.max-w-300 {
  max-width: 300px;
}
.min-w-300 {
  min-width: 300px;
}
.max-w-250 {
  max-width: 250px;
}
.min-w-250 {
  min-width: 250px;
}
</style>
