<template>
  <div class="d-flex gap-6 flex-wrap">
    <div class="w-50">
      <div class="table-box" v-if="basicInfo">
        <h3>Basic Information</h3>
        <div class="d-flex gap-3 mt-4 align-center">
          <div class="w-full max-w-160">Project ID</div>
          <div class="text-gray">
            {{ basicInfo.project_id }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center justify-space-between">
          <div class="d-flex gap-3 align-center w-full">
            <div class="w-full max-w-160">Free Trial</div>
            <div>
              <v-switch
                class="custom-switch"
                color="primary"
                v-model="freeTrial"
                hide-details="true"
                :ripple="false"
                @change="changeFreeTrial"
              ></v-switch>
            </div>
          </div>
          <div class="d-flex gap-3 align-center w-full">
            <div>Awaiting Customer Action</div>
            <div class="pl-6">
              <v-switch
                class="custom-switch"
                color="primary"
                v-model="awaitingAction"
                hide-details="true"
                :ripple="false"
                @change="changeAwaitingAction"
              ></v-switch>
            </div>
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">Candidate Queue</div>
          <div class="text-gray">
            {{ basicInfo.cq_low ? "Yes" : "No" }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">Job description</div>
          <div>
            <v-tooltip color="#F3F4F6" top max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <a
                  v-bind="attrs"
                  v-on="on"
                  class="custom-link whitespace-nowrap"
                  target="_blanck"
                  :href="basicInfo.jd_link"
                  >View Link</a
                >
              </template>
              <span>{{
                basicInfo.jd_link ? basicInfo.jd_link : "Not jd_link"
              }}</span>
            </v-tooltip>
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">Go-Live date</div>
          <div class="text-gray">
            {{ basicInfo.went_live }}
          </div>
        </div>
        <div
          class="d-flex gap-3 mt-2 align-center"
          v-if="
            basicInfo.action_requested_date &&
            basicInfo.action_requested_date !== 'N/A'
          "
        >
          <div class="w-full max-w-160">Action Requested Date</div>
          <div class="text-gray">
            {{ basicInfo.action_requested_date }}
          </div>
        </div>
      </div>
    </div>
    <div class="w-50">
      <div class="table-box">
        <h3>Hiring Team</h3>
        <div class="d-flex hiring-table gap-4 items-center mt-4">
          <div class="w-full title-txt">Decision Maker</div>
          <div class="w-full title-txt">Decision Maker Email</div>
          <div class="w-full max-w-50"></div>
        </div>
        <div class="account-list">
          <div
            class="d-flex gap-4 items-center mt-2"
            v-for="(item, i) in accountDecisionMakers"
            :key="i"
          >
            <div class="w-full">{{ item.name }}</div>
            <div class="w-full">{{ item.email }}</div>
            <div class="w-full max-w-50 d-flex justify-end">
              <v-btn
                icon
                height="15"
                width="15"
                :loading="loading2"
                @click="removeAccount(item)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <div class="select-template">
          <div class="d-flex hiring-table gap-4 items-center mt-3 form-box">
            <div class="w-full pa-2 pr-0">
              <v-text-field
                :rules="rules"
                v-model="nameTxt"
                :hide-details="isEdit ? false : true"
                dense
                :disabled="!isEdit"
                :class="{ 'edit-able': isEdit }"
              />
            </div>
            <div class="w-full pa-2">
              <v-text-field
                :rules="emailRules"
                v-model="emailTxt"
                :hide-details="isEdit ? false : true"
                dense
                :disabled="!isEdit"
                :class="{ 'edit-able': isEdit }"
              />
            </div>
            <div class="w-full max-w-50">
              <v-btn
                elevation="0"
                @click="updateData"
                :class="{ 'edit-able': !isEdit }"
                :disabled="isEdit && isInvalid"
                :loading="isloading"
              >
                <span class="text-icon" :class="{ active: isEdit }">Add</span>
                <v-icon class="text-icon" :class="{ active: !isEdit }" size="24"
                  >mdi-plus</v-icon
                >
              </v-btn>
            </div>
          </div>
        </div>
        <div class="d-flex hiring-table gap-4 items-center mt-4">
          <div class="w-full title-txt">Hiring Manager</div>
          <div class="w-full title-txt">Hiring Manager Email</div>
          <div class="w-full max-w-50"></div>
        </div>
        <div class="d-flex hiring-table gap-4 items-center mt-2">
          <div class="w-full text-gray">
            {{ hiringTeam.hiring_manager_name }}
          </div>
          <div class="w-full text-gray">
            {{ hiringTeam.hiring_manager_email }}
          </div>
          <div class="w-full max-w-50"></div>
        </div>
      </div>
    </div>
    <div class="w-30">
      <div class="table-box">
        <h3>Current Pipeline</h3>
        <div class="d-flex gap-3 mt-4 align-center">
          <div class="w-full max-w-160">Interested</div>
          <div class="text-gray">
            {{ pipelineInfo.interested }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">Question</div>
          <div class="text-gray">
            {{ pipelineInfo.question }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">HM Review</div>
          <div class="text-gray">
            {{ pipelineInfo.hm_review }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">Surplus</div>
          <div class="text-gray">
            {{ pipelineInfo.surplus }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">Should have found</div>
          <div class="text-gray">
            {{ pipelineInfo.should_have_found }}
          </div>
        </div>
      </div>
    </div>
    <div class="w-30">
      <div class="table-box">
        <h3>{{ statsTitle }}</h3>
        <div class="d-flex gap-3 mt-4 align-center">
          <div class="w-full max-w-160">Contacted</div>
          <div class="text-gray">
            {{ weekStatsInfo.contacted }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">Presented</div>
          <div class="text-gray">
            {{ weekStatsInfo.presented }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">HM Approved</div>
          <div class="text-gray">
            {{ weekStatsInfo.hm_approved }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">HM Rejected</div>
          <div class="text-gray">
            {{ weekStatsInfo.hm_rejected }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">HM approval rate</div>
          <div class="text-gray">
            {{ Math.round(weekStatsInfo.hm_approval_rate * 100) / 100 }}%
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">Avg. Presented/week</div>
          <div class="text-gray">
            {{ Math.round(weekStatsInfo.avg_presented_per_week * 10) / 10 }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">Avg. Approved/week</div>
          <div class="text-gray">
            {{ Math.round(weekStatsInfo.avg_approved_per_week * 10) / 10 }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">HF Rejected</div>
          <div class="text-gray">
            {{ weekStatsInfo.hf_rejected }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">HF Approval Rate</div>
          <div class="text-gray">
            {{ Math.round(weekStatsInfo.hf_approval_rate * 100) / 100 }}%
          </div>
        </div>
      </div>
    </div>
    <div class="w-30">
      <div class="table-box">
        <h3>All Time Stats</h3>
        <div class="d-flex gap-3 mt-4 align-center">
          <div class="w-full max-w-160">Days Active</div>
          <div class="text-gray">
            {{ allTimeStatsInfo.days_active }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">Contacted</div>
          <div class="text-gray">
            {{ allTimeStatsInfo.contacted }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">Presented</div>
          <div class="text-gray">
            {{ allTimeStatsInfo.presented }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">HM Approved</div>
          <div class="text-gray">
            {{ allTimeStatsInfo.hm_approved }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">HM Rejected</div>
          <div class="text-gray">
            {{ allTimeStatsInfo.hm_rejected }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">HM approval rate</div>
          <div class="text-gray">
            {{ Math.round(allTimeStatsInfo.hm_approval_rate * 100) / 100 }}%
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">Avg. Presented/week</div>
          <div class="text-gray">
            {{ Math.round(allTimeStatsInfo.avg_presented_per_week * 10) / 10 }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">Avg. Approved/week</div>
          <div class="text-gray">
            {{ Math.round(allTimeStatsInfo.avg_approved_per_week * 10) / 10 }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">HF Rejected</div>
          <div class="text-gray">
            {{ allTimeStatsInfo.hf_rejected }}
          </div>
        </div>
        <div class="d-flex gap-3 mt-2 align-center">
          <div class="w-full max-w-160">HF Approval Rate</div>
          <div class="text-gray">
            {{ Math.round(allTimeStatsInfo.hf_approval_rate * 100) / 100 }}%
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ProjectInformation",
  props: {
    date: {
      type: String,
      default: "last_7",
    },
  },
  computed: {
    ...mapGetters("operations", [
      "projectLoading",
      "projectSuccessData",
      "projectFailureData",
    ]),
    statsTitle() {
      let day = "last 7 days";
      console.log(this.date);
      switch (this.date) {
        case "last_14":
          day = "last 14 days";
          break;
        case "last_30":
          day = "last 30 days";
          break;
        case "last_60":
          day = "last 60 days";
          break;
        case "last_90":
          day = "last 90 days";
          break;
        case "all_time":
          day = "All time";
          break;
        default:
          day = "last 7 days";
          break;
      }
      return `Stats for ${day}`;
    },
    basicInfo() {
      if (
        this.projectSuccessData &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].general_information &&
        this.projectSuccessData.data[this.date].general_information
          .basic_information
      ) {
        return this.projectSuccessData.data[this.date].general_information
          .basic_information;
      }
      return [];
    },
    isInvalid() {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return !this.nameTxt || !pattern.test(this.emailTxt);
    },
    hiringTeam() {
      if (
        this.projectSuccessData &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].general_information &&
        this.projectSuccessData.data[this.date].general_information
          .hiring_team_information
      ) {
        return this.projectSuccessData.data[this.date].general_information
          .hiring_team_information;
      }
      return {};
    },
    pipelineInfo() {
      if (
        this.projectSuccessData &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].general_information &&
        this.projectSuccessData.data[this.date].general_information
          .current_pipeline
      ) {
        return this.projectSuccessData.data[this.date].general_information
          .current_pipeline;
      }
      return [];
    },
    weekStatsInfo() {
      if (
        this.projectSuccessData &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].general_information &&
        this.projectSuccessData.data[this.date].general_information.last_n_days
      ) {
        return this.projectSuccessData.data[this.date].general_information
          .last_n_days;
      }
      return [];
    },
    allTimeStatsInfo() {
      if (
        this.projectSuccessData &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].general_information &&
        this.projectSuccessData.data[this.date].general_information
          .all_time_stats
      ) {
        return this.projectSuccessData.data[this.date].general_information
          .all_time_stats;
      }
      return [];
    },
  },
  data() {
    return {
      nameTxt: "",
      emailTxt: "",
      successSnackbar: false,
      timeout: 4000,
      isEdit: false,
      isloading: false,
      loading2: false,
      loading3: true,
      accountDecisionMakers: [],
      rules: [(value) => !!value || "Required."],
      emailRules: [
        (value) => !!value || "Required.",
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      ],
      freeTrial: false,
      awaitingAction: false,
    };
  },
  methods: {
    ...mapActions("operations", ["changeProjectInfo"]),
    changeFreeTrial(v) {
      if (this.loading3) return;
      const payload = {
        is_trial: v,
        project_id: this.basicInfo.project_id,
      };
      this.$emit("change-trial", payload);
    },
    changeAwaitingAction(v) {
      if (this.loading3) return;
      const payload = {
        awaiting_action: v,
        project_id: this.basicInfo.project_id,
      };
      this.$emit("change-trial", payload);
    },
    removeAccount(obj) {
      this.loading2 = true;
      let data = this.accountDecisionMakers.filter(
        (item) => item.email !== obj.email
      );
      if (!data.length) data = [""];
      const payload = {
        project_id: this.basicInfo.project_id,
        account_decision_makers: data,
      };
      this.changeProjectInfo(payload)
        .then(() => {
          this.isEdit = false;
          this.loading2 = false;
          this.accountDecisionMakers = [...data].filter((item) => item.email);
        })
        .catch(() => {
          this.isEdit = false;
          this.loading2 = false;
        });
    },
    async updateData() {
      if (!this.isEdit) {
        this.isEdit = true;
        return;
      }
      this.isloading = true;
      const obj = {
        name: this.nameTxt,
        email: this.emailTxt,
      };
      const accountDecisionMakersData = this.accountDecisionMakers
        ? this.accountDecisionMakers
        : [];
      const data = [...accountDecisionMakersData, obj];
      const payload = {
        project_id: this.basicInfo.project_id,
        account_decision_makers: data,
      };
      this.changeProjectInfo(payload)
        .then(() => {
          this.isEdit = false;
          this.isloading = false;
          this.accountDecisionMakers.push(obj);
        })
        .catch(() => {
          this.isEdit = false;
          this.isloading = false;
        });
    },
    clickOutsideHandler(e) {
      if (!e.target.closest(".select-template")) {
        this.isEdit = false;
      }
    },
  },
  mounted() {
    this.accountDecisionMakers = this.hiringTeam.account_decision_makers;
    window.addEventListener("click", this.clickOutsideHandler, false);
    this.freeTrial = this.basicInfo.free_trial;
    this.awaitingAction = this.basicInfo.awaiting_action;
    setTimeout(() => {
      this.loading3 = false;
    }, 500);
  },

  beforeDestroy() {
    window.removeEventListener("click", this.clickOutsideHandler, false);
  },
  watch: {
    hiringTeam() {
      this.accountDecisionMakers = this.hiringTeam.account_decision_makers;
    },
    basicInfo(v) {
      this.freeTrial = v.free_trial;
      this.awaitingAction = v.awaiting_action;
      setTimeout(() => {
        this.loading3 = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-box {
  padding: 16px 20px;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  position: relative;
  overflow-y: auto;
  font-size: 14px;
  color: #111827;
  font-weight: 500;
  min-height: 100%;
  h3 {
    font-size: 14px;
    font-weight: 700;
    color: #111827;
    text-transform: uppercase;
  }
}
.gap-6 {
  gap: 24px;
}
.flex-wrap {
  flex-wrap: wrap;
}
.gap-3 {
  gap: 12px;
}
.gap-4 {
  gap: 16px;
}
.account-list {
  color: #384955;
  font-size: 14px;
  font-weight: 400;
  padding: 12px 0;
}
.w-50 {
  width: calc(50% - 12px);
}
.w-30 {
  width: calc(33.33% - 16px);
}
.custom-switch {
  &::v-deep .v-input--selection-controls__input {
    margin: 0 auto;
  }
  &::v-deep .v-input--switch__track {
    opacity: 1 !important;
  }
  &::v-deep .v-input--switch__thumb {
    background-color: #fff;
    box-shadow: -1px 1px 6px 0 rgba(0, 0, 0, 0.5) !important;
  }
}
.v-tooltip__content {
  word-break: break-all;
}
::v-deep {
  .v-input--selection-controls {
    margin-top: 0 !important;
  }
}
.max-w-160 {
  max-width: 160px;
}
.w-full {
  width: 100%;
}
.text-gray {
  color: #384955;
  font-weight: 400;
}
.custom-link {
  color: #1da1f2;
  text-decoration: none;
}
.gap-2 {
  gap: 8px;
}
.hiring-table {
  word-break: break-all;
  .title-txt {
    word-break: break-word;
  }
  &::v-deep {
    .v-btn {
      font-size: 12px;
      background: #4646ab !important;
      color: #fff !important;
      height: 100% !important;
      border-radius: 0px;
      &.edit-able {
        background: #f3f4f6 !important;
        color: #a1a5a6 !important;
      }
    }
    .v-input {
      font-size: 12px;
      padding: 0px 8px;
      height: auto;
      margin: 0px;
      border-radius: 4px;
      &.edit-able {
        background: #f3f4f6;
        position: relative;
      }
      .v-text-field__details {
        position: absolute;
        top: 100%;
        margin-top: 10px;
        left: 0px;
      }
    }
    .v-input__slot:before,
    .v-input__slot:after {
      display: none;
    }
  }
}
.form-box {
  border: 1px solid #e6e7eb;
  border-radius: 4px;
}
.max-w-50 {
  max-width: 64px;
}
.text-icon {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  line-height: 27px;
  &.active {
    z-index: 2;
    opacity: 1;
  }
}
</style>
