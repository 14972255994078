<template>
  <div class="table-container">
    <div class="table-flex-box">
      <div class="table-box">
        <div class="table-box__title">Hirefly - Rejected Reasons</div>
        <v-data-table
          :headers="rejectHeaders"
          :items="rejectItems"
          :items-per-page="10"
          class="table-box__table"
          :hide-default-footer="rejectItems.length <= 10"
          must-sort
        >
        </v-data-table>
      </div>
      <div class="table-box">
        <div class="table-box__title mb-3">
          Hirefly - Rejected Reasons - Pie Chart
        </div>
        <div class="diagram-box">
          <CustomPieChart
            v-if="!loading && rejectReasonCount !== 0"
            :chartOptions="chartOptions"
            :series="series"
          ></CustomPieChart>
          <div class="no-data" v-else>No data available</div>
        </div>
      </div>
    </div>
    <div>
      <div class="table-box w-100 mt-6">
        <div class="table-box__title">
          Hirefly - Rejected Reasons Explanations
        </div>
        <v-data-table
          :headers="explanationsHeaders"
          :items="explanationsItems"
          :items-per-page="10"
          class="table-box__table-1"
          :hide-default-footer="explanationsItems.length <= 10"
          must-sort
        >
          <!-- eslint-disable-next-line -->
          <template #item.name="{ item }">
            <a
              :href="item.linkedin_profile_url"
              target="_blanck"
              class="table-box__candidate-name"
            >
              {{ item.name }}
            </a>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomPieChart from "./CustomPieChart";

export default {
  name: "hm-reject",
  props: {
    date: String,
  },
  components: {
    CustomPieChart,
  },
  data() {
    return {
      loading: true,
      rejectHeaders: [
        {
          text: "Reason",
          value: "reason",
          align: "start",
          sortable: false,
        },
        {
          text: "Count",
          value: "count",
          align: "center",
          sortable: true,
        },
      ],
      explanationsHeaders: [
        {
          text: "Candidate name",
          value: "name",
          align: "start",
          sortable: false,
        },
        {
          text: "Reason",
          value: "rejection_reason_other",
          align: "start",
          sortable: false,
        },
        {
          text: "Reviewer",
          value: "reviewed_by",
          align: "start",
          sortable: false,
        },
        {
          text: "Source",
          value: "source",
          align: "start",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("operations", [
      "projectLoading",
      "projectSuccessData",
      "projectFailureData",
    ]),
    chartOptions() {
      var arr = [];
      this.rejectItems.forEach((element) => {
        if (element.count !== 0) {
          arr.push(element.reason);
        }
      });
      return {
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        chart: {
          type: "pie",
        },
        labels: arr,
        colors: [
          "#00d2bc",
          "#ffa701",
          "#00aada",
          "#6e7d7d",
          "#e0e0e0",
          "#2596be",
          "#3ba1c5",
          "#51abcb",
          "#66b6d2",
          "#7cc0d8",
          "#92cbdf",
          "#a8d5e5",
          "#bee0ec",
          "#d3eaf2",
          "#e9f5f9",
        ],
        legend: {
          fontSize: "12px",
          onItemHover: {
            highlightDataSeries: false,
          },
        },
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: false,
          },
          style: {
            colors: ["#111827"],
          },
          background: {
            foreColor: "#0000",
          },
        },
      };
    },
    rejectReason() {
      let reasons = {};
      if (
        this.projectSuccessData.data &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].hirefly_and_hm_rejections &&
        this.projectSuccessData.data[this.date].hirefly_and_hm_rejections
          .hirefly_rejected_reasons
      ) {
        reasons =
          this.projectSuccessData.data[this.date].hirefly_and_hm_rejections
            .hirefly_rejected_reasons;
      }
      return reasons;
    },
    rejectExplain() {
      let reasons = {};
      if (
        this.projectSuccessData.data &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].hirefly_and_hm_rejections &&
        this.projectSuccessData.data[this.date].hirefly_and_hm_rejections
          .hirefly_rejected_explanations
      ) {
        reasons =
          this.projectSuccessData.data[this.date].hirefly_and_hm_rejections
            .hirefly_rejected_explanations;
      }
      return reasons;
    },
    explanationsItems() {
      let arr = [];
      if (
        this.projectSuccessData.data &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].hirefly_and_hm_rejections &&
        this.projectSuccessData.data[this.date].hirefly_and_hm_rejections
          .hirefly_rejected_explanations
      ) {
        arr = [
          ...this.projectSuccessData.data[this.date].hirefly_and_hm_rejections
            .hirefly_rejected_explanations,
        ];
      }
      return arr;
    },
    series() {
      var arr = [];
      this.rejectItems.forEach((element) => {
        if (element.count !== 0) {
          arr.push(element.count);
        }
      });
      return arr;
    },
    rejectReasonCount() {
      let count = 0;
      for (let i in this.rejectReason) {
        count += this.rejectReason[i];
      }
      return count;
    },
    rejectItems() {
      return [
        {
          reason: "ALREADY CONTACTED",
          count: this.rejectReason.ALREADY_CONTACTED,
        },
        {
          reason: "CANDIDATE OVERQUALIFIED",
          count: this.rejectReason.CANDIDATE_OVERQUALIFIED,
        },
        {
          reason: "COMPANIES NOT_MATCHED",
          count: this.rejectReason.COMPANIES_NOT_MATCHED,
        },
        {
          reason: "DIVERSITY PROFILE NOT MATCHED",
          count: this.rejectReason.DIVERSITY_PROFILE_NOT_MATCHED,
        },
        {
          reason: "DON LIKE CURRENT COMPANY",
          count: this.rejectReason.DONT_LIKE_CURRENT_COMPANY,
        },
        {
          reason: "EDUCATION NOT HIGH",
          count: this.rejectReason.EDUCATION_NOT_HIGH,
        },
        {
          reason: "INDUSTRY NOT MATCHED",
          count: this.rejectReason.INDUSTRY_NOT_MATCHED,
        },
        {
          reason: "JOB_TITLE_NOT_MATCHED",
          count: this.rejectReason.JOB_TITLE_NOT_MATCHED,
        },
        {
          reason: "NOT ENOUGH EXPERIENCE",
          count: this.rejectReason.NOT_ENOUGH_EXPERIENCE,
        },
        {
          reason: "NOT IN DESIRED LOCATION",
          count: this.rejectReason.NOT_IN_DESIRED_LOCATION,
        },
        {
          reason: "NOT PRESTIGIOUS SCHOOL",
          count: this.rejectReason.NOT_PRESTIGIOUS_SCHOOL,
        },
        {
          reason: "OTHER",
          count: this.rejectReason.OTHER,
        },
        {
          reason: "RESPONSE PROB LOW",
          count: this.rejectReason.RESPONSE_PROB_LOW,
        },
        {
          reason: "SKILLS NOT MATCHED",
          count: this.rejectReason.SKILLS_NOT_MATCHED,
        },
        {
          reason: "VISA",
          count: this.rejectReason.VISA,
        },
      ];
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 100);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";

.table-box {
  padding: 16px 20px;
  overflow-y: auto;
  // height: 372px;
  border: 1px solid $border-color;
  border-radius: 4px;
  width: 50%;
  max-height: 100%;
  min-height: 200px;
  &__title {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: $black;
    text-transform: uppercase;
  }
  &__table {
    &::v-deep {
      th {
        font-size: 12px;
        font-weight: 600 !important;
        color: $black !important;
        border-bottom: none !important;
      }
      td {
        font-size: 11px !important;
        color: $gray-dark;
        height: 20px !important;
        line-height: 20px !important;
      }
      td:nth-child(2) {
        border-right: 1px solid rgb(0 0 0 / 12%) !important;
      }
      td:nth-child(1) {
        border-left: 1px solid rgb(0 0 0 / 12%) !important;
      }
      tr:first-child {
        td {
          border-top: 1px solid rgb(0 0 0 / 12%) !important;
        }
      }
      tr:last-child {
        td {
          border-bottom: 1px solid rgb(0 0 0 / 12%) !important;
        }
      }
    }
  }
  &__table-1 {
    &::v-deep {
      th {
        font-size: 12px;
        font-weight: 600 !important;
        color: $black !important;
        border-bottom: none !important;
      }
      td {
        font-size: 11px !important;
        color: $gray-dark;
        height: 22px !important;
        line-height: 22px !important;
      }
      tr:first-child {
        td {
          border-top: 1px solid rgb(0 0 0 / 12%) !important;
        }
      }
      tr:last-child {
        td {
          border-bottom: 1px solid rgb(0 0 0 / 12%) !important;
        }
      }
    }
  }
  &__candidate-name {
    font-weight: 500;
    font-size: 12px;
    color: $blue;
    text-decoration: none;
    cursor: pointer;
  }
}
.table-flex-box {
  display: flex;
  gap: 16px;
}
.w-100 {
  width: 100%;
}
.no-data {
  font-size: 13px;
  color: $gray-dark;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-align: center;
  margin-top: 100px;
}
</style>
