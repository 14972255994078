<template>
  <div class="table-container">
    <div class="table-flex-box">
      <div class="table-box">
        <div class="table-box__title">Hiring Manager Acceptance</div>
        <v-data-table
          :headers="acceptanceHeaders"
          :items="hmAcceptance"
          :items-per-page="10"
          class="table-box__table"
          :hide-default-footer="hmAcceptance.length <= 10"
          disable-sort
        >
          <!-- eslint-disable-next-line -->
          <template #item.name="{ item }">
            <a
              :href="item.linkedin_profile_url"
              class="table-box__candidate-name"
              target="_blanck"
            >
              {{ item.name }}
            </a>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.approval_score="{ item }">
            {{ item.approval_score ? item.approval_score : "N/A" }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.approval_score_other="{ item }">
            {{ item.approval_score_other ? item.approval_score_other : "N/A" }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.rating="{ item }">
            {{ item.rating }}%
          </template>
          <!-- eslint-disable-next-line -->
          <template #header.approval_date="{ item }">
            <div class="sort-header" @click="onSort">
              <v-btn icon>
                <v-icon v-if="!isSort">mdi-arrow-down</v-icon>
                <v-icon v-if="isSort">mdi-arrow-up</v-icon>
              </v-btn>
              Approval Date
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "acceptance-breakdown",
  props: {
    isActiveAc: Boolean,
    date: String,
  },
  computed: {
    ...mapGetters("operations", [
      "projectLoading",
      "projectSuccessData",
      "projectFailureData",
    ]),
    hmAcceptance() {
      let arr = {};
      if (
        this.projectSuccessData.data &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].hm_accept &&
        this.projectSuccessData.data[this.date].hm_accept.hm_acceptance
      ) {
        arr = this.projectSuccessData.data[this.date].hm_accept.hm_acceptance;
      }
      return this.isSort ? arr.reverse() : arr;
    },
  },
  data() {
    return {
      width: "100",
      isSort: false,
      acceptanceHeaders: [
        {
          text: "Candidate name",
          value: "name",
          align: "start",
          sortable: false,
        },
        {
          text: "Hiring Manager name",
          value: "hiring_manager_name",
          align: "start",
          sortable: false,
        },
        {
          text: "Email Address",
          value: "hiring_manager_email",
          align: "start",
          sortable: false,
        },
        {
          text: "Rating",
          value: "approval_score",
          align: "start",
          sortable: false,
        },
        {
          text: "Comments",
          value: "approval_score_other",
          align: "end",
          sortable: false,
        },
        {
          text: "Approval Date",
          value: "approval_date",
          align: "end",
        },
        {
          text: "Source",
          value: "source",
          align: "end",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    renderChart() {
      // Timeout for animation
      setTimeout(() => {
        this.width = "380";
      }, 0);
    },
    onSort() {
      this.isSort = !this.isSort;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";

.table-box {
  overflow-y: auto;
  // height: 372px;
  padding: 16px 20px;
  border: 1px solid $border-color;
  border-radius: 4px;
  width: 100%;
  max-height: 100%;
  min-height: 200px;
  &__title {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: $black;
    text-transform: uppercase;
  }
  &__table {
    &::v-deep {
      th {
        font-size: 12px;
        font-weight: 600 !important;
        color: $black !important;
        border-bottom: none !important;
      }
      td {
        font-size: 11px !important;
        color: $gray-dark;
        height: 22px !important;
        line-height: 22px !important;
      }
      tr:first-child {
        td {
          border-top: 1px solid rgb(0 0 0 / 12%) !important;
        }
      }
      tr:last-child {
        td {
          border-bottom: 1px solid rgb(0 0 0 / 12%) !important;
        }
      }
    }
  }
  &__candidate-name {
    font-weight: 500;
    font-size: 12px;
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
  }
}
.table-flex-box {
  display: flex;
  gap: 16px;
}
.diagram-box {
  display: flex;
  justify-content: center;
}
::v-deep {
  .apexcharts-legend-series {
    margin-bottom: 14px !important;
  }
}
.sort-header {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
</style>
