<template>
  <div class="sticky-container">
    <div class="header">
      <Logo :width="120" :height="32" />
      <div class="header__left-col">
        <v-img
          class="header__left-col--user-img"
          :lazy-src="require('@/assets/images/no-user.jpg')"
          :height="42"
          :width="42"
          contain
          :src="require('@/assets/images/no-user.jpg')"
        ></v-img>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "./Logo.vue";
export default {
  name: "dashboard-header",
  components: {
    Logo,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";
.sticky-container {
  position: sticky;
  top: 0;
  z-index: 99;
}
.header {
  background-color: $primary;
  width: 100%;
  padding: 10px 24px 10px 37px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 99;
  &__left-col {
    display: flex;
    gap: 42px;
    &--export-btn {
      transition: 0.3s;
      height: 45px !important;
      background-color: transparent !important;
      box-shadow: none;
      padding: 0 14px !important;
      &::v-deep .v-btn__content {
        display: block !important;
      }
      div {
        color: #fff;
        font-size: 12px;
        text-transform: capitalize;
        letter-spacing: 0.3px;
        font-weight: 500;
      }
      img {
        filter: invert(100%) sepia(8%) saturate(7467%) hue-rotate(196deg)
          brightness(102%) contrast(110%);
      }
    }
    &--export-btn-active {
      background-color: rgba(255, 255, 255, 0.3) !important;
      i {
        opacity: 1;
      }
      div {
        opacity: 1;
      }
    }
    &--user-img {
      border-radius: 50%;
      cursor: pointer;
    }
  }
}
</style>
