<template>
  <div class="table-container">
    <div class="table-flex-box">
      <div class="table-box">
        <div class="table-box__title">Hiring Manager - Rejected Reasons</div>
        <v-data-table
          :headers="rejectHeaders"
          :items="hmRejectItems"
          :items-per-page="10"
          class="table-box__table"
          :hide-default-footer="hmRejectItems.length <= 10"
          multi-sort
          must-sort
        >
        </v-data-table>
      </div>
      <div class="table-box">
        <div class="table-box__title mb-3">
          Hiring Manager - Rejected Reasons - Pie Chart
        </div>
        <div class="diagram-box">
          <CustomPieChart
            v-if="!loading && rejectReasonHmCount !== 0"
            :chartOptions="hmchartOptions"
            :series="seriesHm"
          ></CustomPieChart>
          <div class="no-data" v-else>No data available</div>
        </div>
      </div>
    </div>
    <div>
      <div class="table-box w-100 mt-6">
        <div class="table-box__title mb-3">
          Hiring Manager - Rejected Reasons Explanations
        </div>
        <v-data-table
          :headers="explanationsHeaders"
          :items="hmExplanationsItems"
          :items-per-page="10"
          class="table-box__table-1"
          :hide-default-footer="hmExplanationsItems.length <= 10"
          disable-sort
        >
          <!-- eslint-disable-next-line -->
          <template #item.name="{ item }">
            <a
              :href="item.linkedin_profile_url"
              target="_blanck"
              class="table-box__candidate-name"
            >
              {{ item.name }}
            </a>
          </template>
          <!-- eslint-disable-next-line -->
          <template #header.reviewed_date="{ item }">
            <div class="sort-header" @click="onSort">
              <v-btn icon>
                <v-icon v-if="!isSort">mdi-arrow-down</v-icon>
                <v-icon v-if="isSort">mdi-arrow-up</v-icon>
              </v-btn>
              Reviewed Date
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomPieChart from "./CustomPieChart.vue";

export default {
  name: "hm-reject",
  props: {
    date: String,
  },
  components: {
    CustomPieChart,
  },
  data() {
    return {
      loading: true,
      isSort: false,
      rejectHeaders: [
        {
          text: "Reason",
          value: "reason",
          align: "start",
          sortable: false,
        },
        {
          text: "Count",
          value: "count",
          align: "center",
          sortable: true,
        },
      ],
      explanationsHeaders: [
        {
          text: "Candidate name",
          value: "name",
          align: "start",
          sortable: false,
        },
        {
          text: "Reason",
          value: "rejection_reason_other",
          align: "start",
          sortable: false,
        },
        {
          text: "Reviewer",
          value: "reviewed_by",
          align: "start",
          sortable: false,
        },
        {
          text: "Source",
          value: "source",
          align: "start",
          sortable: false,
        },
        {
          text: "Reviewed Date",
          value: "reviewed_date",
          align: "end",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("operations", [
      "projectLoading",
      "projectSuccessData",
      "projectFailureData",
    ]),
    hmchartOptions() {
      var arr = [];
      this.hmRejectItems.forEach((element) => {
        if (element.count !== 0) {
          arr.push(element.reason);
        }
      });
      return {
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        chart: {
          type: "pie",
        },
        labels: arr,
        colors: [
          "#00d2bc",
          "#ffa701",
          "#00aada",
          "#6e7d7d",
          "#e0e0e0",
          "#2596be",
          "#3ba1c5",
          "#51abcb",
          "#66b6d2",
          "#7cc0d8",
          "#92cbdf",
          "#a8d5e5",
          "#bee0ec",
          "#d3eaf2",
          "#e9f5f9",
        ],
        legend: {
          fontSize: "12px",
          onItemHover: {
            highlightDataSeries: false,
          },
        },
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: false,
          },
          style: {
            colors: ["#111827"],
          },
          background: {
            foreColor: "#0000",
          },
        },
      };
    },
    rejectReasonHm() {
      let reasons = {};
      if (
        this.projectSuccessData.data &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].hirefly_and_hm_rejections &&
        this.projectSuccessData.data[this.date].hirefly_and_hm_rejections
          .hm_rejected_reasons
      ) {
        reasons =
          this.projectSuccessData.data[this.date].hirefly_and_hm_rejections
            .hm_rejected_reasons;
      }
      return reasons;
    },
    hmExplanationsItems() {
      let arr = [];
      if (
        this.projectSuccessData.data &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].hirefly_and_hm_rejections &&
        this.projectSuccessData.data[this.date].hirefly_and_hm_rejections
          .hm_rejected_explanations
      ) {
        arr = [
          ...this.projectSuccessData.data[this.date].hirefly_and_hm_rejections
            .hm_rejected_explanations,
        ];
      }
      return this.isSort ? arr.reverse() : arr;
    },
    rejectReasonHmCount() {
      let count = 0;
      for (let i in this.rejectReasonHm) {
        count += this.rejectReasonHm[i];
      }
      return count;
    },
    seriesHm() {
      var arr = [];
      this.hmRejectItems.forEach((element) => {
        if (element.count !== 0) {
          arr.push(element.count);
        }
      });
      return arr;
    },
    hmRejectItems() {
      return [
        {
          reason: "ALREADY CONTACTED",
          count: this.rejectReasonHm.ALREADY_CONTACTED,
        },
        {
          reason: "CANDIDATE OVERQUALIFIED",
          count: this.rejectReasonHm.CANDIDATE_OVERQUALIFIED,
        },
        {
          reason: "COMPANIES NOT MATCHED",
          count: this.rejectReasonHm.COMPANIES_NOT_MATCHED,
        },
        {
          reason: "DIVERSITY PROFILE NOT MATCHED",
          count: this.rejectReasonHm.DIVERSITY_PROFILE_NOT_MATCHED,
        },
        {
          reason: "DONT LIKE CURRENT COMPANY",
          count: this.rejectReasonHm.DONT_LIKE_CURRENT_COMPANY,
        },
        {
          reason: "EDUCATION NOT HIGH",
          count: this.rejectReasonHm.EDUCATION_NOT_HIGH,
        },
        {
          reason: "INDUSTRY NOT MATCHED",
          count: this.rejectReasonHm.INDUSTRY_NOT_MATCHED,
        },
        {
          reason: "JOB TITLE NOT MATCHED",
          count: this.rejectReasonHm.JOB_TITLE_NOT_MATCHED,
        },
        {
          reason: "NOT ENOUGH EXPERIENCE",
          count: this.rejectReasonHm.NOT_ENOUGH_EXPERIENCE,
        },
        {
          reason: "NOT IN DESIRED LOCATION",
          count: this.rejectReasonHm.NOT_IN_DESIRED_LOCATION,
        },
        {
          reason: "NOT PRESTIGIOUS SCHOOL",
          count: this.rejectReasonHm.NOT_PRESTIGIOUS_SCHOOL,
        },
        {
          reason: "OTHER",
          count: this.rejectReasonHm.OTHER,
        },
        {
          reason: "RESPONSE PROB LOW",
          count: this.rejectReasonHm.RESPONSE_PROB_LOW,
        },
        {
          reason: "SKILLS NOT MATCHED",
          count: this.rejectReasonHm.SKILLS_NOT_MATCHED,
        },
        {
          reason: "VISA",
          count: this.rejectReasonHm.VISA,
        },
      ];
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 100);
  },
  methods: {
    onSort() {
      this.isSort = !this.isSort;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";

.table-box {
  padding: 16px 20px;
  overflow-y: auto;
  // height: 372px;
  border: 1px solid $border-color;
  border-radius: 4px;
  width: 50%;
  min-height: 200px;
  max-height: 100%;
  &__title {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: $black;
    text-transform: uppercase;
  }
  &__table {
    &::v-deep {
      th {
        font-size: 12px;
        font-weight: 600 !important;
        color: $black !important;
        border-bottom: none !important;
      }
      td {
        font-size: 11px !important;
        color: $gray-dark;
        height: 20px !important;
        line-height: 20px !important;
      }
      td:nth-child(2) {
        border-right: 1px solid rgb(0 0 0 / 12%) !important;
      }
      td:nth-child(1) {
        border-left: 1px solid rgb(0 0 0 / 12%) !important;
      }
      tr:first-child {
        td {
          border-top: 1px solid rgb(0 0 0 / 12%) !important;
        }
      }
      tr:last-child {
        td {
          border-bottom: 1px solid rgb(0 0 0 / 12%) !important;
        }
      }
    }
  }
  &__table-1 {
    &::v-deep {
      th {
        font-size: 12px;
        font-weight: 600 !important;
        color: $black !important;
        border-bottom: none !important;
      }
      td {
        font-size: 11px !important;
        color: $gray-dark;
        height: 22px !important;
        line-height: 22px !important;
      }
      tr:first-child {
        td {
          border-top: 1px solid rgb(0 0 0 / 12%) !important;
        }
      }
      tr:last-child {
        td {
          border-bottom: 1px solid rgb(0 0 0 / 12%) !important;
        }
      }
    }
  }
  &__candidate-name {
    font-weight: 500;
    font-size: 12px;
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
  }
}
.table-flex-box {
  display: flex;
  gap: 16px;
}
.w-100 {
  width: 100%;
}
.no-data {
  font-size: 13px;
  color: $gray-dark;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-align: center;
  margin-top: 100px;
}
::v-deep {
  .apexcharts-tooltip {
    color: #000000 !important;
  }
  .apexcharts-legend-series {
    margin-bottom: 14px !important;
  }
  .apexcharts-legend {
    max-width: 50%;
    width: 100%;
  }
  .apexcharts-legend-series {
    position: relative;
    padding-left: 20px;
  }
  .apexcharts-legend-series .apexcharts-legend-marker {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .apexcharts-legend-text {
    display: block;
  }
}
.sort-header {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
</style>
