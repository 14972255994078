var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table-box"},[_c('div',{staticClass:"table-box__title"},[_vm._v("Emails Sent")]),(!_vm.loading)?_c('CustomLineChart',{attrs:{"chartOptions":_vm.chartOptions,"series":_vm.series}}):_vm._e()],1),_c('div',{staticClass:"table-box mt-4"},[_c('div',{staticClass:"table-box__title"},[_vm._v("Email Find Rate")]),(!_vm.loading)?_c('CustomLineChart',{attrs:{"chartOptions":_vm.chartOptionsRate,"series":_vm.seriesRate}}):_vm._e()],1),_c('div',{staticClass:"table-box mt-6"},[_c('div',{staticClass:"table-box__title"},[_vm._v("Email Open/Response")]),_c('v-data-table',{staticClass:"table-box__table mt-3",attrs:{"headers":_vm.emailHeaders,"items":_vm.emailInfo,"items-per-page":5,"hide-default-footer":_vm.emailInfo.length <= 10},scopedSlots:_vm._u([{key:"item.negative_response_percent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.negative_response_percent.toFixed(2))+"% ")]}},{key:"item.positive_response_percent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.positive_response_percent.toFixed(2))+"% ")]}},{key:"item.first_pixel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_pixel.toFixed(2))+"% ")]}},{key:"item.second_pixel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.second_pixel.toFixed(2))+"% ")]}}])})],1),_c('div',{staticClass:"table-box mt-6"},[_c('div',{staticClass:"table-box__title"},[_vm._v("Template Performance")]),_c('v-data-table',{staticClass:"table-box__table mt-3",attrs:{"headers":_vm.emailTemplateHeaders,"items":_vm.templateUse,"items-per-page":5,"hide-default-footer":_vm.templateUse.length <= 10},scopedSlots:_vm._u([{key:"item.percentage_initial_sends",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.percentage_initial_sends.toFixed(2))+"% ")]}},{key:"item.open_rate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.open_rate)+"% ")]}},{key:"item.response_rate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.response_rate)+"% ")]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }