<template>
  <div>
    <div class="panel__body--left-col__title">
      {{ name }}
    </div>
    <div class="panel__body--left-col__box">
      <div
        class="panel__body--left-col__box--content"
        v-if="changesData.length"
      >
        <div
          class="panel__body--left-col__box--item"
          v-for="(item, i) in changesData"
          :key="`before-title_${i}`"
          :class="{
            removed: item.status === 0,
            added: item.status === 1,
            '': item.status === 2,
            'boolean-style': item.isBoolean,
            'content-text': item.isContent,
          }"
        >
          {{ !item.isBoolean && !item.isContent ? item.value : "" }}
          <v-radio-group
            hide-details
            :value="item.value ? '1' : '0'"
            v-if="item.isBoolean && !item.isContent"
          >
            <v-radio readonly label="" value="1"></v-radio>
          </v-radio-group>
          <div v-html="item.value" v-if="item.isContent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fieldNames } from "@/utils/contants";

export default {
  name: "FieldItem",

  props: {
    changes: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    changesData() {
      const arr = [];
      const changesValues = this.changes;

      if (this.changes.field_name === "outreach_templates") {
        const a = this.convertOutreachTemplates(changesValues);
        arr.push({
          value: a,
          status: 2,
          isContent: true,
        });
        return arr;
      }
      const before = changesValues.before
        ? JSON.parse(changesValues.before)
        : null;
      const after = changesValues.after
        ? JSON.parse(changesValues.after)
        : null;

      if (typeof after === "boolean" || typeof before === "boolean") {
        arr.push({
          value: after,
          status: 1,
          isBoolean: true,
        });
        arr.push({
          value: before,
          status: 0,
          isBoolean: true,
        });
      } else {
        let removed = changesValues.removed
          ? JSON.parse(changesValues.removed)
          : null;
        if (typeof removed === "string" || typeof removed === "number") {
          const a = [];
          a.push(removed);
          removed = [...a];
        }
        removed?.forEach((element) => {
          if (element !== "") {
            arr.push({
              value: element,
              status: 0,
            });
          }
        });
        let added = changesValues.added
          ? JSON.parse(changesValues.added)
          : null;
        if (typeof added === "string" || typeof added === "number") {
          const a = [];
          a.push(added);
          added = [...a];
        }
        added?.forEach((element) => {
          if (element !== "") {
            arr.push({
              value: element,
              status: 1,
            });
          }
        });
        let same = changesValues.same ? JSON.parse(changesValues.same) : null;
        if (typeof same === "string" || typeof same === "number") {
          const a = [];
          a.push(same);
          same = [...a];
        }
        same?.forEach((element) => {
          if (element !== "") {
            arr.push({
              value: element,
              status: 2,
            });
          }
        });
      }

      return arr;
    },
    name() {
      return (
        fieldNames[this.changes.field_name] ||
        this.changes.field_name
          ?.replaceAll("_", " ")
          ?.replaceAll("val", "Importance")
      );
    },
  },
  methods: {
    convertOutreachTemplates(data = {}) {
      const beforeDiff = data.before_diff;
      const afterDiff = data.after_diff;
      let str = "";
      if (!beforeDiff && afterDiff) {
        str = `<div class="added">${afterDiff}</div>`;
      }
      if (beforeDiff && !afterDiff) {
        str = `<div class="removed">${beforeDiff}</div>`;
      }
      if (beforeDiff && afterDiff) {
        let next = 0;
        for (let index = 0; index < beforeDiff.length; index++) {
          const a = beforeDiff[index];
          const b = afterDiff[index];
          if (a === "~") {
            next = 1;
            str += `<span class="added">${b}</span>`;
          } else if (b === "~") {
            next = 2;
            str += `<span class="removed">${a}</span>`;
          } else {
            if (next && b === " ") {
              if (next === 1) {
                str += `<span class="added">${b}</span>`;
              } else {
                str += `<span class="removed">${a}</span>`;
              }
            } else {
              str += b;
            }
            next = 0;
          }
        }
      }
      return str;
    },
    convertChangesData(data) {
      const beforeTmp = JSON.parse(data.before);
      const afterTmp = JSON.parse(data.after);
      const removed = [];
      const added = [];
      const same = [];
      let before = [];
      let after = [];
      if (typeof beforeTmp === "string" || typeof beforeTmp === "number") {
        removed.push(beforeTmp);
      } else {
        before = [...beforeTmp];
      }

      if (typeof afterTmp === "string" || typeof afterTmp === "number") {
        added.push(afterTmp);
      } else {
        after = [...afterTmp];
      }

      after.forEach((item) => {
        if (before.includes(item)) {
          same.push(item);
        } else {
          added.push(item);
        }
      });
      before.forEach((item) => {
        if (!same.includes(item)) {
          removed.push(item);
        }
      });

      return {
        removed,
        added,
        same,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";

.panel {
  &__body {
    &--left-col {
      &__title {
        color: $black;
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;
      }
      &__box {
        padding: 10px 0 10px 20px;
        &--title {
          color: $gray-dark;
          font-weight: 700;
          font-size: 14px;
          letter-spacing: 0.35px;
        }
        &--content {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 6px;
          padding: 5px 0 10px 0;
        }
        &--item {
          padding: 5px 8px;
          border: solid 1px #d8d8d8;
          border-radius: 12.5px;
          font-size: 12px;
          font-weight: 500;
          color: $gray-dark;
          line-height: 12px;
          cursor: default;
          &.removed {
            background: rgba(202, 61, 61, 0.3);
          }
          &.added {
            background: rgba(55, 183, 151, 0.3);
          }
          &.boolean-style {
            &::v-deep {
              .v-input,
              .v-input--selection-controls__input,
              .v-input__slot {
                margin: 0px;
                padding: 0px;
              }
            }
          }
          &.content-text {
            border: none !important;
          }
        }
      }
    }
    &--right-col {
      width: calc(30% - 24px);
      min-height: 100%;
      position: relative;
      &__title {
        position: absolute;
        top: 22px;
        left: 29px;
        color: $gray-dark;
        font-size: 12px;
        font-weight: 600;
      }
      &__btn {
        position: absolute;
        z-index: 10000;
        background-color: $primary !important;
        border-radius: 2px !important;
        box-shadow: none !important;
        min-width: 26px !important;
        height: 26px !important;
        padding: 0 !important;
        right: 18px;
        bottom: 10.5px;
      }
    }
  }
}
</style>
<style lang="scss">
.content-text {
  .added {
    background: rgba(55, 183, 151, 0.3);
  }
  .removed {
    background: rgba(202, 61, 61, 0.3);
  }
}
</style>
