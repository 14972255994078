export const fieldNames = {
  keywords_required: "Required Keywords",
  job_titles: "Job Titles",
  years_exp_min: "Min Years of Experience",
  years_exp_max: "Max Years of Experience",
  locations: "Locations",
  bachelors: "Has Bachelors",
  phd: "Has PhD",
  mba: "Has MBA",
  advanced_degree: "Has Advanced Degree",
  ic_or_manager: "Is Manager",
  visa_filter: "Sponsors Visa",
  remote_work_allowed: "Remote",
  is_fulltime: "Fulltime",
  comp_target_min: "Comp Target (min)",
  comp_target_max: "Comp Target (max)",
  bad_companies: "Bad Companies",
  bad_job_titles: "Bad Job Titles",
  bad_keywords: "Bad Keywords",
  keywords_optional: "Optional Keywords",
  companies: "Companies",
  yore_min: "Min Years of Relevant Experience",
  industries: "Industries",
  flush_cq: "Flush Candidate Queue",
  atcs: "Manual Add to Stack",
  paused: "Project Paused",
  unpaused: "Projected Unpaused",
  archived: "Project Archived",
  unarchived: "Project Unarchived",
};
