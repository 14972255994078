import { get, put, post } from "@/utils";

const state = () => ({
  dashboardSuccessData: null,
  dashboardFailureData: null,
  dashboardLoading: false,

  projectSuccessData: null,
  projectFailureData: null,
  projectLoading: false,

  changeLogSuccessData: null,
  changeLogFailureData: null,
  changeLogLoading: false,

  changeJustificationSuccessData: null,
  changeJustificationFailureData: null,
  changeJustificationLoading: false,

  changeProjectInfoLoading: false,

  refreshLoading: false,
});

const getters = {
  dashboardSuccessData: (state) => state.dashboardSuccessData,
  dashboardFailureData: (state) => state.dashboardFailureData,
  dashboardLoading: (state) => state.dashboardLoading,

  projectSuccessData: (state) => state.projectSuccessData,
  projectFailureData: (state) => state.projectFailureData,
  projectLoading: (state) => state.projectLoading,

  changeLogSuccessData: (state) => state.changeLogSuccessData,
  changeLogFailureData: (state) => state.changeLogFailureData,
  changeLogLoading: (state) => state.changeLogLoading,

  changeJustificationSuccessData: (state) =>
    state.changeJustificationSuccessData,
  changeJustificationFailureData: (state) =>
    state.changeJustificationFailureData,
  changeJustificationLoading: (state) => state.changeJustificationLoading,

  changeProjectInfoLoading: (state) => state.changeProjectInfoLoading,

  refreshLoading: (state) => state.refreshLoading,
};

const mutations = {
  DASHBOARD_DATA_PROCESS: (state, data) => {
    state.dashboardLoading = data;
  },
  DASHBOARD_DATA_SUCCESS: (state, data) => {
    state.dashboardSuccessData = data;
    state.dashboardLoading = false;
  },
  DASHBOARD_DATA_FAILURE: (state, data) => {
    state.dashboardFailureData = null;
    state.dashboardFailureData = data;
    state.dashboardLoading = false;
  },

  PROJECT_DATA_PROCESS: (state, data) => {
    state.projectLoading = data;
  },

  PROJECT_DATA_SUCCESS: (state, data) => {
    state.projectSuccessData = data;
    state.projectLoading = false;
  },
  PROJECT_DATA_FAILURE: (state, e) => {
    state.projectFailureData = null;
    state.projectFailureData = e;
    state.projectLoading = false;
  },

  CHANGE_LOG_DATA_PROCESS: (state, data) => {
    state.changeLogLoading = data;
  },
  CHANGE_LOG_DATA_SUCCESS: (state, data) => {
    state.changeLogSuccessData = data;
    state.changeLogLoading = false;
  },
  CHANGE_LOG_DATA_FAILURE: (state, e) => {
    state.changeLogFailureData = null;
    state.changeLogFailureData = e;
    state.changeLogLoading = false;
  },

  CHANGE_JUSTIFICATION_PROCESS: (state, data) => {
    state.changeJustificationLoading = data;
  },
  CHANGE_JUSTIFICATION_SUCCESS: (state, data) => {
    state.changeJustificationSuccessData = data;
    state.changeJustificationLoading = false;
  },
  CHANGE_JUSTIFICATION_FAILURE: (state, e) => {
    state.changeJustificationFailureData = null;
    state.changeJustificationFailureData = e;
    state.changeJustificationLoading = false;
  },

  CHANGE_PROJECT_INFO_PROCESS: (state, data) => {
    state.changeProjectInfoLoading = data;
  },

  REFRESH_PROCESS: (state, data) => {
    state.refreshLoading = data;
  },
};

const actions = {
  async getDashboardData({ commit }, params) {
    commit("DASHBOARD_DATA_PROCESS", true);
    const res = await get(
      `/operations_dash${
        params?.includePaused ? `?include_paused=${params?.includePaused}` : ""
      }${
        params?.include_archived
          ? `&include_archived=${params?.include_archived}`
          : ""
      }`
    );
    commit("DASHBOARD_DATA_SUCCESS", res?.data);
    commit("DASHBOARD_DATA_PROCESS", false);
  },
  async getProjectInfo({ commit }, id) {
    commit("PROJECT_DATA_PROCESS", true);
    const res = await get(`/operations_dash/data?project_id=${id}`);
    commit("PROJECT_DATA_SUCCESS", res);
    commit("PROJECT_DATA_PROCESS", false);
  },
  async getChangeLogData({ commit }, id) {
    commit("CHANGE_LOG_DATA_PROCESS", true);
    const res = await get(`/operations_dash/changelog?project_id=${id}`);
    commit("CHANGE_LOG_DATA_SUCCESS", res?.data);
    commit("CHANGE_LOG_DATA_PROCESS", false);
  },
  async changeJustification({ commit }, payload) {
    commit("CHANGE_JUSTIFICATION_PROCESS", true);
    const res = await put("/operations_dash/changelog", payload);
    commit("CHANGE_JUSTIFICATION_SUCCESS", res);
    commit("CHANGE_LOG_DATA_SUCCESS", res?.data);
    commit("CHANGE_JUSTIFICATION_PROCESS", false);
    return res;
  },
  async changeProjectInfo({ commit }, payload) {
    commit("CHANGE_PROJECT_INFO_PROCESS", true);
    const res = await put("/operations_dash/data", payload);
    console.log(res);
    // commit("PROJECT_DATA_SUCCESS", res);
    commit("CHANGE_PROJECT_INFO_PROCESS", false);
    return res;
  },
  async refreshProject({ commit }, id) {
    commit("REFRESH_PROCESS", true);
    await post(`/operations_dash/data?project_id=${id}`);
    commit("REFRESH_PROCESS", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
