<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div class="subscription-container">
    <div class="content">
      <h1>Job Slots Subscription Purchased by Companies</h1>
      <p>Sort by Companies, purchased and unused slots</p>
      <div class="d-flex gap-3 align-center search-box">
        <div class="search-label">Companies:</div>
        <v-text-field
          v-model="searchTxt"
          hide-details
          placeholder="All (default)"
          class="search-text"
          @input="onSearch"
        ></v-text-field>
        <div>
          <v-btn @click="onSearch" icon class="search-icon">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="table-container">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="dataToShow"
          item-key="name"
          show-select
          :loading="loading"
        >
          <template v-slot:header.name="{ header }">
            <div>{{ header.text }}</div>
            <div class="button-header">
              <v-btn @click="selectAll" text height="24"
                >Select All/Clear</v-btn
              >
              <v-btn
                class="pa-2"
                :class="{ active: isShowSelected }"
                @click="showSelected"
                elevation="0"
                height="24"
                >Show Selected</v-btn
              >
            </div>
          </template>
          <template v-slot:header.job_notes="{ header }">
            <div class="text-center">{{ header.text }}</div>
          </template>
          <template v-slot:header.autopilot_slots="{ header }">
            <div>{{ header.text }}</div>
            <div class="pb-2">
              <v-btn height="24" elevation="0" class="sort-btn">
                Order <v-icon size="18">mdi-arrow-up</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:header.unused_jobs="{ header }">
            <div>{{ header.text }}</div>
            <div class="pb-2">
              <v-btn elevation="0" height="24" class="sort-btn">
                Order <v-icon size="18">mdi-arrow-up</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:item.job_notes="{ item }">
            <div class="relative text-content notes">
              <div v-if="!item.isEditNote">{{ item.job_notes }}</div>
              <v-text-field
                v-else
                v-model="notesTxt"
                hide-details
              ></v-text-field>
              <v-icon
                v-if="!item.isEditNote"
                small
                class="edit-icon"
                @click="editNote(item.name)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                v-else
                small
                class="edit-icon"
                @click="updateNote(item.name)"
              >
                mdi-check-bold
              </v-icon>
            </div>
          </template>
          <template v-slot:item.autopilot_slots="{ item }">
            <div class="relative">
              <div class="text-center text-content">
                <span v-if="!item.isEditPurchased">
                  {{ item.autopilot_slots }}
                </span>
                <v-text-field
                  v-else
                  v-model="purchasedTxt"
                  hide-details
                ></v-text-field>
              </div>
              <v-icon
                v-if="!item.isEditPurchased"
                small
                :loading="loading"
                class="edit-icon"
                @click="editPurchasedJobs(item.name)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                v-else
                small
                class="edit-icon"
                :loading="loading"
                @click="updatePurchasedJobs(item.name)"
              >
                mdi-check-bold
              </v-icon>
            </div>
          </template>
          <template v-slot:item.unused_jobs="{ item }">
            <div
              class="text-center unused-jobs"
              :class="{ 'unused-jobs-active': Number(item.unused_jobs) }"
            >
              {{ item.unused_jobs }}
            </div>
          </template>
          <template v-slot:item.name="{ item }">
            <div
              class="red-jobs"
              :class="{ 'red-jobs-active': Number(item.unused_jobs) }"
            >
              {{ item.name }}
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>
<script>
import sortIcon from "@/assets/images/order-icon.png";
import { get, put } from "@/utils";

export default {
  name: "SubscriptionPage",
  computed: {
    dataToShow() {
      return this.isShowSelected
        ? this.selected
        : this.tableData.length
        ? this.tableData.filter((item) =>
            item.name?.toUpperCase()?.includes(this.searchString?.toUpperCase())
          )
        : [];
    },
  },
  data() {
    return {
      loading: false,
      searchTxt: "",
      selected: [],
      sortIcon,
      isShowSelected: false,
      searchString: "",
      purchasedTxt: "",
      notesTxt: "",
      isEditUnused: false,
      headers: [
        {
          text: "Company name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Purchased Jobs", align: "center", value: "autopilot_slots" },
        { text: "Unused Jobs", align: "center", value: "unused_jobs" },
        { text: "Notes", value: "job_notes", sortable: false },
      ],
      tableData: [],
    };
  },
  methods: {
    showSelected() {
      this.isShowSelected = !this.isShowSelected;
    },
    editPurchasedJobs(name) {
      let ind = -1;
      this.dataToShow.forEach((element, i) => {
        if (element.name === name) ind = i;
        element.isEditPurchased = false;
      });
      if (ind === -1) return;
      this.dataToShow[ind].isEditPurchased = true;
      this.purchasedTxt = this.dataToShow[ind]?.autopilot_slots || "";
    },
    updatePurchasedJobs(name) {
      let ind = -1;
      this.dataToShow.forEach((element, i) => {
        if (element.name === name) ind = i;
      });
      if (ind === -1) return;
      this.dataToShow[ind].isEditPurchased = false;
      this.dataToShow[ind].autopilot_slots = this.purchasedTxt;
      this.updateData(this.dataToShow[ind]);
    },
    editNote(name) {
      let ind = -1;
      this.dataToShow.forEach((element, i) => {
        if (element.name === name) ind = i;
        element.isEditNote = false;
      });
      if (ind === -1) return;
      this.dataToShow[ind].isEditNote = true;
      this.notesTxt = this.dataToShow[ind]?.job_notes || "";
    },
    updateNote(name) {
      let ind = -1;
      this.dataToShow.forEach((element, i) => {
        if (element.name === name) ind = i;
      });
      if (ind === -1) return;
      this.dataToShow[ind].isEditNote = false;
      this.dataToShow[ind].job_notes = this.notesTxt;
      this.updateData(this.dataToShow[ind]);
    },
    selectAll() {
      if (this.selected.length === this.tableData.length) {
        this.selected = [];
      } else {
        this.selected = [...this.tableData];
      }
    },
    onSearch() {
      this.searchString = this.searchTxt;
    },
    formatData(data = []) {
      return data.map((item) => {
        return {
          ...item,
          isEditPurchased: false,
          isEditNote: false,
          unused_jobs: item.unused_jobs ? item.unused_jobs : 0,
          job_notes: item.job_notes ? item.job_notes : "",
        };
      });
    },
    async getData() {
      try {
        this.loading = true;
        const res = await get(`/operations_dash/jobslots`);
        if (res.status === 200) {
          this.tableData = this.formatData(res.data);
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async updateData(v) {
      const params = {
        slots: v.autopilot_slots,
        name: v.name,
        job_notes: v.job_notes,
      };
      try {
        this.loading = true;
        const res = await put(`/operations_dash/jobslots`, params);
        if (res.status === 200) {
          this.tableData = this.formatData(res.data);
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/main.scss";
.subscription-container {
  padding: 0px 0 36px 0;
  margin: 0px 36px 16px 36px;
  box-shadow: -1px 1px 9px 0 rgba(0, 0, 0, 0.2);
  width: calc(100% - 72px);
  border-radius: 20px;
  background-color: #fff;
  position: relative;
  z-index: 10;
  padding: 32px;
  .content {
    max-width: 990px;
    margin: 0 auto;
  }
  h1 {
    font-size: 18px;
    color: #384955;
    font-weight: 600;
    margin: 0px;
  }
  p {
    font-size: 12px;
    color: #6b7280;
    margin: 4px 0 0;
  }
  .search-box {
    max-width: 320px;
    margin-top: 16px;
    border-bottom: 1px solid #e6e7eb;
    font-size: 14px;
    color: #111827;
    .search-icon {
      background: #e6e7eb;
      border-radius: 2px;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .search-label {
      font-size: 14px;
      font-weight: 700;
      color: #111827;
    }
  }
}
.gap-3 {
  gap: 12px;
}
.search-text {
  padding: 0px;
  &::v-deep {
    .v-input__slot {
      &::before,
      &::after {
        display: none;
      }
    }
    input {
      padding: 0px;
      font-size: 14px;
      color: #111827;
    }
  }
}
.table-container {
  margin-top: 16px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  &::v-deep {
    tr td,
    tr th {
      color: #111827 !important;
      padding-left: 5px !important;
      &:first-child {
        padding-left: 10px !important;
        padding-right: 0px;
      }
    }
    tr th {
      position: relative;
      .v-data-table__checkbox {
        position: relative;
        bottom: -10px;
        margin-right: -5px;
      }
    }
  }
}
.edit-icon {
  position: absolute !important;
  top: 50%;
  transform: translate(0, -50%);
  right: 0px;
}
.relative {
  position: relative;
}
.unused-jobs {
  color: #a1a5a6;
  &.unused-jobs-active {
    color: #ca3d3d;
  }
}
.red-jobs {
  color: #111827;
  &.red-jobs-active {
    color: #ca3d3d;
  }
}
::v-deep {
  .v-data-table-header th {
    color: #111827;
    font-size: 14px !important;
    .v-data-table-header__icon {
      display: none;
    }
  }
  .v-text-field .v-label {
    top: -3px;
  }
  .v-input {
    margin: 0px;
    padding: 0px;
  }
}
.button-header {
  &::v-deep {
    .v-btn {
      text-transform: none !important;
      font-size: 12px !important;
      font-weight: 400;
      padding: 0px !important;
      margin-right: 24px;
      background-color: transparent !important;
      &.pa-2 {
        padding: 8px !important;
        border: 1px solid #a1a5a6;
      }
      &.active {
        background-color: #f5f5f5 !important;
      }
    }
  }
}
.text-content {
  min-height: 12px;
}
.notes {
  min-width: 300px;
}
.sort-btn {
  border: 1px solid #a1a5a6;
  padding: 8px !important;
  font-size: 12px !important;
  color: #384955 !important;
  background-color: #fff !important;
  text-transform: none !important;
}
</style>
