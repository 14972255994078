import { render, staticRenderFns } from "./TechnicalInformation.vue?vue&type=template&id=247c97cb&scoped=true&"
import script from "./TechnicalInformation.vue?vue&type=script&lang=js&"
export * from "./TechnicalInformation.vue?vue&type=script&lang=js&"
import style0 from "./TechnicalInformation.vue?vue&type=style&index=0&id=247c97cb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247c97cb",
  null
  
)

export default component.exports