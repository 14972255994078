<template>
  <div class="panel__body">
    <div class="panel__body--left-col">
      <div class="panel__body--left-col__content">
        <div v-for="(item, j) in candidate.changes" :key="`change_${j}`">
          <FieldItem :changes="item" changesDefault="" />
        </div>
      </div>
    </div>
    <div class="panel__body--right-col">
      <div class="panel__body--right-col__title">Justification</div>
      <div
        class="justifycation-read-only"
        @click="onClickText"
        v-html="justificationReadOnly"
      ></div>
      <v-btn class="panel__body--right-col__btn">
        <v-icon @click="sendJustification" color="#fff">mdi-send</v-icon>
      </v-btn>
      <VueEditor
        class="custom-editor"
        :editorToolbar="customToolbar"
        v-model="justification"
        ref="editor"
      />
    </div>
    <v-snackbar
      centered
      color="#384955"
      v-model="successSnackbar"
      :timeout="timeout"
    >
      Change log justification has been saved.
    </v-snackbar>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import FieldItem from "./FieldItem.vue";
import { mapActions } from "vuex";

export default {
  name: "ChangeLogItem",

  props: {
    candidate: {
      type: Object,
      default: () => {},
    },
    projectId: String,
  },

  computed: {
    justificationReadOnly() {
      return this.candidate.justification;
    },
    justificationChange() {
      return `${this.justificationReadOnly}${this.justification}`;
    },
  },

  data() {
    return {
      justification: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
      ],
      timeout: 4000,
      successSnackbar: false,
    };
  },

  components: {
    VueEditor,
    FieldItem,
  },

  methods: {
    ...mapActions("operations", ["changeJustification"]),
    async sendJustification() {
      const payload = {
        change_justification: this.justificationChange,
        change_id: this.candidate.id,
        project_id: this.projectId,
      };
      const res = await this.changeJustification(payload);
      if (res?.status === 200) this.successSnackbar = true;
    },
    onClickText() {
      this.$refs.editor.quill.focus();
    },
  },

  watch: {
    candidate() {
      this.justification = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";

.panel {
  &__container {
    padding-right: 10px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--avatar {
      width: 42px;
      height: 42px;
      img {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
        border-radius: 50%;
      }
    }
    &--username {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      color: $gray-dark;
      margin-left: 12px;
    }
    &--role {
      font-size: 12px;
      color: $gray-dark;
      margin-left: 24px;
    }
    &--changed {
      font-size: 16px;
      font-weight: 600;
      color: $gray-dark;
      letter-spacing: 0.2px;
    }
    &--date {
      text-transform: uppercase;
      font-size: 12px;
      color: $gray-dark;
      margin-left: 12px;
      margin-right: 19px;
    }
  }
  &__body {
    display: flex;
    justify-content: space-between;
    padding: 9px 0 6px 0;
    &--left-col {
      width: 70%;
      border-radius: 8px;
      border: solid 1px $border-color;
      padding: 16px 6px 16px 18px;
      &__content {
        height: 100%;
        overflow-y: auto;
      }
    }
    &--right-col {
      width: calc(30% - 24px);
      min-height: 100%;
      position: relative;
      border-radius: 8px;
      border: solid 1px #c9c9c9;
      &__title {
        padding-top: 22px;
        padding-left: 29px;
        color: $gray-dark;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
      }
      &__btn {
        position: absolute;
        z-index: 10000;
        background-color: $primary !important;
        border-radius: 2px !important;
        box-shadow: none !important;
        min-width: 26px !important;
        height: 26px !important;
        padding: 0 !important;
        right: 18px;
        bottom: 10.5px;
      }
    }
  }
}
.v-expansion-panel {
  margin-top: 6px !important;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2) !important;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
.v-expansion-panel-header {
  padding: 13px 20px !important;
}
.no-border {
  border: none !important;
  padding-left: 0 !important;
}
::v-deep {
  .ql-editor {
    min-height: 250px !important;
    font-size: 14px;
    line-height: 19px;
    color: $gray-dark;
    padding-left: 29px;
    padding-right: 29px;
  }
  .ql-toolbar {
    position: absolute;
    width: 100%;
    bottom: 0;
    border: none !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  .ql-container {
    height: auto;
  }
  .ql-toolbar {
    border-top: solid 1px $border-color !important;
  }
  .ql-container.ql-snow {
    border: none !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  .ql-formats {
    margin-right: 5px !important;
  }
  #quill-container {
    padding-top: 0px;
  }
  .ql-snow.ql-toolbar button.ql-active .ql-stroke {
    stroke: $primary !important;
    color: $primary !important;
  }
  .ql-snow.ql-toolbar button:hover .ql-fill {
    color: $primary !important;
    fill: $primary !important;
  }
  .ql-snow.ql-toolbar button:hover .ql-stroke {
    stroke: $primary !important;
    color: $primary !important;
  }
  .ql-snow.ql-toolbar button.ql-active .ql-fill {
    stroke: $primary !important;
    color: $primary !important;
    fill: $primary !important;
  }
}
.custom-editor {
  min-height: 250px;
}
.justifycation-read-only {
  padding-top: 12px;
  padding-right: 29px;
  padding-left: 29px;
  font-size: 14px;
  color: #384955;
}
</style>
