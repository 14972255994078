<template>
  <div>
    <div
      :class="{ 'margin-box': isSticky }"
      class="body__content"
      v-if="activeTabName === 'ChangeLog'"
    >
      <ChangeLog :projectId="projectId" />
    </div>
    <div
      class="body__content"
      :class="{ 'margin-box': isSticky }"
      v-if="activeTabName === 'ProjectData'"
    >
      <ProjectData @change-trial="changeTrial" :date="filterDate" />
    </div>
    <div
      class="body__content"
      :class="{ 'margin-box': isSticky }"
      v-if="activeTabName === 'jobSubmission'"
    >
      <ProjectData
        @change-trial="changeTrial"
        :date="filterDate"
        :showJobSubmission="true"
      />
    </div>
  </div>
</template>

<script>
import ChangeLog from "@/components/ChangeLog";
import ProjectData from "@/components/ProjectData.vue";

export default {
  data() {
    return {};
  },
  props: {
    activeTabName: String,
    filterDate: String,
    isSticky: Boolean,
    projectId: String,
  },
  components: {
    ChangeLog,
    ProjectData,
  },
  methods: {
    changeTrial(payload) {
      this.$emit("change-trial", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";
</style>
