<template>
  <div class="export">
    <div class="export__container" v-if="isLogined">
      <div class="export__header">
        <div class="export__title">Select and Export</div>
        <v-btn
          @click="onExportInboundAsCSV"
          class="export__header--btn"
          width="300px"
          height="48px"
          :disabled="isInboundDisabled"
        >
          Export Inbound Candidates As CSV
        </v-btn>
        <v-btn
          @click="onExportAsCSV"
          class="export__header--btn"
          width="153px"
          height="48px"
          :disabled="isDisabled"
        >
          Export As CSV
        </v-btn>
      </div>

      <div class="export__steps">
        <div>Step 1</div>
        <div>Step 2</div>
        <div>Step 3</div>
      </div>

      <div class="export__body">
        <div class="export__body--item">
          <div class="export__body--item__title">Select Projects</div>
          <div class="export__body--item__content">
            <div class="export__body--item__selected-box">
              <div class="export__body--item__selected-box--header">
                <v-checkbox
                  :ripple="false"
                  label="Select All/Clear All"
                  v-model="selectAll"
                  color="#384955"
                  hide-details
                  class="select-all-checkbox"
                ></v-checkbox>
                <v-checkbox
                  :ripple="false"
                  label="Include Archived"
                  v-model="isIncludeArchived"
                  color="#384955"
                  hide-details
                  @change="getProjectIncludesArchived"
                  class="select-all-checkbox"
                ></v-checkbox>
                <div>
                  <v-menu
                    v-model="showSelected"
                    min-width="500px"
                    nudge-left="371px"
                    offset-y
                    bottom
                    content-class="export__body--item__selected-box--menu"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        height="30px"
                        class="select-all-btn ml-1"
                        text
                        v-bind="attrs"
                        v-on="on"
                      >
                        Show Selected
                      </v-btn>
                    </template>
                    <v-list>
                      <div
                        class="export__body--item__selected-box--menu__header"
                      >
                        Selected Projects
                      </div>
                      <div class="py-4 selected-box--menu__container">
                        <div
                          v-for="(item, i) in companiesItems"
                          :key="`company_${i}`"
                          class="ml-6"
                        >
                          <v-checkbox
                            :ripple="false"
                            v-model="item.model"
                            @change="changeCompany(item.company, $event)"
                            color="#384955"
                            hide-details
                            class="export__body--item__checkbox-company"
                            v-if="showCompanyName(item)"
                          >
                            <template v-slot:label>
                              <span class="checkbox-label">{{
                                item.company
                              }}</span>
                            </template>
                          </v-checkbox>
                          <div
                            v-for="(project, j) in item.projects"
                            :key="`project__${j}`"
                          >
                            <v-checkbox
                              :ripple="false"
                              v-model="selectedCompanies"
                              v-if="
                                selectedCompanies.includes(project.project_id)
                              "
                              :label="project.name"
                              :value="project.project_id"
                              @change="changeProject"
                              color="#384955"
                              hide-details
                              class="export__body--item__checkbox-project ml-3"
                            ></v-checkbox>
                          </div>
                        </div>
                      </div>
                    </v-list>
                  </v-menu>
                </div>
              </div>
            </div>
            <div class="export__body--item__checkbox-box--other">
              <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel
                  v-for="(item, i) in companiesItems"
                  :key="`company_${i}`"
                >
                  <v-expansion-panel-header class="d-flex">
                    <div class="d-flex">
                      <v-checkbox
                        :ripple="false"
                        v-model="item.model"
                        @change="changeCompany(item.company, $event)"
                        color="#384955"
                        hide-details
                        class="export__body--item__checkbox-company"
                      >
                        <template v-slot:label>
                          <span class="checkbox-label">{{ item.company }}</span>
                        </template>
                      </v-checkbox>
                    </div>
                    <template v-slot:actions>
                      <v-icon class="plus-icon" color="#384955"
                        >mdi-plus</v-icon
                      >
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-for="(project, j) in item.projects"
                      :key="`project__${j}`"
                    >
                      <v-checkbox
                        :ripple="false"
                        v-model="selectedCompanies"
                        :label="project.name"
                        :value="project.project_id"
                        @change="changeProject"
                        color="#384955"
                        hide-details
                        class="export__body--item__checkbox-project"
                      ></v-checkbox>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </div>
        <div class="export__body--item">
          <div class="export__body--item__title">Select Items to Export</div>
          <div class="export__body--item__content">
            <div class="d-flex justify-center pa-4 pt-6">
              <div @click="onPerformanceClick" class="title-button">
                Performance Metrics
              </div>
            </div>
            <v-checkbox
              v-for="(item, i) in exportItems"
              :key="`checkbox_${i}`"
              :ripple="false"
              :label="item.label"
              v-model="exportItemsValue"
              color="#384955"
              :value="item.id"
              hide-details
              class="export__body--item__checkbox"
            ></v-checkbox>
            <div class="d-flex justify-center pa-4 pt-6">
              <div @click="onApproveClick" class="title-button">
                Candidate Profiles
              </div>
            </div>
            <v-checkbox
              v-for="(item, i) in candidateProfiles"
              :key="`checkbox_candidate-${i}`"
              :ripple="false"
              :label="item.label"
              v-model="candidateProfilesValue"
              color="#384955"
              :value="item.id"
              hide-details
              class="export__body--item__checkbox"
            ></v-checkbox>
          </div>
        </div>
        <div class="export__body--item">
          <div class="export__body--item__title">Select Days</div>
          <div class="export__body--item__content">
            <v-radio-group v-model="selectedDate" hide-details>
              <v-radio
                v-for="(item, i) in dateItems"
                :key="`radio_${i}`"
                :off-icon="'mdi-checkbox-blank-outline'"
                :on-icon="'mdi-checkbox-marked'"
                :ripple="false"
                :label="item.label"
                color="#384955"
                :value="item.id"
                hide-details
                class="export__body--item__radio"
              ></v-radio>
              <i class="italic">(default)</i>
            </v-radio-group>
          </div>
        </div>
      </div>
    </div>
    <OperatorAuth v-else />
    <v-snackbar
      centered
      :color="success ? '#384955' : '#CA3D3D'"
      v-model="isSnackbar"
      :timeout="timeout"
    >
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
import { post } from "@/utils";
import { mapGetters, mapActions } from "vuex";
// import Papa from "papaparse";
import OperatorAuth from "@/components/OperatorAuth";

export default {
  name: "export-page",
  components: {
    OperatorAuth,
  },
  data() {
    return {
      isIncludeArchived: false,
      showSelected: false,
      selectedCompanies: [],
      isSnackbar: false,
      message: "",
      success: false,
      timeout: 4000,
      candidateProfilesValue: [],
      dateItems: [
        {
          label: "7 days",
          id: "last_7",
        },
        {
          label: "14 days",
          id: "last_14",
        },
        {
          label: "30 days",
          id: "last_30",
        },
        {
          label: "60 days",
          id: "last_60",
        },
        {
          label: "90 days",
          id: "last_90",
        },
        {
          label: "All time",
          id: "all_time",
        },
      ],
      selectedDate: "last_14",
      exportItems: [
        {
          label: "Days Active on Hirefly",
          id: "general_information.all_time_stats.days_active",
        },
        {
          label: "Number of Candidates Presented",
          id: "general_information.last_n_days.presented",
        },
        {
          label: "Number of Candidates Approved",
          id: "general_information.last_n_days.hm_approved",
        },
        {
          label: "Number of Candidates Pending Company Review",
          id: "general_information.current_pipeline.hm_review",
        },
        {
          label: "Avg. Number of Candidates Presented per Week",
          id: "general_information.last_n_days.avg_presented_per_week",
        },
        {
          label: "Hiring Manager Approval Rate",
          id: "general_information.last_n_days.hm_approval_rate",
        },
      ],
      candidateProfiles: [
        {
          label: "Approved Candidates",
          id: "export_hm_approved",
        },
        {
          label: "Approved Candidates in ATS",
          id: "export_ats_synced",
        },
      ],
      exportItemsValue: [
        "general_information.all_time_stats.days_active",
        "general_information.last_n_days.presented",
        "general_information.last_n_days.hm_approved",
        "general_information.current_pipeline.hm_review",
        "general_information.last_n_days.avg_presented_per_week",
        "general_information.last_n_days.hm_approval_rate",
      ],
      panel: [],
    };
  },
  computed: {
    ...mapGetters("operations", ["dashboardSuccessData"]),
    ...mapGetters("auth", ["isLogined"]),
    companiesItems() {
      if (this.dashboardSuccessData) {
        let arr = this.dashboardSuccessData.projects;
        arr = arr.sort((a, b) => {
          const c = a.company.toUpperCase().trim();
          const d = b.company.toUpperCase().trim();
          if (c < d) {
            return -1;
          }
          if (c > d) {
            return 1;
          }
          return 0;
        });
        const groups = [];
        let ind = 0;
        arr.forEach((item) => {
          if (
            groups.length &&
            !(
              groups[ind] &&
              groups[ind].projects &&
              groups[ind].projects.filter(
                (e) =>
                  e.company.toUpperCase().trim() ===
                  item.company.toUpperCase().trim()
              ).length
            )
          ) {
            ind++;
          }
          if (!groups[ind]) {
            groups[ind] = {};
            groups[ind].projects = [];
            groups[ind].company = item.company;
            groups[ind].model = false;
          }
          groups[ind].projects.push(item);
        });
        return groups;
      }
      return [];
    },
    allCompanies() {
      let arr = [];
      this.companiesItems.forEach((item) => {
        item.projects.forEach((project) => {
          arr.push(project);
        });
      });
      return arr;
    },
    selectAll: {
      set(val) {
        this.selectedCompanies = [];
        this.companiesItems.forEach((el) => {
          el.model = false;
        });
        if (val) {
          for (let i = 0; i < this.companiesItems.length; i++) {
            this.companiesItems[i].model = true;
            this.companiesItems[i].projects.forEach((item) => {
              this.selectedCompanies.push(item.project_id);
            });
          }
        }
      },
      get() {
        return this.selectedCompanies.length === this.allCompanies.length;
      },
    },
    isDisabled() {
      if (
        this.selectedCompanies.length &&
        (this.exportItemsValue.length || this.candidateProfilesValue.length) &&
        this.selectedDate
      ) {
        return false;
      }
      return true;
    },
    isInboundDisabled() {
      return this.selectedCompanies.length == 0;
    },
  },
  created() {
    this.getDashboardData({
      includePaused: true,
    });
  },
  methods: {
    ...mapActions("operations", ["getDashboardData"]),
    getProjectIncludesArchived(v) {
      if (v) {
        this.getDashboardData({
          includePaused: true,
          include_archived: true,
        });
      } else {
        this.getDashboardData({
          includePaused: true,
        });
      }
    },
    showCompanyName(item) {
      var selectedcount = 0;
      item.projects.forEach((el) => {
        if (this.selectedCompanies.includes(el.project_id)) {
          selectedcount++;
        }
      });
      if (selectedcount > 0) {
        return true;
      } else return false;
    },
    changeCompany(company, e) {
      if (e) {
        this.companiesItems.forEach((item) => {
          if (item.company === company) {
            item.projects.forEach((project) => {
              if (
                !this.selectedCompanies.filter(
                  (ite) => ite === project.project_id
                ).length
              ) {
                this.selectedCompanies.push(project.project_id);
              }
            });
          }
        });
      } else {
        this.companiesItems.forEach((item) => {
          if (item.company === company) {
            item.projects.forEach((project) => {
              this.selectedCompanies = this.selectedCompanies.filter(
                (el) => el !== project.project_id
              );
            });
          }
        });
      }
    },
    changeProject(e) {
      this.companiesItems.forEach((item) => {
        let selectedcount = 0;
        item.projects.forEach((el) => {
          if (e.includes(el.project_id)) {
            selectedcount++;
          }
        });
        if (selectedcount === item.projects.length) {
          item.model = true;
        } else item.model = false;
      });
    },
    onPerformanceClick() {
      if (this.exportItemsValue.length < this.exportItems.length) {
        this.exportItemsValue = [];
        this.exportItems.forEach((item) => {
          this.exportItemsValue.push(item.id);
        });
      } else {
        this.exportItemsValue = [];
      }
    },
    onApproveClick() {
      if (this.candidateProfilesValue.length < this.candidateProfiles.length) {
        this.candidateProfilesValue = [];
        this.candidateProfiles.forEach((item) => {
          this.candidateProfilesValue.push(item.id);
        });
      } else {
        this.candidateProfilesValue = [];
      }
    },
    async onExportAsCSV() {
      const request = {
        project_ids: [...this.selectedCompanies],
        date_range: this.selectedDate,
        fields_to_export: [
          ...this.exportItemsValue,
          ...this.candidateProfilesValue,
        ],
      };
      if (this.candidateProfilesValue.includes("export_hm_approved")) {
        request.export_hm_approved = true;
      }
      if (this.candidateProfilesValue.includes("export_ats_synced")) {
        request.export_ats_synced = true;
      }
      const res = await post("/operations_dash/export", request);
      if (res.status === 200) {
        this.message = "Please check your email in a few minutes";
        this.success = true;
      } else {
        this.message = "Export failed, please try again later";
        this.success = false;
      }
      this.isSnackbar = true;
      // this.downloadCSV(res.data);
    },
    async onExportInboundAsCSV() {
      const params = {
        project_ids: [...this.selectedCompanies],
      };
      const res = await post("/operations_dash/export_inbound", params);
      if (res.status === 200) {
        this.message = "Please check your email in a few minutes";
        this.success = true;
      } else {
        this.message = "Export failed, please try again later";
        this.success = false;
      }
      this.isSnackbar = true;
    },
    // downloadCSV(data) {
    //   var csv = Papa.unparse([...data]);
    //   let csvContent = "data:text/csv;charset=utf-8,";
    //   csvContent += csv + "\r\n";
    //   csvContent = csvContent
    //     .replaceAll(
    //       "general_information.all_time_stats.days_active",
    //       "Days Active on Hirefly"
    //     )
    //     .replaceAll(
    //       "general_information.last_n_days.presented",
    //       "Number of Candidates Presented"
    //     )
    //     .replaceAll(
    //       "general_information.last_n_days.hm_approved",
    //       "Number of Candidates Approved"
    //     )
    //     .replaceAll(
    //       "general_information.current_pipeline.hm_review",
    //       "Number of Candidates Pending Company Review"
    //     )
    //     .replaceAll(
    //       "general_information.last_n_days.avg_presented_per_week",
    //       "Avg. Number of Candidates Presented per Week"
    //     )
    //     .replaceAll(
    //       "general_information.last_n_days.hm_approval_rate",
    //       "Hiring Manager Approval Rate"
    //     );
    //   var encodedUri = encodeURI(csvContent);
    //   var link = document.createElement("a");
    //   link.setAttribute("href", encodedUri);
    //   const d = new Date();
    //   let month = d.getMonth();
    //   let date = d.getDate();
    //   let year = d.getFullYear();
    //   let hour = d.getHours();
    //   let minutes = d.getMinutes();
    //   let seconds = d.getSeconds();
    //   const numberOfDate = `${month < 10 ? "0" : ""}${month + 1}${
    //     date < 10 ? "0" : ""
    //   }${date}${("" + year).substring(2, 4)}${hour < 10 ? "0" : ""}${hour}${
    //     minutes < 10 ? "0" : ""
    //   }${minutes}${seconds < 10 ? "0" : ""}${seconds}`;
    //   link.setAttribute(
    //     "download",
    //     "hirefly_dash_export_" + numberOfDate + ".csv"
    //   );
    //   link.click();
    // },
  },
  watch: {
    panel(v) {
      const icons = document.getElementsByClassName("plus-icon");
      for (let i = 0; i < icons.length; i++) {
        if (v.includes(i)) {
          icons[i].classList.add("mdi-minus");
          icons[i].classList.remove("mdi-plus");
        } else {
          icons[i].classList.add("mdi-plus");
          icons[i].classList.remove("mdi-minus");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";
.export {
  padding: 0px 0 36px 0;
  margin: 0px 36px 16px 36px;
  box-shadow: -1px 1px 9px 0 rgba(0, 0, 0, 0.2);
  width: calc(100% - 72px);
  border-radius: 20px;
  background-color: #fff;
  position: relative;
  z-index: 10;
  &__close-btn {
    position: fixed;
    right: 25px;
    top: 75px;
  }
  &__container {
    max-width: 1219px;
    margin: 0 auto;
    padding-bottom: 80px;
  }
  &__title {
    text-align: center;
    color: $gray-dark;
    font-size: 22px;
    font-weight: 600;
  }
  &__steps {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    div {
      width: 370px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.35px;
      color: $gray-dark;
      text-align: center;
    }
    div:first-child {
      width: 500px;
    }
    div:last-child {
      width: 300px;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    gap: 0 24px;
    margin-bottom: 36px;
    &--item:first-child {
      min-height: 605px;
      padding-bottom: 0;
    }
    &--item:first-child {
      width: 500px !important;
    }
    &--item:last-child {
      width: 300px !important;
    }
    &--item {
      width: 370px;
      overflow-y: auto;
      border: solid 2px #e6e7eb;
      border-radius: 4px;
      padding-bottom: 8px;
      &__checkbox-box {
        &--all {
          position: relative;
          i {
            top: 3px !important;
            left: 82px !important;
          }
        }
        &--other {
          max-height: 570px;
          overflow-y: auto;
          padding: 10px 6px 12px 12px;
          margin: 5px 6px 5px 5px;
        }
      }
      &__content {
        .italic {
          position: absolute;
          top: 69px;
          left: 117px;
          font-size: 12px;
          color: $gray-dark;
        }
      }

      &__selected-box {
        padding: 12px 12px 11px 12px;
        border-bottom: 1px solid #b9b9b9;
        &--header {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        &--menu {
          top: 300px !important;
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
          max-height: 655px;
          &__header {
            font-size: 14px;
            line-height: 14px;
            font-weight: 500;
            letter-spacing: 0.3px;
            color: $gray-dark;
            text-align: center;
            border-bottom: 1px solid #e6e7eb;
            padding-bottom: 8.5px;
            padding-top: 8px;
          }
        }
      }
      &__radio {
        padding-top: 8.5px;
        padding-bottom: 16.5px;
        border-bottom: 1px solid #e6e7eb;
        padding-left: 36px;
      }
      &__checkbox {
        border-bottom: 1px solid #e6e7eb;
        padding-left: 36px;
        padding-bottom: 16px;
      }
      &__checkbox-all {
        padding-left: 24px;
        padding-bottom: 16px;
        border-bottom: solid 1px $border-color;
      }
      &__checkbox-company {
        text-transform: uppercase;
        &::v-deep .v-input--selection-controls {
          margin-top: 0 !important;
        }
      }
      &__checkbox-project {
        padding-bottom: 0px;
        padding-left: 9px;
      }
      &__title {
        padding: 23px 0 13.5px 0;
        text-align: center;
        border-bottom: solid 1px $border-color;
        font-size: 14px;
        letter-spacing: 0.35px;
        text-transform: uppercase;
        color: $gray-dark;
        font-weight: 700;
      }
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 36px;
    margin-bottom: 24px;
    background-color: #fff;
    &--btn {
      background-color: $primary !important;
      box-shadow: none;
      border-radius: 24px;
      &::v-deep .v-btn__content {
        font-size: 16px;
        color: #fff;
        text-transform: capitalize;
        font-weight: 500;
        letter-spacing: 0;
      }
    }
  }
}
::v-deep {
  .v-label {
    font-size: 12px;
    color: $gray-dark !important;
    font-weight: 500;
    letter-spacing: 0.3px;
    padding-top: 2px;
  }
  .theme--light.v-icon {
    color: #d8d8d8;
    font-size: 18px;
  }
  .theme--light.v-input--is-disabled {
    color: $primary;
  }
  .v-input--selection-controls {
    padding-top: 0 !important;
  }
  .v-input--selection-controls__input {
    margin-right: 0 !important;
  }
}
.project-group {
  border-bottom: solid 1px $border-color;
  padding-bottom: 16px;
}
.v-expansion-panel {
  margin-top: 8px !important;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4) !important;
}
.v-expansion-panel:first-child {
  margin-top: 0px !important;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
.v-expansion-panel-header {
  padding-top: 0px !important;
  padding-left: 15px !important;
  padding-right: 12px !important;
}
.plus-icon {
  margin-top: 16px;
}
.select-all-btn {
  &::v-deep .v-btn__content {
    text-transform: capitalize;
    color: $gray-dark;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
  }
}
.select-all-checkbox {
  margin-top: 0 !important;
}
.selected-box--menu__container {
  max-height: 600px;
  overflow: auto;
}
.title-button {
  font-size: 14px;
  color: $gray-dark;
  cursor: pointer;
  font-weight: 500;
}
</style>
