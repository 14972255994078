<template>
  <div class="sidebar mt-2" id="sidebar">
    <div class="sidebar-sticky">
      <div class="sidebar__header">
        <div class="sidebar__header--title">
          Companies /
          {{ statusOptions[activeStatus] }} Projects
        </div>
        <div class="sidebar__header--buttons mt-3">
          <span class="sidebar__header--texts mr-2">Last Updated</span>
          <span class="sidebar__header--texts mr-2">Urgency</span>
          <span class="sidebar__header--texts mr-4">Priority</span>
        </div>
      </div>
      <div class="sidebar__body">
        <div
          class="sidebar__body--container"
          :class="{ 'sidebar__body--loader-box': dashboardLoading }"
        >
          <v-progress-circular
            v-if="dashboardLoading"
            indeterminate
            color="primary"
            class="custom-loader"
          ></v-progress-circular>
          <template v-else>
            <div
              class="sidebar__body--item"
              v-for="(item, i) in filtredItemsiesList"
              :key="`item_${i}`"
            >
              <div v-if="item.length" class="sidebar__body--item__title">
                {{ i }}
              </div>
              <div v-if="item.length" class="sidebar__body--item__content">
                <div
                  v-for="(project, j) in item"
                  :key="`project_${j}`"
                  class="sidebar__body--item__content--item"
                  :class="{
                    'sidebar__body--item__content--item-active':
                      project.project_id === activeProject.project_id,
                  }"
                  @click="changeActiveProject(project)"
                >
                  <div class="d-flex align-center">
                    <v-icon
                      :color="
                        project.isActive
                          ? '#fff'
                          : project.isAutopilot
                          ? '#4646AB'
                          : '#1DA1F2'
                      "
                      size="16"
                    >
                      {{
                        project.isAutopilot
                          ? "mdi-steering"
                          : "mdi-compass-outline"
                      }}
                    </v-icon>
                    <SidebarTitleItemVue
                      :name="projectName(project)"
                      v-if="projectName(project)"
                    />
                  </div>
                  <div class="d-flex justify-end align-center">
                    <div class="sidebar__body--item__content--item__nums-first">
                      {{ lastChange(project) }}
                    </div>
                    <div class="sidebar__body--item__content--item__nums-first">
                      {{ fixedUrgency(project.urgency) }}
                    </div>
                    <div class="sidebar__body--item__content--item__nums">
                      {{ project.bip || 0 }}
                    </div>
                    <div class="sidebar__body--item__content--item__icon">
                      <template>
                        <div class="text-center">
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="select-icon"
                                color="#384955"
                                size="19"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-chevron-down</v-icon
                              >
                            </template>
                            <v-list>
                              <div
                                :class="{ 'is-active': project.bip === number }"
                                v-for="number in priorityItems"
                                :key="number"
                                class="select-item"
                                @click="changeBip(number)"
                              >
                                {{ number }}
                              </div>
                            </v-list>
                          </v-menu>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SidebarTitleItemVue from "./SidebarTitleItem";

export default {
  name: "left-sidebar",
  components: {
    SidebarTitleItemVue,
  },
  props: {
    activeStatus: String,
    filtredCompanies: Array,
    filtredOwners: Array,
    projectId: String,
    filterProjectId: String,
  },
  data() {
    return {
      activeProject: {},
      filteredProjectsCounts: {
        new: 0,
        slow_pace: 0,
        hm_approval: 0,
        borderline: 0,
        free_trial: 0,
        free_trial_debrief: 0,
        healthy: 0,
        backlog: 0,
        new_review: 0,
        client_delay: 0,
        narrow: 0,
        self_serve: 0,
      },
      priorityItems: [1, 2, 3, 4, 5],
      statusOptions: {
        backlog: "Backlog",
        slow_pace: "Slow Pace",
        hm_approval: "Approval Rate",
        new: "To - Do",
        new_review: "For Review",
        client_delay: "By Client",
        free_trial: "Active",
        free_trial_debrief: "Debrief",
        borderline: "Borderline",
        healthy: "Healthy",
        self_serve: "Self Serve",
      },
    };
  },
  computed: {
    ...mapGetters("operations", [
      "dashboardLoading",
      "dashboardSuccessData",
      "dashboardFailureData",
    ]),
    filtredItemsiesList() {
      let obj = {};
      if (this.dashboardSuccessData) {
        let projectList = this.dashboardSuccessData.projects;
        if (this.filterProjectId && projectList) {
          projectList = projectList.filter(
            (proj) => proj.project_id === this.filterProjectId
          );
        }
        obj = projectList.reduce(function (r, a) {
          r[a.company.toUpperCase().trim()] =
            r[a.company.toUpperCase().trim()] || [];
          r[a.company.toUpperCase().trim()].push(a);
          return r;
        }, Object.create(null));
        if (this.activeStatus) {
          for (let company in obj) {
            obj[company] = obj[company].filter(
              (project) => project.operations_status === this.activeStatus
            );
          }
        }
        if (this.filtredCompanies) {
          for (let company in obj) {
            if (!this.filtredCompanies.find((el) => el === company)) {
              obj[company] = [];
            }
          }
        }
        if (this.filtredOwners) {
          for (let company in obj) {
            obj[company] = obj[company].filter((project) =>
              this.filtredOwners.includes(project.owner)
            );
          }
        }
        for (let company in obj) {
          obj[company] = obj[company].filter(
            (project) => project.operations_status !== "paused"
          );
          if (!obj[company] || obj[company].length === 0) {
            delete obj[company];
          }
        }
      }
      return Object.keys(obj)
        .sort()
        .reduce((item, key) => {
          item[key] = obj[key].sort((a, b) => {
            const text1 = this.projectName(a);
            const text2 = this.projectName(b);
            if (text1 < text2) {
              return -1;
            }
            if (text1 > text2) {
              return 1;
            }
            return 0;
          });
          return item;
        }, {});
    },
  },
  methods: {
    changeBip(number) {
      const payload = {
        project_id: this.activeProject.project_id,
        bip: number,
      };
      this.$emit("change-bip", payload);
    },
    changeActiveProject(project) {
      this.activeProject = project;
    },
    fixedUrgency(urgency) {
      return urgency.toFixed(1);
    },
    projectName(project) {
      let name = project.name;
      const regex = /^.+-/;
      return name.replace(regex, "").trim();
    },
    lastChange(project) {
      if (!project.last_changed || project.last_changed === "Not Available") {
        return "N/A";
      } else return project.last_changed;
    },
    filterProjects() {
      if (this.dashboardSuccessData) {
        var allProjects = [];
        for (let company in this.filtredItemsiesList) {
          if (this.filtredItemsiesList[company].length) {
            this.filtredItemsiesList[company].forEach((project) => {
              allProjects.push(project);
            });
          }
        }
        if (allProjects.length) {
          if (this.projectId) {
            const proj = allProjects.find(
              (item) => item.project_id === this.projectId
            );
            if (proj) {
              this.activeProject = proj;
            } else {
              this.activeProject = allProjects[0];
            }
          } else {
            this.activeProject = allProjects[0];
          }
        } else {
          this.activeProject = {};
        }
        for (let i in this.filteredProjectsCounts) {
          this.filteredProjectsCounts[i] = 0;
        }
        var projectsForFilterCount = [];
        let projectList = this.dashboardSuccessData.projects;
        if (this.filterProjectId && projectList) {
          projectList = projectList.filter(
            (proj) => proj.project_id === this.filterProjectId
          );
        }
        projectList.forEach((item) => {
          if (
            this.filtredCompanies.includes(item.company.toUpperCase().trim()) &&
            this.filtredOwners.includes(item.owner)
          ) {
            projectsForFilterCount.push(item);
          }
        });
        projectsForFilterCount.forEach((item) => {
          if (item.operations_status === "new") {
            this.filteredProjectsCounts.new++;
          } else if (item.operations_status === "slow_pace") {
            this.filteredProjectsCounts.slow_pace++;
          } else if (item.operations_status === "hm_approval") {
            this.filteredProjectsCounts.hm_approval++;
          } else if (item.operations_status === "borderline") {
            this.filteredProjectsCounts.borderline++;
          } else if (item.operations_status === "free_trial") {
            this.filteredProjectsCounts.free_trial++;
          } else if (item.operations_status === "free_trial_debrief") {
            this.filteredProjectsCounts.free_trial_debrief++;
          } else if (item.operations_status === "healthy") {
            this.filteredProjectsCounts.healthy++;
          } else if (item.operations_status === "backlog") {
            this.filteredProjectsCounts.backlog++;
          } else if (item.operations_status === "new_review") {
            this.filteredProjectsCounts.new_review++;
          } else if (item.operations_status === "client_delay") {
            this.filteredProjectsCounts.client_delay++;
          } else if (item.operations_status === "narrow") {
            this.filteredProjectsCounts.narrow++;
          } else if (item.operations_status === "self_serve") {
            this.filteredProjectsCounts.self_serve++;
          }
        });
        this.$emit("update-status-counts", this.filteredProjectsCounts);
      }
    },
  },
  watch: {
    activeProject(v) {
      if (Object.keys(v).length === 0) {
        this.$emit("clear");
      }
      const a = v.project_id;
      setTimeout(() => {
        if (a === this.activeProject.project_id)
          this.$emit("change-project", v);
      }, 100);
    },
    activeStatus() {
      this.filterProjects();
    },
    filtredCompanies() {
      this.filterProjects();
    },
    filtredOwners() {
      this.filterProjects();
    },
    filterProjectId() {
      this.filterProjects();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";
.sidebar {
  background: #fff;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 350px;
  min-width: 350px;
  border-radius: 4px;
  &-sticky {
    position: sticky;
    top: 89px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 10;
  }
  &__header {
    background-color: #fff;
    height: 99px;
    padding-top: 28px;
    border-radius: 12px;
    position: relative;
    &::before {
      content: "";
      background: #dddddd;
      height: 1px;
      width: 100%;
      position: absolute;
      top: 100%;
      left: 0px;
    }
    &--title {
      font-size: 16px;
      color: $gray-dark;
      font-weight: 700;
      letter-spacing: 0.5px;
      text-align: center;
      text-transform: capitalize;
    }
    &--buttons {
      text-align: right;
    }
    &--texts {
      color: $gray-dark;
      font-size: 10px;
      text-transform: capitalize;
      border: 1px solid #e6e7eb;
      padding: 2px 4px;
    }
  }
  &__body {
    background-color: #fff;
    height: calc(100vh - 187px);
    margin-top: 1px;
    padding-right: 3px;
    border-radius: 12px;
    &--container {
      height: 100%;
      overflow-y: auto;
      padding-bottom: 20px;
      padding-right: 3px;
    }
    &--loader-box {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--item {
      &__title {
        padding: 17px 23px;
        border-bottom: 1px solid $border-color;
        font-size: 14px;
        color: $gray-dark;
        text-transform: uppercase;
        font-weight: 700;
      }
      &__content {
        &--item {
          padding: 12px 4px 12px 11px;
          border-bottom: 1px solid rgba(201, 201, 201, 0.33);
          transition: 0.3s;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          &__name {
            font-size: 14px;
            font-weight: 500;
            color: $black;
            padding-left: 7px;
            width: 220px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &__nums {
            color: $gray-dark;
            font-size: 14px;
            letter-spacing: 0.28px;
            padding-left: 15px;
          }
          &__nums-first {
            color: $gray-dark;
            font-size: 14px;
            letter-spacing: 0.28px;
            padding-left: 25px;
            min-width: 62px;
            &:first-child {
              padding-left: 12px;
            }
          }
          &__icon {
            margin-left: 5px;
          }
        }
        &--item-active {
          background-color: $primary;
          .sidebar__body--item__content--item__name {
            color: #fff;
            font-weight: 700;
          }
          .sidebar__body--item__content--item__nums {
            color: #fff;
          }
          .sidebar__body--item__content--item__nums-first {
            color: #fff;
          }
          &::v-deep i {
            color: #fff !important;
          }
          .select-icon {
            color: #fff !important;
          }
        }
      }
    }
  }
}
.sticky-header {
  position: fixed;
  top: 52px;
  left: 0;
  height: calc(100vh - 80px);
}
.v-tooltip__content {
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  color: $black !important;
  padding: 4px 7px 4px 7px !important;
  border-radius: 0 !important;
  box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, 0.5);
  opacity: 1 !important;
  line-height: 15px;
}
.no-border {
  border-top: none !important;
}
.select-item {
  font-weight: 500;
  padding: 2px 7px;
  font-size: 14px;
  color: $gray-dark;
  cursor: pointer;
}
.is-active {
  background-color: $primary;
  color: #fff;
}
::v-deep {
  .v-input {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  input {
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 21px !important;
  }
}
</style>
