<template>
  <v-app :class="{ 'white-bg': !isLogined }">
    <Header v-if="isLogined && !isLoginPage" />
    <HeaderTab v-if="isLogined && !isLoginPage" />
    <div class="bg-header" v-if="isLogined && !isLoginPage"></div>
    <router-view class="margin-box" id="home" />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./components/Header";
import HeaderTab from "./components/HeaderTab";
export default {
  components: { Header, HeaderTab },
  name: "App",

  data: () => ({
    //
  }),
  computed: {
    ...mapGetters("auth", ["isLogined"]),
    isLoginPage() {
      return this.$route.name === "login";
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/main.scss";
body {
  background-color: #f5f5f6;
}
.white-bg {
  background-color: #fff !important;
}
.bg-header {
  background-color: $primary;
  height: 170px;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  width: 100%;
}
</style>
