<template>
  <v-img
    class="logo"
    :lazy-src="require('@/assets/images/hirefly-logo-1.png')"
    :height="height"
    :width="width"
    contain
    :src="require('@/assets/images/hirefly-logo-1.png')"
    @click="goToHome()"
  ></v-img>
</template>

<script>
export default {
  name: "hirefly-logo",
  props: {
    height: [String, Number],
    width: [String, Number],
  },
  methods: {
    goToHome() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style scoped>
.logo {
  cursor: pointer;
  max-width: 120px !important;
}
</style>
