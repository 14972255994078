import axios from "axios";
import store from "../store";

axios.defaults.baseURL =
  typeof window.baseUrl !== "undefined"
    ? window.baseUrl
    : "https://operations-dash-backend.herokuapp.com/v1";

const request = async (
  method,
  url,
  data = undefined,
  handleError = false,
  headers = {}
) => {
  axios.defaults.headers[method]["Content-Type"] =
    "application/x-www-form-urlencoded";
  axios.defaults.headers[method]["X-Parse-Session-Token"] =
    localStorage.getItem("Operator-X-Parse-Session-Token");
  try {
    const res = await axios[method](url, data, headers);
    return res;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("Operator-X-Parse-Session-Token");
      store.dispatch("auth/setLogin", false);
    }
    if (handleError) {
      throw error;
    } else {
      return error;
    }
  }
};

const get = async (url, data = null, handleError = false) =>
  request("get", url, data, handleError);
const put = async (url, data, handleError = false) =>
  request("put", url, data, handleError);
const post = async (url, data, handleError = false) =>
  request("post", url, data, handleError);
const postFile = async (url, data, handleError = false) =>
  request("post", url, data, handleError, {
    "Content-Type": "multipart/form-data",
  });
const del = async (url, data, handleError = false) =>
  request("delete", url, data, handleError);

export { get, put, post, postFile, del };
