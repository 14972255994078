<template>
  <div class="custom-text-box-item" @click="onFocusText">
    <ol>
      <li
        @click="onEdit(ele, i)"
        v-for="(ele, i) in items"
        :key="i"
        :class="{ active: isEditMode && i === activeIndex - 1 }"
      >
        <span>{{ ele }}</span>
      </li>
    </ol>
    <div class="d-flex" :class="{ 'opacity-0': !isFocus && items.length > 0 }">
      <div class="number-placeholder" :class="{ hold: !currentTxt }">
        {{ isEditMode ? activeIndex : items.length + 1 }}
      </div>
      <v-textarea
        :placeholder="items.length ? 'Add item' : 'Make a list…'"
        v-model="currentTxt"
        @keydown="onAddItem"
        @blur="onBlur"
        hide-details
        @focus="onFocus"
        class="ma-0 pa-0"
        auto-grow
        rows="1"
        ref="inputTxt"
        v-if="!loading"
      ></v-textarea>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomsTextbox",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentTxt: "",
      loading: false,
      activeIndex: 0,
      isEditMode: false,
      isFocus: false,
    };
  },
  methods: {
    onAddItem(e) {
      if (e.keyCode === 13) {
        this.onSave();
      }
    },
    onEdit(e, i) {
      this.isEditMode = true;
      this.currentTxt = e;
      this.activeIndex = i + 1;
      this.$refs.inputTxt.focus();
    },
    onBlur() {
      this.isFocus = false;
      if (!this.currentTxt || this.currentTxt.trim() === "") {
        return;
      }
      this.onSave();
    },
    onFocus() {
      this.isFocus = true;
    },
    onFocusText() {
      this.$refs.inputTxt.focus();
    },
    onSave() {
      const arr = this.items;
      if (this.isEditMode) {
        if (this.currentTxt === "") {
          arr.splice(this.activeIndex - 1, 1);
        } else {
          arr[this.activeIndex - 1] = this.currentTxt;
        }
      } else {
        arr.push(this.currentTxt);
      }
      this.$emit("change", arr);
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10);
      this.currentTxt = "";
      if (this.isFocus) {
        setTimeout(() => {
          this.$refs.inputTxt.focus();
        }, 100);
      }
      this.isEditMode = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-text-box-item {
  height: 100%;
  width: 100%;
  ol {
    padding-left: 15px;
    color: #111827;
    li {
      cursor: pointer;
      &.active {
        position: relative;
        * {
          position: relative;
          z-index: 1;
        }
        &::before {
          background: #5d9ded;
          opacity: 0.3;
          position: absolute;
          top: 0px;
          left: -24px;
          width: calc(100% + 34px);
          height: 100%;
          z-index: 0;
          content: "";
        }
      }
    }
  }
}
.number-placeholder {
  font-size: 14px;
  color: #111827;
  padding-top: 6px;
  padding-right: 8px;
  &.hold {
    color: #a1a5a6;
  }
}
::v-deep {
  .v-input textarea {
    font-size: 14px;
  }
  .v-input__slot {
    padding-top: 2px;
    &::before,
    &::after {
      display: none;
    }
  }
}
.opacity-0 {
  opacity: 0;
}
</style>
