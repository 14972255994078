<template>
  <div>
    <div class="box-container">
      <h2>Project Information</h2>
      <div class="flex gap-6">
        <div class="w-full">
          <div class="flex gap-3">
            <div class="w-full max-w-200 text-label">Name</div>
            <div>{{ project.name }}</div>
          </div>
          <div class="flex gap-3 mt-4">
            <div class="w-full max-w-200 text-label">Email</div>
            <div>{{ project.work_email }}</div>
          </div>
          <div class="flex gap-3 mt-4">
            <div class="w-full max-w-200 text-label">Hiring Company</div>
            <div>{{ project.hiring_company }}</div>
          </div>
          <div class="flex gap-3 mt-4">
            <div class="w-full max-w-200 text-label">Project name</div>
            <div>{{ project.project_name }}</div>
          </div>
          <div class="flex gap-3 mt-4">
            <div class="w-full max-w-200 text-label">Job description link</div>
            <div class="break-all">
              <a :href="project.jd_link" target="_blank">
                {{ project.jd_link }}
              </a>
            </div>
          </div>
        </div>
        <div class="w-full">
          <div class="flex gap-3">
            <div class="w-full max-w-200 text-label">Job title</div>
            <div>{{ project.job_title }}</div>
          </div>
          <div
            class="flex gap-3 mt-4"
            v-if="!project.locations_primary && !project.locations_secondary"
          >
            <div class="w-full max-w-200 text-label">Job locations</div>
            <div class="flex gap-3 text-label flex-wrap">
              <v-chip small v-for="(location, i) in project.locations" :key="i">
                {{ location }}
              </v-chip>
            </div>
          </div>
          <div v-else>
            <div class="flex gap-3 mt-4">
              <div class="w-full max-w-200 text-label">
                Primary job locations
              </div>
              <div class="flex gap-3 text-label flex-wrap">
                <v-chip
                  small
                  v-for="(location, i) in project.locations_primary"
                  :key="i"
                >
                  {{ location }}
                </v-chip>
              </div>
            </div>
            <div class="flex gap-3 mt-4">
              <div class="w-full max-w-200 text-label">
                Secondary job locations (Optional)
              </div>
              <div class="flex gap-3 text-label flex-wrap">
                <v-chip
                  small
                  v-for="(location, i) in project.locations_secondary"
                  :key="i"
                >
                  {{ location }}
                </v-chip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-container mt-4">
      <h2>Ideal candidates</h2>
      <div class="flex gap-6">
        <div class="w-full"></div>
        <div class="w-full">
          <div class="w-full text-label">About Candidates</div>
        </div>
      </div>
      <div
        class="flex gap-6 mt-2"
        v-for="(item, i) in project.ideal_candidates"
        :key="i"
      >
        <div class="w-full">
          <div class="flex gap-3">
            <div class="w-full text-label max-w-200">Candidate {{ i + 1 }}</div>
            <div class="break-all">
              <a :href="item" target="_blank">{{ item }}</a>
            </div>
          </div>
        </div>
        <div class="w-full">
          <v-textarea
            readonly
            auto-grow
            rows="1"
            hide-details
            class="textarea-box"
            :value="idealCandidateInfo[i]"
          ></v-textarea>
        </div>
      </div>
    </div>
    <div class="box-container mt-4">
      <h2>Interview Scheduling</h2>
      <div class="flex gap-6">
        <div class="w-full">
          <div class="">
            <div class="w-full text-label">First Interview</div>
            <div class="mt-3">{{ project.phone_screener_name }}</div>
            <div class="mt-3">{{ project.phone_screener_email }}</div>
          </div>
          <div class="mt-4">
            <div class="w-full text-label">
              CC when new candidates are found
            </div>
            <div class="mt-3">{{ hmEmails }}</div>
          </div>
          <div class="mt-4">
            <div class="w-full text-label">
              CC when scheduling interviews with interested candidates
            </div>
            <div class="mt-3">{{ scheduleEmails }}</div>
          </div>
        </div>
        <div class="w-full">
          <div class="">
            <div class="w-full text-label">
              Interview scheduling coordination method
            </div>
            <div class="mt-3">
              {{ options[Number(project.interview_scheduling) - 1] }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-container mt-4">
      <h2>Additional Information</h2>
      <div class="flex gap-6">
        <div class="w-full">
          <div class="flex gap-3">
            <div class="w-full max-w-200 text-label">Visa Sponsorship</div>
            <div>
              {{ project.visa_sponsorship ? "true" : "false" }}
            </div>
          </div>
          <div class="flex gap-3 mt-4">
            <div class="w-full max-w-200 text-label">Base Salary</div>
            <div>
              {{ project.base_compensation }}
            </div>
          </div>
          <div class="flex gap-3 mt-4">
            <div class="w-full max-w-200 text-label">Equity</div>
            <div>
              {{ project.equity_compensation }}
            </div>
          </div>
          <div class="flex gap-3 mt-4">
            <div class="w-full max-w-200 text-label">Bonus</div>
            <div>
              {{ project.bonus_compensation }}
            </div>
          </div>
        </div>
        <div class="w-full">
          <div class="">
            <div class="w-full text-label">Remote work policy</div>
            <div class="mt-3">
              {{ project.remote_work_policy }}
            </div>
          </div>
          <div class="mt-4">
            <div class="w-full text-label">
              Additional Info about job and candidate
            </div>
            <div class="mt-3">{{ project.anything_else }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-container mt-4">
      <h2>ATS Sync</h2>
      <div class="flex gap-6">
        <div class="w-full">
          <div class="">
            <div class="w-full text-label">Sync ATS</div>
            <div class="mt-3"></div>
          </div>
          <div class="mt-4">
            <div class="w-full text-label">Which ATS to sync to</div>
            <div class="mt-3"></div>
          </div>
          <div class="mt-4">
            <div class="w-full text-label">
              Which project you want to sync to
            </div>
            <div class="mt-3"></div>
          </div>
        </div>
        <div class="w-full"></div>
      </div>
    </div>
    <div class="box-container mt-4">
      <h2>Target Companies</h2>
      <div class="flex gap-x-6 gap-y-3 flex-wrap">
        <v-chip
          v-for="(item, i) in targetCompanies"
          :key="i"
          class="box-container-chip"
        >
          {{ item }}
        </v-chip>
      </div>
      <div class="mt-10 text-label">
        Reasons for choosing the Target Companies
      </div>
      <div class="mt-4">
        {{ project.why_target_companies }}
      </div>
    </div>
    <div class="box-container mt-4">
      <h2>Keywords Required</h2>
      <div class="flex gap-x-6 gap-y-3 flex-wrap">
        <v-chip
          v-for="(item, i) in keywordsRequired"
          :key="i"
          class="box-container-chip"
        >
          {{ item }}
        </v-chip>
      </div>
      <div class="mt-10 text-label">Description</div>
      <div class="mt-4">
        {{ project.ideal_candidate_must_haves }}
      </div>
    </div>
    <div class="box-container mt-4">
      <h2>Keywords optional</h2>
      <div class="flex gap-x-6 gap-y-3 flex-wrap">
        <v-chip
          v-for="(item, i) in keywordsOptional"
          :key="i"
          class="box-container-chip"
        >
          {{ item }}
        </v-chip>
      </div>
      <div class="mt-10 text-label">Description</div>
      <div class="mt-4">
        {{ project.ideal_candidate_niceto_haves }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "JobSubmission",
  props: {
    date: String,
  },
  computed: {
    ...mapGetters("operations", [
      "projectLoading",
      "projectSuccessData",
      "projectFailureData",
    ]),
    project() {
      return this.projectSuccessData?.data[this.date]?.job_submission || {};
    },
    hmEmails() {
      return this.project.hm_cc_emails?.join(", ");
    },
    scheduleEmails() {
      return this.project.scheduling_cc_emails?.join(", ");
    },
    targetCompanies() {
      return this.project.target_companies || [];
    },
    keywordsRequired() {
      return this.project.keywords_required || [];
    },
    keywordsOptional() {
      return this.project.keywords_optional || [];
    },
    idealCandidateInfo() {
      if (this.project.ideal_candidate_info) {
        if (this.project.ideal_candidate_info.includes("*")) {
          return this.project.ideal_candidate_info.split("*");
        }
        const count = this.project.ideal_candidates
          ? this.project.ideal_candidates.length
          : 0;
        const arr = this.project.ideal_candidate_info.split(", ") || [];
        if (arr.length <= count) {
          return arr;
        }
        const result = [];
        let ind = -1;
        arr.forEach((item) => {
          if (item[0] === item[0].toUpperCase()) {
            ind += 1;
            result[ind] = item;
          } else {
            if (ind === -1) {
              ind = 0;
              result[ind] = item;
            } else {
              result[ind] += `, ${item}`;
            }
          }
        });
        if (result.length <= count) return result;
        return [this.project.ideal_candidate_info];
      }
      if (this.project.ideal_candidate_infos) {
        return this.project.ideal_candidate_infos;
      }
      return [];
    },
  },
  data() {
    return {
      options: [
        "Public Scheduling Page",
        "Use Hirefly scheduling",
        "Coordinate myself",
        "My assistant will coordinate",
        "Other",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";
.flex {
  display: flex;
}
.box-container {
  padding: 16px 20px;
  border: 1px solid $border-color;
  border-radius: 4px;
  font-size: 12px;
  color: #384955;
  min-height: 200px;
  word-break: break-word;
  .break-all {
    word-break: break-all;
  }
  h2 {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: $black;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  .text-label {
    font-weight: 600;
    color: #111827;
  }
}
.gap-6 {
  gap: 24px;
}
.gap-x-6 {
  column-gap: 24px;
}
.gap-y-3 {
  row-gap: 12px;
}
.gap-3 {
  gap: 12px;
}
.flex {
  display: flex;
}
.w-full {
  width: 100%;
}
.max-w-200 {
  max-width: 135px;
}
.mt-4 {
  margin-top: 24px;
}
.mt-3 {
  margin-top: 12px;
}
a {
  color: #1da1f2 !important;
}
.box-container-chip {
  padding: 0 12px !important;
}
.flex-wrap {
  flex-wrap: wrap;
}
.textarea-box {
  font-size: 12px;
  padding-top: 0px !important;
  margin-top: 0px !important;
  &::v-deep {
    .v-input__slot::before {
      display: none;
    }
    textarea {
      line-height: 18px;
      color: #384955;
    }
  }
}
</style>
