<template>
  <div class="login__container">
    <div class="login__box">
      <img src="@/assets/images/hirefly-logo-2.svg" alt="logo" />
      <div class="login__box--title">Sign in to your account</div>
      <div class="login__box--form">
        <div class="login__box--form__title">Email address</div>
        <v-text-field
          v-model="email"
          label="Email"
          :rules="emailRules"
          outlined
          full-width
          class="login__box--form__custom-input"
        ></v-text-field>
        <div class="login__box--form__title">Password</div>
        <v-text-field
          type="password"
          v-model="password"
          label="Password"
          outlined
          full-width
          class="login__box--form__custom-input"
        ></v-text-field>
        <v-btn
          class="login__box--form__custom-btn mt-4"
          :loading="loading"
          elevation="0"
          color="#4F46E5"
          width="100%"
          height="36px"
          @click="onLogin"
          >Sign in</v-btn
        >
      </div>
    </div>
    <v-snackbar color="#CA3D3D" v-model="errorSnackbar" top>
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OperatorAuth",
  data() {
    return {
      emailRules: [
        (value) => !!value || "Required.",
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      ],
      email: "",
      password: "",
      message: "Please log in to hirefly.ai first and try again",
      errorSnackbar: false,
      loading: false,
    };
  },
  methods: {
    async onLogin() {
      const paramsQuery = {
        user_email: this.email,
        admin_password: this.password,
      };
      this.loading = true;
      try {
        const res = await axios.get("/operations_dash/login", {
          params: paramsQuery,
        });
        if (res?.status === 200) {
          localStorage.setItem("Operator-X-Parse-Session-Token", res.data);
          this.$store.dispatch("auth/setLogin", true);
          this.$router.push("/");
        } else {
          if (res?.status === 407) {
            this.message = "Please log in to hirefly.ai first and try again";
          } else if (res?.status === 401) {
            this.message = "Credentials failed, please try again";
          } else {
            this.message = "Login failed, please try again later";
          }
          this.errorSnackbar = true;
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        if (e?.response?.status === 407) {
          this.message = "Please log in to hirefly.ai first and try again";
        } else if (e?.response?.status === 401) {
          this.message = "Credentials failed, please try again";
        } else {
          this.message = "Login failed, please try again later";
        }
        this.errorSnackbar = true;
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  font-family: "Inter", sans-serif;
}
.login {
  &__container {
    margin-top: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  &__box {
    width: 368px;
    img {
      margin-left: calc(50% - 24px);
    }
    &--title {
      margin-top: 29px;
      text-align: center;
      color: $black;
      font-size: 30px;
      line-height: 36px;
      font-weight: 600;
    }
    &--form {
      margin-top: 41px;
      &__title {
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 4px;
        font-weight: 500;
        color: #374151;
      }
      &__custom-input {
        &::v-deep .v-input__slot {
          min-height: 42px !important;
          border-radius: 6px !important;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        }
        &::v-deep .v-label {
          top: 12px;
        }
      }
      &__custom-btn {
        border-radius: 6px;
        &::v-deep .v-btn__content {
          text-transform: none;
          color: #fff;
          font-size: 14px;
          letter-spacing: 0;
          font-weight: 500;
          font-family: "Inter", sans-serif;
        }
        &::v-deep .v-btn__loader {
          color: #fff;
        }
      }
    }
  }
}
</style>
