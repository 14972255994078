<template>
  <div ref="nameContent">
    <v-tooltip
      color="#F3F4F6"
      top
      :disabled="!isOver"
      :max-width="500"
      content-class="name-section"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="name-content"
          v-bind="attrs"
          v-on="on"
          :class="{ active: !loading }"
        >
          {{ name }}
        </div>
      </template>
      <span>{{ name }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "ChangeLogItemName",
  props: {
    name: String,
  },
  data() {
    return {
      loading: true,
      isOver: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.checkTextOver();
    }, 10);
  },
  methods: {
    checkTextOver() {
      this.loading = true;
      this.isOver = false;
      if (
        this.$refs.nameContent.scrollWidth > this.$refs.nameContent.clientWidth
      ) {
        this.isOver = true;
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scope>
.name-content {
  font-size: 12px;
  font-weight: 600;
  color: #384955;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  white-space: nowrap;
  &.active {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
