var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"subscription-container"},[_c('div',{staticClass:"content"},[_c('h1',[_vm._v("Job Slots Subscription Purchased by Companies")]),_c('p',[_vm._v("Sort by Companies, purchased and unused slots")]),_c('div',{staticClass:"d-flex gap-3 align-center search-box"},[_c('div',{staticClass:"search-label"},[_vm._v("Companies:")]),_c('v-text-field',{staticClass:"search-text",attrs:{"hide-details":"","placeholder":"All (default)"},on:{"input":_vm.onSearch},model:{value:(_vm.searchTxt),callback:function ($$v) {_vm.searchTxt=$$v},expression:"searchTxt"}}),_c('div',[_c('v-btn',{staticClass:"search-icon",attrs:{"icon":""},on:{"click":_vm.onSearch}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)],1),_c('div',{staticClass:"table-container"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataToShow,"item-key":"name","show-select":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('div',[_vm._v(_vm._s(header.text))]),_c('div',{staticClass:"button-header"},[_c('v-btn',{attrs:{"text":"","height":"24"},on:{"click":_vm.selectAll}},[_vm._v("Select All/Clear")]),_c('v-btn',{staticClass:"pa-2",class:{ active: _vm.isShowSelected },attrs:{"elevation":"0","height":"24"},on:{"click":_vm.showSelected}},[_vm._v("Show Selected")])],1)]}},{key:"header.job_notes",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.autopilot_slots",fn:function(ref){
var header = ref.header;
return [_c('div',[_vm._v(_vm._s(header.text))]),_c('div',{staticClass:"pb-2"},[_c('v-btn',{staticClass:"sort-btn",attrs:{"height":"24","elevation":"0"}},[_vm._v(" Order "),_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-arrow-up")])],1)],1)]}},{key:"header.unused_jobs",fn:function(ref){
var header = ref.header;
return [_c('div',[_vm._v(_vm._s(header.text))]),_c('div',{staticClass:"pb-2"},[_c('v-btn',{staticClass:"sort-btn",attrs:{"elevation":"0","height":"24"}},[_vm._v(" Order "),_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-arrow-up")])],1)],1)]}},{key:"item.job_notes",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"relative text-content notes"},[(!item.isEditNote)?_c('div',[_vm._v(_vm._s(item.job_notes))]):_c('v-text-field',{attrs:{"hide-details":""},model:{value:(_vm.notesTxt),callback:function ($$v) {_vm.notesTxt=$$v},expression:"notesTxt"}}),(!item.isEditNote)?_c('v-icon',{staticClass:"edit-icon",attrs:{"small":""},on:{"click":function($event){return _vm.editNote(item.name)}}},[_vm._v(" mdi-pencil ")]):_c('v-icon',{staticClass:"edit-icon",attrs:{"small":""},on:{"click":function($event){return _vm.updateNote(item.name)}}},[_vm._v(" mdi-check-bold ")])],1)]}},{key:"item.autopilot_slots",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"relative"},[_c('div',{staticClass:"text-center text-content"},[(!item.isEditPurchased)?_c('span',[_vm._v(" "+_vm._s(item.autopilot_slots)+" ")]):_c('v-text-field',{attrs:{"hide-details":""},model:{value:(_vm.purchasedTxt),callback:function ($$v) {_vm.purchasedTxt=$$v},expression:"purchasedTxt"}})],1),(!item.isEditPurchased)?_c('v-icon',{staticClass:"edit-icon",attrs:{"small":"","loading":_vm.loading},on:{"click":function($event){return _vm.editPurchasedJobs(item.name)}}},[_vm._v(" mdi-pencil ")]):_c('v-icon',{staticClass:"edit-icon",attrs:{"small":"","loading":_vm.loading},on:{"click":function($event){return _vm.updatePurchasedJobs(item.name)}}},[_vm._v(" mdi-check-bold ")])],1)]}},{key:"item.unused_jobs",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center unused-jobs",class:{ 'unused-jobs-active': Number(item.unused_jobs) }},[_vm._v(" "+_vm._s(item.unused_jobs)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"red-jobs",class:{ 'red-jobs-active': Number(item.unused_jobs) }},[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }