<template>
  <div class="slider-box__item--info">
    <v-tooltip bottom nudge-bottom="10px" content-class="top-fixed">
      <template v-slot:activator="{ on, attrs }">
        <div dark v-bind="attrs" v-on="on">
          <img :src="InfoIcon" alt="" />
        </div>
      </template>
      <div class="slider-box__item--tooltip">
        <div class="arrow-up"></div>
        <div class="arrow-up2"></div>
        <h3>Suggestions</h3>
        <ol>
          <li v-for="(item, i) in content" :key="i">
            {{ item.text }}
            <ol type="a" v-if="item.subs">
              <li v-for="(sub, j) in item.subs" :key="j">{{ sub }}</li>
            </ol>
          </li>
        </ol>
      </div>
    </v-tooltip>
  </div>
</template>

<script>
import InfoIcon from "@/assets/images/icons/info-svg.svg";

export default {
  name: "InfoTooltip",
  props: {
    content: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      InfoIcon,
    };
  },
};
</script>
<style lang="scss" scoped>
.slider-box__item {
  &--info {
    width: 18px;
    position: absolute;
    right: 0;
    bottom: -2px;
    &::v-deep {
      .v-btn {
        max-width: 30px;
        min-width: 0;
      }
      img {
        max-width: 100%;
      }
    }
  }
  &--tooltip {
    background: #fff;
    box-shadow: -1px 1px 9px 0 rgba(0, 0, 0, 0.5);
    padding: 24px 32px 24px;
    width: 337px;
    font-size: 12px;
    color: #111827;
    font-weight: 500;
    border-radius: 6px;
    h3 {
      margin: 0px;
      text-align: center;
      color: #384955;
      font-weight: 700;
      font-size: 16px;
    }
    ol {
      margin-top: 18px;
      padding: 0 0 0 15px;
      li {
        margin-bottom: 12px;
      }
      ol {
        margin-top: 0px;
        li {
          margin-bottom: 0px;
        }
      }
    }
  }
}
.v-tooltip__content {
  background: none;
  box-shadow: none;
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #fff;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 10px);
  z-index: 2;
}
.arrow-up2 {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 4px);
  z-index: 1;
  filter: blur(3px);
}
.top-fixed {
  top: 340px !important;
}
</style>
