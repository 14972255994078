const state = () => ({
  isLogined: !!localStorage.getItem("Operator-X-Parse-Session-Token"),
});

const getters = {
  isLogined: (state) => state.isLogined,
};

const mutations = {
  SET_ISLOGINED: (state, data) => {
    state.isLogined = data;
  },
};
const actions = {
  setLogin({ commit }, value) {
    commit("SET_ISLOGINED", value);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
