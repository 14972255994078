<template>
  <div>
    <div :class="{ sticky: isSticky }" class="header mt-2">
      <div class="header-bottom">
        <div>
          <div class="header__left-col">
            <div
              class="header__left-col--item"
              :class="{
                'header__left-col--is-active': activeTabName === 'ProjectData',
              }"
              @click="changeTab('ProjectData')"
            >
              <div class="py-1 header__left-col--item__title">Project data</div>
            </div>
            <div
              class="header__left-col--item"
              :class="{
                'header__left-col--is-active': activeTabName === 'ChangeLog',
              }"
              @click="changeTab('ChangeLog')"
            >
              <div class="py-1 header__left-col--item__title">Change log</div>
            </div>
            <div
              class="header__left-col--item"
              :class="{
                'header__left-col--is-active':
                  activeTabName === 'jobSubmission',
              }"
              @click="changeTab('jobSubmission')"
            >
              <div class="py-1 header__left-col--item__title">
                Job Submission
              </div>
            </div>
          </div>
        </div>
        <div class="header__active-project-name">
          {{ projectName }}
        </div>
        <div class="header__right-col" v-if="activeProject.project_id">
          <v-menu
            content-class="custom-menu"
            offset-y
            bottom
            :close-on-content-click="false"
            v-model="editStaffMenu"
            v-if="!projectLoading"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="header__right-col--item" v-bind="attrs" v-on="on">
                <div class="header__right-col--item__header">
                  <div>
                    <div class="header__right-col--item__avatar">
                      <v-img :src="csmImage" v-if="csmImage" />
                      <div
                        v-else
                        class="header__right-col--item__avatar--no-image"
                      >
                        {{ csmAvatarLetters }}
                      </div>
                    </div>
                    <div class="header__right-col--item__title pt-1">CSM</div>
                  </div>
                  <div>
                    <div class="header__right-col--item__avatar">
                      <v-img :src="ownerImage" v-if="ownerImage" />
                      <div
                        v-else
                        class="header__right-col--item__avatar--no-image"
                      >
                        {{ ownerAvatarLetters }}
                      </div>
                    </div>
                    <div class="header__right-col--item__title pt-1">OWNER</div>
                  </div>
                </div>
              </div>
            </template>

            <v-list>
              <div class="menu__body--title">Edit Staff Assignment</div>
              <div class="menu__body--item" v-if="selectedCsm">
                <div class="menu__body--item__title">
                  Customer Success Manager
                </div>
                <CustomDropdown
                  :value="editCsmMenu"
                  @update="editCsmMenu = $event"
                >
                  <template v-slot:button="{ on, attrs }">
                    <div class="menu__body--item__box" v-bind="attrs" v-on="on">
                      <div class="menu__body--item__box--avatar">
                        <v-img
                          :src="selectedCsm.csm_picture"
                          v-if="selectedCsm.csm_picture"
                        />
                        <div
                          class="menu__body--item__box--avatar__no-image"
                          v-else
                        >
                          {{ getAvatarLetters(selectedCsm.csm_name) }}
                        </div>
                      </div>
                      <div class="menu__body--item__box--title">
                        {{ selectedCsm.csm_name }}
                      </div>
                      <div>
                        <v-icon color="#384955" v-if="!editCsmMenu"
                          >mdi-chevron-down</v-icon
                        >
                        <v-icon color="#384955" v-if="editCsmMenu"
                          >mdi-chevron-up</v-icon
                        >
                      </div>
                    </div>
                  </template>

                  <v-list>
                    <div>
                      <v-radio-group
                        v-model="selectedCsm"
                        @change="editCsmMenu = false"
                      >
                        <v-radio
                          v-for="(item, i) in getCsmsList"
                          :key="`radio_${i}`"
                          :off-icon="'mdi-checkbox-blank-circle'"
                          :on-icon="'mdi-checkbox-blank-circle'"
                          :ripple="false"
                          :label="item.csm_name"
                          color="#4646AB"
                          :value="item"
                          hide-details
                          class="date-menu__body--radio-box__radio border pt-2 pb-4 ml-4 mr-2"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                  </v-list>
                </CustomDropdown>
              </div>
              <div class="menu__body--item" v-if="selectedOwner">
                <div class="menu__body--item__title">Project Owner</div>
                <CustomDropdown
                  :value="editOwnerMenu"
                  @update="editOwnerMenu = $event"
                >
                  <template v-slot:button="{ on, attrs }">
                    <div class="menu__body--item__box" v-bind="attrs" v-on="on">
                      <div class="menu__body--item__box--avatar">
                        <v-img
                          :src="selectedOwner.owner_picture"
                          v-if="selectedOwner.owner_picture"
                        />
                        <div
                          v-else
                          class="menu__body--item__box--avatar__no-image"
                        >
                          {{ getAvatarLetters(selectedOwner.owner_name) }}
                        </div>
                      </div>
                      <div class="menu__body--item__box--title">
                        {{ selectedOwner.owner_name }}
                      </div>
                      <div>
                        <v-icon color="#384955" v-if="!editOwnerMenu"
                          >mdi-chevron-down</v-icon
                        >
                        <v-icon color="#384955" v-if="editOwnerMenu"
                          >mdi-chevron-up</v-icon
                        >
                      </div>
                    </div>
                  </template>

                  <v-list>
                    <div>
                      <v-radio-group
                        v-model="selectedOwner"
                        @change="editOwnerMenu = false"
                      >
                        <v-radio
                          v-for="(item, i) in getOwnersList"
                          :key="`radio_${i}`"
                          :off-icon="'mdi-checkbox-blank-circle'"
                          :on-icon="'mdi-checkbox-blank-circle'"
                          :ripple="false"
                          :label="item.owner_name"
                          color="#4646AB"
                          :value="item"
                          hide-details
                          class="date-menu__body--radio-box__radio border pt-2 pb-4 ml-4 mr-2"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                  </v-list>
                </CustomDropdown>
              </div>
              <div class="menu__body--btn-box">
                <v-btn
                  class="menu__body--btn"
                  color="#E6E7EB"
                  width="230px"
                  height="45px"
                  @click="submitChanges"
                  :loading="changeProjectInfoLoading"
                >
                  Save
                </v-btn>
              </div>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div v-if="activeProject.project_id">
        <div class="d-flex header-filter">
          <div class="header__select-box">
            <span>Last</span>
            <v-menu
              content-class="custom-menu"
              :close-on-content-click="false"
              v-model="openDateMenu"
              offset-y
              bottom
              nudge-left="70"
              nudge-top="-10"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="date-menu__input ml-3" v-bind="attrs" v-on="on">
                  <div class="date-menu__model">
                    <span>{{ date.label }}</span>
                    <i v-if="date.id === 'last_7'">(default)</i>
                  </div>
                  <div>
                    <v-icon
                      :class="{ selected: openDateMenu }"
                      class="select-icon"
                      color="#384955"
                      >mdi-chevron-down</v-icon
                    >
                  </div>
                </div>
              </template>

              <v-list>
                <div class="date-menu__body--title">Select Days to-date</div>
                <div class="date-menu__body--radio-box mb-4 mr-1">
                  <v-radio-group v-model="date" @change="openDateMenu = false">
                    <v-radio
                      v-for="(item, i) in dateMenuItems"
                      :key="`radio_${i}`"
                      :off-icon="'mdi-checkbox-blank-circle'"
                      :on-icon="'mdi-checkbox-blank-circle'"
                      :ripple="false"
                      :label="item.label"
                      color="#4646AB"
                      :value="item"
                      hide-details
                      class="date-menu__body--radio-box__radio border pt-2 pb-4 ml-4 mr-2"
                    ></v-radio>
                    <i>(default)</i>
                  </v-radio-group>
                </div>
              </v-list>
            </v-menu>
          </div>
          <v-tooltip color="#F3F4F6" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                height="25px"
                @click="refreshData"
                class="header__refresh-btn"
                v-bind="attrs"
                rounded
                v-on="on"
              >
                <v-icon color="#384955">mdi-refresh</v-icon>
                <span class="ml-1">Refresh</span>
              </v-btn>
            </template>
            <span>This will refesh data in all accordion tabs</span>
          </v-tooltip>
          <div class="last-update-format">
            Last Calculated: {{ lastUpdated }}
          </div>
          <div class="line-block">
            <v-tooltip color="#F3F4F6" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :href="`https://www.hirefly.ai/projects/edit/${activeProject.project_id}`"
                  target="_blank"
                  class="header__right-col--item__icon"
                  v-bind="attrs"
                  v-on="on"
                  rounded
                >
                  <img
                    src="@/assets/images/icons/settings.svg"
                    width="18px"
                    height="18px"
                    alt=""
                  />
                  <span class="header__right-col--item__text">Settings</span>
                </v-btn>
              </template>
              <span>Open project settings in a new tab</span>
            </v-tooltip>
          </div>
          <div class="line-block">
            <v-tooltip color="#F3F4F6" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="showResumeModal = true"
                  target="_blank"
                  class="header__right-col--item__icon"
                  v-bind="attrs"
                  v-on="on"
                  rounded
                >
                  <span class="header__right-col--item__text"
                    >Upload Resume</span
                  >
                </v-btn>
              </template>
              <span>Attach a resume to a candidate</span>
            </v-tooltip>
          </div>
          <div class="line-block">
            <v-menu
              nudge-bottom="10px"
              nudge-right="10px"
              :close-on-content-click="false"
              v-model="openSendMail"
              offset-y
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  class="header__right-col--item__icon"
                  :class="{ active: openSendMail }"
                  rounded
                  v-bind="attrs"
                  v-on="on"
                >
                  <span class="header__right-col--item__text">Send Mail</span>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <div class="confirm-popup">
                  <div class="confirm-title">Send Email to Hiring Manager</div>
                  <div class="mt-3 confirm-text">
                    To: {{ hiringTeam.hiring_manager_name }} &lt;{{
                      hiringTeam.hiring_manager_email
                    }}&gt;
                  </div>
                  <div class="mt-2 text-center">
                    <v-radio-group
                      v-model="selectedOption"
                      class="d-inline-flex"
                      :hide-details="true"
                    >
                      <v-radio
                        v-for="n in sendMailOptions"
                        :key="n.value"
                        :label="n.title"
                        :value="n.value"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="mt-4">
                    <v-menu
                      :close-on-content-click="false"
                      v-model="openEmailConfirm"
                      nudge-left="25px"
                      nudge-top="10px"
                      offset-x
                      bottom
                      left
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="confirm-btn2"
                          elevation="0"
                          :disabled="!selectedOption"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Send
                        </v-btn>
                      </template>
                      <v-list>
                        <div class="flush-popup">
                          <div class="flush-popup-title">Confirm</div>
                          <div>Do you want to continue?</div>
                          <div class="mt-2 d-flex justify-space-between">
                            <v-btn
                              class="confirm-btn"
                              elevation="0"
                              @click="openEmailConfirm = false"
                              text
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              class="confirm-btn"
                              elevation="0"
                              @click="sendMailHandle"
                              :loading="loading"
                            >
                              Yes
                            </v-btn>
                          </div>
                        </div>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
              </v-list>
            </v-menu>
          </div>
          <div class="line-block">
            <v-menu
              nudge-bottom="10px"
              nudge-right="10px"
              :close-on-content-click="false"
              v-model="openManageProject"
              offset-y
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  class="header__right-col--item__icon"
                  rounded
                  v-bind="attrs"
                  v-on="on"
                  :class="{ active: openManageProject }"
                >
                  <span class="header__right-col--item__text">
                    Manage Project
                  </span>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <div class="project-manager-popup">
                  <div class="project-manager-popup-title">Manage Project</div>
                  <v-list dense>
                    <v-list-item-group v-model="selectedItem" color="primary">
                      <v-list-item>
                        <v-list-item-content>
                          <v-menu
                            nudge-right="-25px"
                            nudge-top="10px"
                            :close-on-content-click="false"
                            v-model="openFlush"
                            offset-x
                            bottom
                            left
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-title v-bind="attrs" v-on="on">
                                Flush Queue
                              </v-list-item-title>
                            </template>
                            <v-list>
                              <div class="flush-popup">
                                <div class="flush-popup-title">Confirm</div>
                                <div>Do you want to continue?</div>
                                <div class="mt-2 d-flex justify-space-between">
                                  <v-btn
                                    class="confirm-btn"
                                    elevation="0"
                                    @click="openFlush = false"
                                    text
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    class="confirm-btn"
                                    elevation="0"
                                    @click="flushHandle"
                                    :loading="loading"
                                  >
                                    Yes
                                  </v-btn>
                                </div>
                              </div>
                            </v-list>
                          </v-menu>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-menu
                            :close-on-content-click="false"
                            v-model="openStack"
                            nudge-right="-25px"
                            nudge-top="10px"
                            offset-x
                            bottom
                            left
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-title v-bind="attrs" v-on="on">
                                Add to Stack
                              </v-list-item-title>
                            </template>
                            <v-list>
                              <div class="flush-popup">
                                <div class="flush-popup-title">Confirm</div>
                                <div>Do you want to continue?</div>
                                <div class="mt-2 d-flex justify-space-between">
                                  <v-btn
                                    class="confirm-btn"
                                    elevation="0"
                                    @click="openStack = false"
                                    text
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    class="confirm-btn"
                                    elevation="0"
                                    @click="stackHandle"
                                    :loading="loading"
                                  >
                                    Yes
                                  </v-btn>
                                </div>
                              </div>
                            </v-list>
                          </v-menu>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-list-item-content>
                          <v-menu
                            :close-on-content-click="false"
                            v-model="openPause"
                            nudge-right="-25px"
                            nudge-top="10px"
                            offset-x
                            bottom
                            left
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-title v-bind="attrs" v-on="on">
                                Pause Project
                              </v-list-item-title>
                            </template>
                            <v-list>
                              <div class="flush-popup">
                                <div class="flush-popup-title">Confirm</div>
                                <div>Do you want to continue?</div>
                                <div class="mt-2 d-flex justify-space-between">
                                  <v-btn
                                    class="confirm-btn"
                                    elevation="0"
                                    @click="openPause = false"
                                    text
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    class="confirm-btn"
                                    elevation="0"
                                    @click="pauseProject(true)"
                                    :loading="loading"
                                  >
                                    Yes
                                  </v-btn>
                                </div>
                              </div>
                            </v-list>
                          </v-menu>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-menu
                            :close-on-content-click="false"
                            v-model="openArchive"
                            nudge-right="-25px"
                            nudge-top="10px"
                            offset-x
                            bottom
                            left
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-title v-bind="attrs" v-on="on">
                                Archive Project
                              </v-list-item-title>
                            </template>
                            <v-list>
                              <div class="flush-popup">
                                <div class="flush-popup-title">Confirm</div>
                                <div>Do you want to continue?</div>
                                <div class="mt-2 d-flex justify-space-between">
                                  <v-btn
                                    class="confirm-btn"
                                    elevation="0"
                                    @click="openArchive = false"
                                    text
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    class="confirm-btn"
                                    elevation="0"
                                    @click="archiveProject(true)"
                                    :loading="loading"
                                  >
                                    Yes
                                  </v-btn>
                                </div>
                              </div>
                            </v-list>
                          </v-menu>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
    <v-snackbar
      centered
      color="#384955"
      v-model="successSnackbar"
      :timeout="timeout"
    >
      Calculating dashboard, please refresh the page in 1 minute
    </v-snackbar>
    <v-snackbar color="#CA3D3D" top v-model="errorSnackbar" :timeout="timeout">
      Error: please try again later.
    </v-snackbar>
    <v-snackbar v-model="snackbar" top :timeout="timeout" :color="color">
      {{ message }}
    </v-snackbar>
    <v-dialog max-width="500px" class="resume-modal" v-model="showResumeModal">
      <v-form v-model="resumeModalValid">
        <div class="resume-modal">
          Upload a candidate's resume
          <v-text-field
            rows="1"
            color="#A1A5A6"
            placeholder="Please provide the candidate’s LinkedIn URL"
            v-model="resumeLinkedinUrl"
            :rules="[rules.required, rules.linkedinUrl]"
          />
          <v-file-input
            v-model="resumeFile"
            :rules="[rules.required]"
            label="upload a file"
          />
          <v-btn
            :disabled="!resumeModalValid"
            :loading="resumeLoading"
            @click="onSubmitResume"
            >Submit</v-btn
          >
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { put, post, postFile } from "../utils";
import CustomDropdown from "../components/customs/Dropdown";

export default {
  name: "tabs-header",
  props: {
    activeProject: Object,
  },
  components: {
    CustomDropdown,
  },
  computed: {
    ...mapGetters("operations", [
      "projectLoading",
      "projectSuccessData",
      "projectFailureData",
      "changeProjectInfoLoading",
      "dashboardSuccessData",
      "refreshLoading",
    ]),
    csmImage() {
      return this.projectSuccessData &&
        this.projectSuccessData.data &&
        this.projectSuccessData.data.csm_picture
        ? this.projectSuccessData.data.csm_picture
        : null;
    },
    ownerImage() {
      return this.projectSuccessData &&
        this.projectSuccessData.data &&
        this.projectSuccessData.data.owner_picture
        ? this.projectSuccessData.data.owner_picture
        : null;
    },
    projectName() {
      return this.activeProject.name || "<Unknown name>";
    },
    ownerAvatarLetters() {
      if (
        this.activeProject.owner !== "Not Assigned" &&
        this.projectSuccessData?.data.owner_name
      ) {
        return this.projectSuccessData.data.owner_name
          .match(/\b(\w)/g)
          .join("");
      } else {
        return "NA";
      }
    },
    csmAvatarLetters() {
      if (
        this.activeProject.csm !== "Not Assigned" &&
        this.projectSuccessData?.data.csm_name
      ) {
        return this.projectSuccessData.data.csm_name.match(/\b(\w)/g).join("");
      } else {
        return "NA";
      }
    },
    dateId() {
      return this.date.id;
    },
    getCsmsList() {
      let arr = [];
      if (this.dashboardSuccessData) {
        arr = this.dashboardSuccessData.csms_list;
      }
      return arr;
    },
    getOwnersList() {
      let arr = [];
      if (this.dashboardSuccessData) {
        arr = this.dashboardSuccessData.owners_list;
      }
      return arr;
    },
    lastUpdated() {
      const date = new Date(
        `${this.projectSuccessData?.data?.last_updated} UTC`
      );
      return moment(date).format("lll");
    },
    hiringTeam() {
      if (
        this.projectSuccessData &&
        this.projectSuccessData.data[this.date.id] &&
        this.projectSuccessData.data[this.date.id].general_information &&
        this.projectSuccessData.data[this.date.id].general_information
          .hiring_team_information
      ) {
        return this.projectSuccessData.data[this.date.id].general_information
          .hiring_team_information;
      }
      return {};
    },
  },
  data() {
    const isLinkedInURL = (value) => {
      if (!value) return false;
      let str = value;
      if (!str.startsWith("https://") && !str.startsWith("http://")) {
        str = `https://${str}`;
      }

      try {
        const url = new URL(str);
        const profileRegex = /^\/in\/[a-zA-Z0-9]/;
        return (
          (url.protocol === "http:" || url.protocol === "https:") &&
          url.hostname === "www.linkedin.com" &&
          profileRegex.test(url.pathname)
        );
      } catch (error) {
        this.errorToast(error);
        return false;
      }
    };

    return {
      isSticky: false,
      editStaffMenu: false,
      openArchive: false,
      openPause: false,
      editOwnerMenu: false,
      openEmailConfirm: false,
      editCsmMenu: false,
      activeTabName: "ProjectData",
      timeout: 4000,
      successSnackbar: false,
      errorSnackbar: false,
      showLoading: false,
      openDateMenu: false,
      openSendMail: false,
      selectedOption: "",
      openStack: false,
      openFlush: false,
      snackbar: false,
      openManageProject: false,
      message: "Error: please try again later",
      color: "green",
      selectedItem: "",
      sendMailOptions: [
        {
          title: "Fix Job description Link",
          value: "hm_broken_jd",
        },
        {
          title: "Review Pending candidates",
          value: "hm_review_pending",
        },
        {
          title: "Calibrate Search",
          value: "hm_calibrate",
        },
      ],
      dateMenuItems: [
        {
          label: "7 days",
          id: "last_7",
        },
        {
          label: "14 days",
          id: "last_14",
        },
        {
          label: "30 days",
          id: "last_30",
        },
        {
          label: "60 days",
          id: "last_60",
        },
        {
          label: "90 days",
          id: "last_90",
        },
        {
          label: "All time",
          id: "all_time",
        },
      ],
      selectedOwner: {},
      selectedCsm: {},
      date: {
        label: "7 days",
        id: "last_7",
      },
      showArchivedPopup: false,
      isArchived: false,
      isLoading: false,
      isShowActions: false,
      loading: false,
      showResumeModal: false,
      resumeModalValid: false,
      resumeLoading: false,
      resumeLinkedinUrl: "",
      resumeFile: null,
      rules: {
        required: (value) => !!value || "Required.",
        linkedinUrl: (value) =>
          isLinkedInURL(value) || "Not a valid Linkedin URL",
      },
    };
  },
  methods: {
    getAvatarLetters(value) {
      if (value && value !== "Not Assigned") {
        return value
          .match(/\b(\w)/g)
          .join("")[0]
          .toUpperCase();
      } else {
        return "NA";
      }
    },
    refreshData() {
      this.showLoading = true;
      this.$emit("refresh");
    },
    changeTab(v) {
      this.activeTabName = v;
      this.$emit("change-tab", v);
      let box = document.getElementById("home");
      let sidebarHeader = document.getElementById("sidebar");
      box.scrollTop = 0;
      this.isSticky = false;
      if (sidebarHeader) sidebarHeader.classList.remove("sticky-header");
    },
    handleScroll() {
      let box = document.getElementById("home");
      let sidebarHeader = document.getElementById("sidebar");
      if (box.scrollTop > 0 && box.scrollTop < 360) {
        this.isSticky = false;
        if (sidebarHeader) sidebarHeader.classList.remove("sticky-header");
        if (sidebarHeader)
          sidebarHeader.style.height = `calc(100vh - ${420 - box.scrollTop}px)`;
      } else if (box.scrollTop >= 360) {
        this.isSticky = true;
        if (sidebarHeader) sidebarHeader.classList.add("sticky-header");
      }
      this.$emit("is-sticky", this.isSticky);
    },
    submitChanges() {
      const payload = {
        project_id: this.activeProject.project_id,
        owner: this.selectedOwner.owner,
        csm: this.selectedCsm.csm,
      };
      this.$emit("change-project-info", payload);
    },
    async sendMailHandle() {
      this.loading = true;
      this.openEmailConfirm = false;
      const payload = {
        email_type: this.selectedOption,
        project_id: this.projectSuccessData?.data?.project_id,
      };
      try {
        const res = await post("/email", payload);
        this.openSendMail = false;
        if (res.status === 200) {
          this.color = "green";
          this.snackbar = true;
          this.message = "Email successfully sent to Hiring Manager";
        } else {
          this.color = "red";
          this.snackbar = true;
          this.message = "Error: Email was not send, please try again later";
        }
      } catch (e) {
        this.openSendMail = false;
        this.color = "red";
        this.snackbar = true;
        this.message = "Error: Email was not send, please try again later";
      }
      this.loading = false;
    },
    async flushHandle() {
      this.loading = true;
      try {
        const res = await put("/operations_dash/data", {
          project_id: this.projectSuccessData?.data?.project_id,
          flush_cq: true,
        });
        this.openFlush = false;
        if (res.status === 200) {
          this.color = "green";
          this.snackbar = true;
          this.message = "Flushing Queue";
        } else {
          this.color = "red";
          this.snackbar = true;
          this.message = "Error - operation failed, please try again later";
        }
      } catch (e) {
        this.openFlush = false;
        this.color = "red";
        this.snackbar = true;
        this.message = "Error - operation failed, please try again later";
      }
      this.loading = false;
    },
    async stackHandle() {
      this.loading = true;
      try {
        const res = await put("/operations_dash/data", {
          project_id: this.projectSuccessData?.data?.project_id,
          atcs: true,
        });
        this.openStack = false;
        if (res.status === 200) {
          this.color = "green";
          this.snackbar = true;
          this.message = "Adding to stack";
        } else {
          this.color = "red";
          this.snackbar = true;
          this.message = "Error - operation failed, please try again later";
        }
      } catch (e) {
        this.openStack = false;
        this.color = "red";
        this.snackbar = true;
        this.message = "Error - operation failed, please try again later";
      }
      this.loading = false;
    },
    handleSubmitArchivedAndPause(params) {
      this.showArchivedPopup = false;
      if (this.isArchived) {
        this.submitArchiveProject(true, params);
      } else {
        this.submitPauseProject(true, params);
      }
    },
    async pauseProject(value) {
      this.submitPauseProject(value);
    },
    async submitPauseProject(value, params) {
      this.loading = true;
      this.isLoading = true;
      this.isShowActions = false;
      const project = {
        outreach_pause: value,
        ...params,
      };
      try {
        const res = await put(
          `projects/settings?project_id=${this.projectSuccessData?.data?.project_id}`,
          project
        );
        if (res.status === 200) {
          this.message = value
            ? "Your Project has been paused!"
            : "Project reactivated";
        }
      } catch (e) {
        this.message = "Error - operation failed, please try again later";
      }
      this.loading = false;
      this.isLoading = false;
      this.snackbar = true;
    },
    async archiveProject(value) {
      this.submitArchiveProject(value);
    },
    async submitArchiveProject(value, params) {
      this.loading = true;
      this.isLoading = true;
      this.isShowActions = false;
      console.log(value, params);
      const project = {
        archive: value,
        is_archived: value,
        ...params,
      };
      try {
        const res = await put(
          `projects/settings?project_id=${this.projectSuccessData?.data?.project_id}`,
          project
        );
        if (res.status === 200) {
          this.message = value
            ? "Your Project has been archived!"
            : "Project restored from archives";
        }
      } catch (e) {
        this.message = "Error - operation failed, please try again later";
      }
      this.loading = false;
      this.snackbar = true;
      this.isLoading = false;
    },
    async onSubmitResume() {
      this.resumeLoading = true;
      const formData = new FormData();
      formData.append("file", this.resumeFile);
      try {
        await postFile(
          `candidates/resume?project_id=${this.activeProject.project_id}&linkedin_profile_url=${this.resumeLinkedinUrl}`,
          formData
        );
        this.message = "Resume successfully uploaded";
      } catch (e) {
        this.message = "Resume upload failed, please try again later.";
        console.error(e);
      }
      this.snackbar = true;
      this.linkedinUrl = "";
      this.resumeFile = null;
      this.showResumeModal = false;
      this.resumeLoading = false;
    },
  },
  watch: {
    refreshLoading(v) {
      if (!v) {
        this.selectedOwner =
          this.dashboardSuccessData?.owners_list?.filter(
            (item) => item.owner === this.projectSuccessData.data.owner
          )[0] || {};
        this.selectedCsm =
          this.dashboardSuccessData?.csms_list?.filter(
            (item) => item.csm === this.projectSuccessData.data.csm
          )[0] || {};
        if (!this.showLoading) {
          return;
        }
        if (this.projectSuccessData) {
          this.successSnackbar = true;
        } else {
          this.errorSnackbar = true;
        }
        this.showLoading = false;
      }
    },
    dateId(v) {
      this.$emit("change-date-filter", v);
    },
    changeProjectInfoLoading(v) {
      if (!v) {
        this.editStaffMenu = false;
      }
    },
    editStaffMenu() {
      this.selectedOwner =
        this.dashboardSuccessData?.owners_list?.filter(
          (item) => item.owner === this.projectSuccessData.data.owner
        )[0] || {};
      this.selectedCsm =
        this.dashboardSuccessData?.csms_list?.filter(
          (item) => item.csm === this.projectSuccessData.data.csm
        )[0] || {};
    },
  },
  mounted() {
    this.selectedOwner =
      this.dashboardSuccessData?.owners_list?.filter(
        (item) => item.owner === this.projectSuccessData.data.owner
      )[0] || {};
    this.selectedCsm =
      this.dashboardSuccessData?.csms_list?.filter(
        (item) => item.csm === this.projectSuccessData.data.csm
      )[0] || {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";
.header {
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 20%);
  background-color: #fff;
  border-radius: 4px;
  white-space: nowrap;
  padding: 16px 24px;
  &-bottom {
    display: flex;
    justify-content: space-between;
  }
  &__select-box {
    padding-left: 8px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    border: 1px solid #d8d8d8;
    span {
      font-size: 12px;
      color: $gray-dark;
      letter-spacing: 0.3px;
      padding-top: 2px;
    }
  }
  &__left-col {
    display: flex;
    padding-top: 5px;
    max-width: 330px;
    width: 100%;
    &--item {
      border-bottom: 4px transparent;
      user-select: none;
      cursor: pointer;
      &__title {
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
        color: #4646ab;
        text-align: center;
        width: 100%;
        background: #fff;
        height: 24px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        align-items: center;
        border: 1px solid #d8d8d8;
      }
    }
    &--is-active {
      .header__left-col--item__title {
        color: #fff;
        background: #4646ab;
        border: 1px solid #4646ab;
      }
    }
  }
  &__right-col {
    display: flex;
    align-items: center;
    max-width: 330px;
    min-height: 70px;
    width: 100%;
    justify-content: end;
    &--item {
      user-select: none;
      cursor: pointer;
      &__icon {
        height: 25px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: none;
        padding: 0 12px !important;
        background-color: transparent !important;
        border: 1px solid #d8d8d8;
        &.active {
          border: 1px solid $gray-dark;
        }
        &::v-deep .v-btn__content {
          display: flex;
          align-items: center;
          gap: 4px;
        }
        img {
          filter: invert(24%) sepia(7%) saturate(1814%) hue-rotate(162deg)
            brightness(97%) contrast(85%);
        }
      }
      &__header {
        display: flex;
        justify-content: center;
        gap: 20px;
      }
      &__avatar {
        display: inline-block;
        width: 42px;
        height: 42px;
        overflow: hidden;
        border-radius: 50%;
        img {
          object-fit: cover;
          width: 42px;
          height: 42px;
        }
        &--no-image {
          background-color: $gray-dark;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          color: #fff;
        }
      }
      &__title {
        font-size: 12px;
        font-weight: 500;
        color: $gray-dark;
        text-align: center;
      }
      &__text {
        font-size: 12px !important;
        color: $black !important;
        text-transform: capitalize !important;
        letter-spacing: 0 !important;
        font-weight: 400 !important;
        padding-left: 4px;
      }
    }
  }
  &__active-project-name {
    font-size: 16px;
    color: $black;
    font-weight: 500;
    text-align: center;
    padding-top: 5px;
    cursor: default;
    width: 100%;
    white-space: pre-wrap;
    padding-left: 12px;
    padding-right: 12px;
  }
  &__refresh-btn {
    text-transform: capitalize !important;
    box-shadow: none;
    padding: 0 12px !important;
    border: 1px solid #d8d8d8;
    background-color: transparent !important;
    margin-left: 24px;
    @media only screen and (max-width: 1485px) {
      margin-left: 12px;
    }
    &::v-deep .v-btn__content {
      font-size: 12px;
      color: $gray-dark;
      letter-spacing: 0 !important;
      font-weight: 400 !important;
    }
  }
}
.menu {
  &__body {
    &--title {
      font-size: 12px;
      color: $gray-dark;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.3px;
      text-align: center;
      padding-top: 22px;
      padding-bottom: 19.5px;
      border-bottom: 1px solid $border-color;
    }
    &--item {
      padding-top: 23px;
      &__title {
        font-size: 14px;
        letter-spacing: 0.35px;
        font-weight: 700;
        color: $gray-dark;
        text-align: center;
      }
      &__box {
        padding: 27.5px 32px;
        border-bottom: 1px solid #e6e7eb;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        &--avatar {
          max-width: 42px;
          width: 100%;
          height: 42px;
          border-radius: 50%;
          background-color: #fff;
          overflow: hidden;
          display: inline-block;
          img {
            object-fit: cover;
            width: 42px;
            height: 42px;
          }
          &__no-image {
            background-color: $gray-dark;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            color: #fff;
          }
        }
        &--title {
          color: $gray-dark;
          font-size: 12px;
          letter-spacing: 0.3px;
          font-weight: 500;
          padding-left: 8px;
          padding-right: 8px;
        }
      }
      &__box:last-child {
        border: none;
      }
    }
    &--btn-box {
      display: flex;
      justify-content: center;
      padding-bottom: 40px;
      padding-top: 20px;
    }
    &--btn {
      box-shadow: none;
    }
  }
}
.custom-menu {
  width: 330px !important;
}
.date-menu {
  &__input {
    display: flex;
    margin-bottom: 2px;
  }
  &__model {
    padding-right: 16px;
    i {
      font-size: 12px;
      color: $gray-dark;
      padding-left: 4px;
    }
  }
  &__body {
    &--title {
      font-size: 12px;
      color: $gray-dark;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.3px;
      text-align: center;
      padding-top: 22px;
      padding-bottom: 19px;
      border-bottom: 1px solid $border-color;
    }
    &--radio-box {
      height: 385px;
      overflow-y: auto;
      &__radio {
        margin-top: 0;
      }
      i {
        position: absolute;
        top: 11px;
        left: 87px;
        font-size: 12px;
        color: $gray-dark;
      }
    }
  }
}
.select-icon {
  transition: 0.3s;
}
.selected {
  transform: rotate(180deg);
}
.border {
  border-bottom: 1px solid #e6e7eb;
}
.sticky {
  position: fixed;
  top: 52px;
  z-index: 1000000;
  width: calc(100% - 380px);
}
::v-deep {
  .v-label {
    font-size: 12px;
    color: $gray-dark !important;
    font-weight: 500;
    letter-spacing: 0.3px;
  }
  .theme--light.v-icon {
    color: #d8d8d8;
    font-size: 18px;
  }
  .v-snack {
    top: 60px;
  }
  .v-input--selection-controls {
    margin-top: 0px;
  }
}
.last-update-format {
  font-size: 10px;
  color: #111827;
  margin-left: 8px;
  font-style: italic;
  margin-right: 24px;
}
.header-filter {
  align-items: center;
  flex-wrap: wrap;
  row-gap: 24px;
}
.line-block {
  border-left: 1px solid #d8d8d8;
  padding-left: 24px;
  padding-right: 24px;
  @media only screen and (max-width: 1485px) {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.confirm-popup {
  text-align: center;
  padding: 12px 40px;
  position: relative;
  color: #384955;
  width: 300px;
  box-sizing: border-box;
  font-size: 14px;
  &::v-deep {
    .v-icon {
      font-size: 24px;
    }
  }
}
.close-btn {
  position: absolute;
  top: 0px;
  right: 10px;
}
.confirm-title {
  color: #384955;
  font-size: 14px;
  font-weight: 600;
}
.confirm-btn {
  color: #384955;
  font-size: 14px;
  text-transform: none;
  border: 2px solid #d8d8d8;
  margin-top: 12px;
}
.confirm-btn2 {
  text-transform: none;
  padding-right: 30px !important;
  padding-left: 30px !important;
  margin-top: 12px;
}
.confirm-text {
  color: #6b7280;
  font-size: 12px;
}
.project-manager-popup {
  width: 172px;
  &-title {
    color: #384955;
    font-size: 14px;
    font-weight: 600;
    padding: 8px;
    text-align: center;
  }
  &::v-deep {
    .v-list-item__title {
      font-size: 12px;
    }
    .v-list-item {
      padding: 0 16px !important;
    }
  }
}
.flush-popup {
  width: 186px;
  font-size: 12px;
  color: #031a28;
  text-align: center;
  padding: 0 16px 16px;
  &-title {
    color: #384955;
    font-size: 14px;
    font-weight: 600;
    padding: 8px;
  }
  &::v-deep {
    .v-btn {
      font-size: 12px;
      color: #6b7280;
      font-weight: 500;
      height: 24px !important;
      background-color: #e6e7eb;
      border: none;
    }
    .v-btn--text {
      border: none;
      font-weight: 400;
      background-color: transparent;
    }
  }
}

.resume-modal {
  background-color: white;
  padding: 8px;
}
</style>
