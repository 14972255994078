<template>
  <div>
    <div class="table-box">
      <div class="table-box__title">Emails Sent</div>
      <CustomLineChart
        v-if="!loading"
        :chartOptions="chartOptions"
        :series="series"
      ></CustomLineChart>
    </div>
    <div class="table-box mt-4">
      <div class="table-box__title">Email Find Rate</div>
      <CustomLineChart
        v-if="!loading"
        :chartOptions="chartOptionsRate"
        :series="seriesRate"
      ></CustomLineChart>
    </div>
    <div class="table-box mt-6">
      <div class="table-box__title">Email Open/Response</div>
      <v-data-table
        :headers="emailHeaders"
        :items="emailInfo"
        :items-per-page="5"
        class="table-box__table mt-3"
        :hide-default-footer="emailInfo.length <= 10"
      >
        <!-- eslint-disable-next-line -->
        <template #item.negative_response_percent="{ item }">
          {{ item.negative_response_percent.toFixed(2) }}%
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.positive_response_percent="{ item }">
          {{ item.positive_response_percent.toFixed(2) }}%
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.first_pixel="{ item }">
          {{ item.first_pixel.toFixed(2) }}%
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.second_pixel="{ item }">
          {{ item.second_pixel.toFixed(2) }}%
        </template>
      </v-data-table>
    </div>
    <div class="table-box mt-6">
      <div class="table-box__title">Template Performance</div>
      <v-data-table
        :headers="emailTemplateHeaders"
        :items="templateUse"
        :items-per-page="5"
        class="table-box__table mt-3"
        :hide-default-footer="templateUse.length <= 10"
      >
        <!-- eslint-disable-next-line -->
        <template #item.percentage_initial_sends="{ item }">
          {{ item.percentage_initial_sends.toFixed(2) }}%
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.open_rate="{ item }"> {{ item.open_rate }}% </template>
        <!-- eslint-disable-next-line -->
        <template #item.response_rate="{ item }">
          {{ item.response_rate }}%
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomLineChart from "./CustomLineChart";

export default {
  name: "email-info",
  props: {
    date: {
      type: String,
      default: "last_7",
    },
  },
  components: {
    CustomLineChart,
  },
  data() {
    return {
      loading: true,
      emailHeaders: [
        {
          text: "Initial Outreach mails sent",
          value: "initial_outreach_sent",
          align: "center",
          sortable: false,
        },
        {
          text: "Followup mails sent",
          value: "followups_sent",
          align: "center",
          sortable: false,
        },
        {
          text: "First Pixel",
          value: "first_pixel",
          align: "center",
          sortable: false,
        },
        {
          text: "Second  Pixel",
          value: "second_pixel",
          align: "center",
          sortable: false,
        },
        {
          text: "Positive Response %",
          value: "positive_response_percent",
          align: "center",
          sortable: false,
        },
        {
          text: "Negative Response %",
          value: "negative_response_percent",
          align: "center",
          sortable: false,
        },
      ],
      emailTemplateHeaders: [
        {
          text: "Template name",
          value: "template_name",
          align: "center",
          sortable: false,
        },
        {
          text: "Initial Sends",
          value: "initial_sends",
          align: "center",
          sortable: false,
        },
        {
          text: "% Total Initial Sends",
          value: "percentage_initial_sends",
          align: "center",
          sortable: false,
        },
        {
          text: "Open Rate",
          value: "open_rate",
          align: "center",
          sortable: false,
        },
        {
          text: "Response Rate",
          value: "response_rate",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("operations", [
      "projectLoading",
      "projectSuccessData",
      "projectFailureData",
    ]),
    templateUse() {
      if (
        this.projectSuccessData.data &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].templates_used
      ) {
        return this.projectSuccessData?.data[this.date].templates_used;
      }
      return [];
    },
    emailInfo() {
      const arr = [];
      if (
        this.projectSuccessData.data &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].email_information &&
        this.projectSuccessData.data[this.date].email_information
          .email_open_response
      ) {
        arr.push(
          this.projectSuccessData.data[this.date].email_information
            .email_open_response
        );
      }
      return arr;
    },
    emailObjAutoSwipe() {
      let obj = {};
      if (
        this.projectSuccessData.data &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].email_information &&
        this.projectSuccessData.data[this.date].email_information
          .email_find_and_send &&
        this.projectSuccessData.data[this.date].email_information
          .email_find_and_send.autoswipe
      ) {
        obj =
          this.projectSuccessData.data[this.date].email_information
            .email_find_and_send.autoswipe;
      }
      return obj;
    },
    emailObj() {
      let obj = {};
      if (
        this.projectSuccessData.data &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].email_information &&
        this.projectSuccessData.data[this.date].email_information
          .email_find_and_send &&
        this.projectSuccessData.data[this.date].email_information
          .email_find_and_send.followup
      ) {
        obj =
          this.projectSuccessData.data[this.date].email_information
            .email_find_and_send.followup;
      }
      return obj;
    },
    categoriesAutoswipe() {
      const arr = [];
      const obj = this.emailObjAutoSwipe;
      const result = Object.keys(obj).map((key) => [key, obj[key]]);
      result.forEach((item) => {
        arr.push(item[0]);
      });
      return arr;
    },
    categories() {
      const arr = [];
      const obj = this.emailObj;
      const result = Object.keys(obj).map((key) => [key, obj[key]]);
      result.forEach((item) => {
        arr.push(item[0]);
      });
      return arr;
    },
    categoriesData() {
      const arr = [...this.categories];
      this.categoriesAutoswipe.forEach((item) => {
        if (!arr.includes(item)) {
          arr.push(item);
        }
      });
      return arr.sort();
    },
    maxValue() {
      let max = 0;
      const obj = this.emailObjAutoSwipe;
      const result = Object.keys(obj).map((key) => [key, obj[key]]);
      result.forEach((item) => {
        if (max < item[1]) max = item[1];
      });
      const obj2 = this.emailObjAutoSwipe;
      const result2 = Object.keys(obj2).map((key) => [key, obj2[key]]);
      result2.forEach((item) => {
        if (max < item[1]) max = item[1];
      });
      return max;
    },
    maxValueRate() {
      let max = 0;
      const obj = this.emailFindRate;
      const result = Object.keys(obj).map((key) => [key, obj[key]]);
      result.forEach((item) => {
        if (max < item[1]) max = item[1];
      });
      return max;
    },
    chartOptions() {
      return {
        chart: {
          height: 200,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        grid: {
          row: {
            colors: ["transparent"],
            opacity: 0.5,
          },
        },
        colors: ["#002d5f", "#1d7bc8"],
        xaxis: {
          categories: [...this.categoriesData],
        },
        legend: {
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          min: 0,
          max: this.maxValue,
          tickAmount: 2,
        },
        markers: {
          size: 3,
        },
        tooltip: {
          custom: function ({ series, dataPointIndex, w }) {
            const title = w.globals.categoryLabels[dataPointIndex];
            const tmp = [];
            const compare = (a, b) => {
              if (a.value < b.value) {
                return -1;
              }
              if (a.value > b.value) {
                return 1;
              }
              // a must be equal to b
              return 0;
            };
            series.forEach((item, i) => {
              tmp.push({
                value: item[dataPointIndex],
                color: w.globals.colors[i],
                label: w.globals.initialSeries[i].name,
              });
            });
            const arr = [];
            tmp
              .sort(compare)
              .reverse()
              .forEach((item) => {
                arr.push(
                  `<li><span class="color" style="background:${item.color}"></span><span>${item.label}:</span><strong>${item.value}</strong></li>`
                );
              });
            return `<div class="custom-tooltip">
                <div class="text">${title}</div>
                <ul>
                  ${arr.join("")}
                </ul>
              </div>`;
          },
        },
      };
    },
    chartOptionsRate() {
      return {
        chart: {
          height: 200,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        grid: {
          row: {
            colors: ["transparent"],
            opacity: 0.5,
          },
        },
        colors: ["#002d5f", "#1d7bc8"],
        xaxis: {
          categories: [...this.categoriesData],
        },
        legend: {
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          min: 0,
          max: this.maxValueRate,
          tickAmount: 2,
          labels: {
            formatter: (value) => `${value}%`,
          },
        },
        markers: {
          size: 3,
        },
        tooltip: {
          custom: function ({ series, dataPointIndex, w }) {
            const title = w.globals.categoryLabels[dataPointIndex];
            const tmp = [];
            const compare = (a, b) => {
              if (a.value < b.value) {
                return -1;
              }
              if (a.value > b.value) {
                return 1;
              }
              // a must be equal to b
              return 0;
            };
            series.forEach((item, i) => {
              tmp.push({
                value: item[dataPointIndex],
                color: w.globals.colors[i],
                label: w.globals.initialSeries[i].name,
              });
            });
            const arr = [];
            tmp
              .sort(compare)
              .reverse()
              .forEach((item) => {
                arr.push(
                  `<li><span class="color" style="background:${item.color}"></span><span>${item.label}:</span><strong>${item.value}%</strong></li>`
                );
              });
            return `<div class="custom-tooltip">
                <div class="text">${title}</div>
                <ul>
                  ${arr.join("")}
                </ul>
              </div>`;
          },
        },
      };
    },
    seriesAutoSwipe() {
      const arr = [];
      if (
        this.projectSuccessData.data &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].email_information &&
        this.projectSuccessData.data[this.date].email_information
          .email_find_and_send &&
        this.projectSuccessData.data[this.date].email_information
          .email_find_and_send.autoswipe
      ) {
        const obj =
          this.projectSuccessData.data[this.date].email_information
            .email_find_and_send.autoswipe;
        this.categoriesData.forEach((item) => {
          if (!obj[item]) {
            arr.push(0);
          } else {
            arr.push(obj[item]);
          }
        });
      }
      return arr;
    },
    seriesFollowUp() {
      const arr = [];
      if (
        this.projectSuccessData.data &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].email_information &&
        this.projectSuccessData.data[this.date].email_information
          .email_find_and_send &&
        this.projectSuccessData.data[this.date].email_information
          .email_find_and_send.followup
      ) {
        const obj =
          this.projectSuccessData.data[this.date].email_information
            .email_find_and_send.followup;
        this.categoriesData.forEach((item) => {
          if (!obj[item]) {
            arr.push(0);
          } else {
            arr.push(obj[item]);
          }
        });
      }
      return arr;
    },
    emailFindRate() {
      const arr = [];
      if (
        this.projectSuccessData.data &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].email_information &&
        this.projectSuccessData.data[this.date].email_information
          .email_find_and_send &&
        this.projectSuccessData.data[this.date].email_information
          .email_find_and_send.find_rate
      ) {
        const obj =
          this.projectSuccessData.data[this.date].email_information
            .email_find_and_send.find_rate;
        this.categoriesData.forEach((item) => {
          if (!obj[item]) {
            arr.push(0);
          } else {
            arr.push(obj[item]);
          }
        });
      }
      return arr;
    },
    series() {
      return [
        {
          name: "autoswipe_count",
          data: this.seriesAutoSwipe,
        },
        {
          name: "followup_count",
          data: this.seriesFollowUp,
        },
      ];
    },
    seriesRate() {
      return [
        {
          name: "find rate",
          data: this.emailFindRate,
        },
      ];
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 100);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";
.table-box {
  overflow-y: auto;
  // height: 332px;
  padding: 16px 20px;
  border: 1px solid $border-color;
  border-radius: 4px;
  max-height: 100%;
  min-height: 200px;
  &__title {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: $black;
    text-transform: uppercase;
  }
  &__subtitle {
    color: $black;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
  }
}
::v-deep {
  th {
    font-size: 12px;
    font-weight: 600 !important;
    color: $black !important;
    border-bottom: none !important;
  }
  td {
    border-bottom: none !important;
    font-size: 14px;
    color: $gray-dark;
  }
}
::v-deep {
  .custom-tooltip {
    .text {
      padding: 8px 12px;
      font-size: 14px;
      border-bottom: 1px solid #ddd;
      background-color: #f5f5f5;
    }
    ul {
      list-style: none;
      margin: 0px;
      padding: 12px;
      li {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
    .color {
      width: 12px;
      height: 12px;
      border-radius: 100%;
    }
  }
}
</style>
