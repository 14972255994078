<template>
  <div>
    <div class="operations-box">
      <div class="operations-box__menu">
        <div class="operations-box__menu-box">
          <div class="operations-box__menu-box--left-col">
            <div class="menu__title">Companies</div>
            <div class="mr-1">
              <v-icon size="21px">mdi-magnify</v-icon>
            </div>
            <v-menu
              content-class="custom-menu"
              nudge-bottom="40px"
              :close-on-content-click="false"
              v-model="openCompanyMenu"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="menu__input" v-bind="attrs" v-on="on">
                  <div>
                    <div
                      class="menu__input--model"
                      v-if="companies.length === getCompaniesList.length"
                    >
                      ALL <i>(default)</i>
                    </div>
                    <div class="menu__input--model" v-else>
                      {{
                        companies.length === 1 || companies.length === 0
                          ? `${companies.length} Company`
                          : `${companies.length} Companies`
                      }}
                    </div>
                  </div>
                  <div>
                    <v-icon
                      :class="{ selected: openCompanyMenu }"
                      class="select-icon"
                      color="#384955"
                      >mdi-chevron-down</v-icon
                    >
                  </div>
                </div>
              </template>

              <v-list>
                <div class="menu__body--title">Choose Companies to display</div>
                <div class="menu__body--selected-box">
                  <div class="menu__body--selected-box__clear-all">
                    <v-btn @click="companies = []" height="30px">
                      Clear All
                    </v-btn>
                  </div>
                </div>
                <div class="menu__body--checkbox-box pb-4">
                  <div class="menu__body--checkbox-box__all pb-4">
                    <v-checkbox
                      :ripple="false"
                      v-model="selectedAllCompanies"
                      label="ALL"
                      color="#384955"
                      hide-details
                      class="menu__body--checkbox-box__checkbox ml-7"
                    ></v-checkbox>
                    <i>(default)</i>
                  </div>
                  <div class="menu__body--checkbox-box__other pr-1">
                    <v-checkbox
                      v-for="(item, i) in getCompaniesList"
                      :key="`checkbox_${i}`"
                      :ripple="false"
                      :off-icon="'mdi-square-rounded-outline'"
                      v-model="companies"
                      :label="item"
                      color="#384955"
                      :value="item"
                      hide-details
                      class="menu__body--checkbox-box__checkbox pb-1 ml-7 mr-2"
                    ></v-checkbox>
                  </div>
                </div>
              </v-list>
            </v-menu>
          </div>
          <div class="operations-box__menu-box--right-col">
            <div class="menu__title">Owner</div>
            <v-menu
              content-class="custom-menu"
              nudge-bottom="40px"
              :close-on-content-click="false"
              v-model="openOwnerMenu"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="menu__input" v-bind="attrs" v-on="on">
                  <div>
                    <div
                      class="menu__input--model"
                      v-if="owners.length === getOwnersList.length"
                    >
                      ALL <i>(default)</i>
                    </div>
                    <div class="menu__input--model" v-else>
                      {{
                        owners.length === 1 || owners.length === 0
                          ? `${owners.length} Owner`
                          : `${owners.length} Owners`
                      }}
                    </div>
                  </div>
                  <div>
                    <v-icon
                      :class="{ selected: openOwnerMenu }"
                      class="select-icon"
                      color="#384955"
                      >mdi-chevron-down</v-icon
                    >
                  </div>
                </div>
              </template>

              <v-list>
                <div class="menu__body--title">Current owners</div>
                <div class="menu__body--checkbox-box pb-4">
                  <div class="menu__body--checkbox-box__all mt-8 pb-4">
                    <v-checkbox
                      :ripple="false"
                      :off-icon="'mdi-square-rounded-outline'"
                      v-model="selectedAllOwners"
                      label="ALL"
                      color="#384955"
                      hide-details
                      class="menu__body--checkbox-box__checkbox ml-7"
                    ></v-checkbox>
                    <i>(default)</i>
                  </div>
                  <div class="menu__body--checkbox-box__other-1 pr-1">
                    <v-checkbox
                      v-for="(item, i) in getOwnersList"
                      :key="`checkbox_${i}`"
                      :ripple="false"
                      :off-icon="'mdi-square-rounded-outline'"
                      v-model="owners"
                      :label="item"
                      color="#384955"
                      :value="item"
                      hide-details
                      class="menu__body--checkbox-box__checkbox pb-1 ml-7 mr-2"
                    ></v-checkbox>
                  </div>
                </div>
              </v-list>
            </v-menu>
          </div>
          <div class="operations-box__menu-box--right-col">
            <div class="menu__title">Project ID</div>
            <v-text-field
              hide-details
              clearable
              v-model="projectId"
              @input="$emit('updateFilterProjectId', projectId)"
              class="pt-0 mt-0 custom-input"
            ></v-text-field>
          </div>
        </div>
      </div>
      <div class="slider-box">
        <v-progress-circular
          v-if="dashboardLoading"
          indeterminate
          color="primary"
          class="custom-loader"
        ></v-progress-circular>
        <v-slide-group
          v-model="sliderModel"
          class="pt-6 slider-box__carousel"
          mandatory
          :show-arrows="true"
          v-else
        >
          <v-slide-item v-for="(item, i) in sliderItems" :key="`slider${i}`">
            <v-card class="ml-2 mr-2 pl-2 pr-2 slider-box__item" height="100">
              <div class="slider-box__item--title">
                {{ item.title }}
              </div>
              <div class="slider-box__item__btns">
                <v-btn
                  class="slider-box__item__btn"
                  v-for="(menu, i) in item.menu"
                  :key="i"
                  elevation="0"
                  @click="changeActiveStatus(menu.status, true)"
                >
                  <span
                    :style="{ color: `${menu.color}` }"
                    class="slider-box__item--count"
                  >
                    {{ menu.count || 0 }}
                  </span>
                  <span
                    class="slider-box__item--subtitle"
                    :class="{
                      'slider-box__item--subtitle-active':
                        menu.status === activeStatus,
                      'has-info': menu.info && menu.count,
                    }"
                  >
                    {{ menu.subtitle }}
                    <InfoTooltip
                      v-if="menu.info && menu.count"
                      :content="infoContent[menu.status]"
                    />
                    {{ menu.percent }}
                  </span>
                </v-btn>
              </div>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InfoTooltip from "@/components/InfoTooltip";

export default {
  name: "operations-box",
  props: {
    filterStatusCounts: Object,
    closeMenu: Boolean,
    defaultStatus: String,
    filterProjectId: String,
  },
  components: {
    InfoTooltip,
  },
  data() {
    return {
      activeStatus: "",
      openCompanyMenu: false,
      openOwnerMenu: false,
      companies: [],
      owners: [],
      projectId: "",
      sliderModel: null,
      priorityOrder: [
        "new",
        "new_review",
        "free_trial",
        "hm_approval",
        "client_delay",
        "backlog",
        "slow_pace",
        "borderline",
        "healthy",
        "free_trial_debrief",
        "narrow",
      ],
      isChanged: false,
      infoContent: {
        slow_pace: [
          {
            text: "Check if emails are being sent and delivered",
          },
          {
            text: "Analyze uninterested candidates and address any egregious patterns",
          },
          {
            text: "Change Search Preferences",
            subs: [
              "Lower years of experience (min and max)",
              "Put in a less prestigious set of companies or turn off companies",
              "Put in less competitive cities (eg Chicago instead of San Francisco)",
            ],
          },
          {
            text: "Change templates",
            subs: ["Change subject lines", "Change template content"],
          },
          {
            text: "Last resort: turn priority mode on to send more emails",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("operations", [
      "dashboardLoading",
      "dashboardSuccessData",
      "dashboardFailureData",
    ]),
    itemsiesList() {
      let obj = {};
      if (this.dashboardSuccessData) {
        obj = this.dashboardSuccessData.projects.reduce(function (r, a) {
          r[a.company.toUpperCase().trim()] =
            r[a.company.toUpperCase().trim()] || [];
          r[a.company.toUpperCase().trim()].push(a);
          return r;
        }, Object.create(null));
        for (let company in obj) {
          obj[company] = obj[company].filter(
            (project) => project.operations_status !== "paused"
          );
          if (!obj[company] || obj[company].length === 0) {
            delete obj[company];
          }
        }
      }
      return obj;
    },
    getOwnersList() {
      if (this.dashboardSuccessData) {
        var arr = [];
        this.dashboardSuccessData.projects.forEach((project) => {
          arr.push(project.owner);
        });
      }
      let owners = [...new Set(arr)];
      return owners;
    },
    getCompaniesList() {
      if (this.dashboardSuccessData) {
        var arr = [];
        for (let company in this.itemsiesList) {
          arr.push(company);
        }
        // this.dashboardSuccessData.projects.forEach((project) => {
        //   arr.push(project.company.toUpperCase().trim());
        // });
      }
      let companies = [...new Set(arr)];
      return companies.sort();
    },
    selectedAllCompanies: {
      set(val) {
        this.companies = [];
        if (val) {
          for (let i = 0; i < this.getCompaniesList.length; i++) {
            this.companies.push(this.getCompaniesList[i]);
          }
        }
      },
      get() {
        return this.companies.length === this.getCompaniesList.length;
      },
    },
    selectedAllOwners: {
      set(val) {
        this.owners = [];
        if (val) {
          for (let i = 0; i < this.getOwnersList.length; i++) {
            this.owners.push(this.getOwnersList[i]);
          }
        }
      },
      get() {
        return this.owners.length === this.getOwnersList.length;
      },
    },
    sliderItems() {
      const items = [
        {
          title: "Critical",
          menu: [
            {
              subtitle: "Backlog",
              count: this.filterStatusCounts.backlog,
              status: "backlog",
              color: "#CA3D3D",
              info: true,
            },
            {
              subtitle: "Slow Pace",
              count: this.filterStatusCounts.slow_pace,
              status: "slow_pace",
              color: "#CA3D3D",
              info: true,
            },
            {
              subtitle: "Approval Rate",
              count: this.filterStatusCounts.hm_approval,
              status: "hm_approval",
              color: "#CA3D3D",
              info: true,
            },
            {
              subtitle: "Narrow",
              count: this.filterStatusCounts.narrow,
              status: "narrow",
              color: "#CA3D3D",
              info: true,
            },
          ],
        },
        {
          title: "New",
          menu: [
            {
              subtitle: "To - Do",
              count: this.filterStatusCounts.new,
              status: "new",
              color: "#1DA1F2",
            },
            {
              subtitle: "For Review",
              count: this.filterStatusCounts.new_review,
              status: "new_review",
              color: "#1DA1F2",
            },
          ],
        },
        {
          title: "Delays",
          menu: [
            {
              subtitle: "By Client",
              count: this.filterStatusCounts.client_delay,
              status: "client_delay",
              color: "#F2C211",
            },
          ],
        },
        {
          title: "Free Trials",
          menu: [
            {
              count: this.filterStatusCounts.free_trial,
              status: "free_trial",
              color: "#4F46E5",
              subtitle: "Active",
            },
            {
              count: this.filterStatusCounts.free_trial_debrief,
              status: "free_trial_debrief",
              color: "#4F46E5",
              subtitle: "Debrief",
            },
          ],
        },
        {
          title: "Borderline",
          menu: [
            {
              count: this.filterStatusCounts.borderline,
              status: "borderline",
              color: "#F2991D",
            },
          ],
        },
        {
          title: "Healthy",
          menu: [
            {
              count: this.filterStatusCounts.healthy,
              status: "healthy",
              color: "#37B797",
              percent: `(${Math.round(this.percentageOfHealthy * 100)}%)`,
            },
          ],
        },
        {
          title: "Self Serve",
          menu: [
            {
              count: this.filterStatusCounts.self_serve,
              status: "self_serve",
              color: "#4F46E5",
            },
          ],
        },
      ];
      return items;
    },
    percentageOfHealthy() {
      return (
        this.filterStatusCounts.healthy /
        (this.filterStatusCounts.healthy +
          this.filterStatusCounts.borderline +
          this.filterStatusCounts.client_delay +
          this.filterStatusCounts.hm_approval +
          this.filterStatusCounts.slow_pace +
          this.filterStatusCounts.backlog)
      );
    },
  },
  mounted() {
    this.owners = this.getOwnersList;
    this.companies = this.getCompaniesList;
    this.projectId = this.filterProjectId;
    this.getDefaultStatus();
  },
  watch: {
    closeMenu(v) {
      if (v) {
        this.openCompanyMenu = false;
        this.openOwnerMenu = false;
      }
    },
    openCompanyMenu(v) {
      if (v) {
        this.$emit("open-menu");
      }
    },
    openOwnerMenu(v) {
      if (v) {
        this.$emit("open-menu");
      }
    },
    dashboardLoading(v) {
      this.activeStatus = "";
      if (!v) {
        this.owners = this.getOwnersList;
        this.companies = this.getCompaniesList;
      }
    },
    companies(v) {
      this.$emit("filter-projects-companies", v);
    },
    owners(v) {
      this.$emit("filter-projects-owners", v);
    },
    filterProjectId(v) {
      this.projectId = v;
    },
    filterStatusCounts: {
      handler: function () {
        if (!this.isChanged) {
          this.getDefaultStatus();
        } else {
          this.isChanged = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    changeActiveStatus(status, isChanged) {
      this.isChanged = isChanged;
      if (this.activeStatus === status) {
        this.activeStatus = "";
      } else {
        this.activeStatus = status;
      }
      this.$emit("filter-projects-status", this.activeStatus);
    },
    getDefaultStatus() {
      if (this.defaultStatus) {
        this.activeStatus = this.defaultStatus;
        this.changeActiveStatus(this.activeStatus, false);
        this.changeActiveStatus(this.defaultStatus, false);
        return;
      }
      this.changeActiveStatus(this.activeStatus, false);
      this.priorityOrder.forEach((item) => {
        if (!this.activeStatus) {
          for (let i = this.sliderItems.length - 1; i >= 0; i--) {
            const a = this.sliderItems[i]?.menu.find((v) => v.status === item);
            if (a && a.count > 0) {
              this.changeActiveStatus(item, false);
              break;
            }
            if (this.activeStatus) break;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";
.operations-box {
  padding: 36px 24px;
  margin: 0px 36px 16px 36px;
  box-shadow: -1px 1px 9px 0 rgba(0, 0, 0, 0.2);
  width: calc(100% - 72px);
  border-radius: 20px;
  background-color: #fff;
  position: relative;
  z-index: 10;
  &__title {
    padding-left: 24px;
    color: $black;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
  }
  &__menu {
    display: flex;
    justify-content: center;
  }
  &__menu-box {
    height: 58px;
    border-radius: 29px;
    border: solid 1px #d8d8d8;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    gap: 60px;
    > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
.menu {
  &__title {
    color: $gray-dark;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    font-size: 14px;
    font-weight: 500;
  }
  &__input {
    height: 22px;
    border-bottom: 1px solid #e6e7eb;
    width: 200px;
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    align-items: center;
    &--model {
      font-size: 14px;
      font-weight: 500;
      color: $gray-dark;
      letter-spacing: 0.35px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 160px;
      i {
        font-weight: 400 !important;
      }
    }
  }
  &__body {
    &--title {
      font-size: 12px;
      color: $gray-dark;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.3px;
      text-align: center;
      padding-top: 16px;
      padding-bottom: 24px;
      border-bottom: 1px solid $border-color;
    }
    &--selected-box {
      &__title {
        font-size: 12px;
        color: $gray-dark;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.3px;
        padding-left: 24px;
        padding-bottom: 2px;
      }
      &__body {
        padding: 12px;
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
      }
      &__clear-all {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        button {
          text-transform: capitalize;
          border: none;
          font-weight: 400;
          box-shadow: none !important;
          background-color: #fff !important;
          &::v-deep .v-btn__content {
            color: $gray-dark !important;
            letter-spacing: 0 !important;
            font-size: 13px;
          }
        }
      }

      &__item {
        text-transform: uppercase;
        width: 95px;
        height: 27px;
        background-color: $gray-light;
        border-radius: 27px;
        font-size: 12px;
        line-height: 12px;
        color: $gray-dark;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        font-weight: 500;
        text-align: center;
        user-select: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: 7.5px 5px 0 5px;
      }
    }
    &--checkbox-box {
      &__all {
        position: relative;
        i {
          position: absolute;
          top: 6px;
          font-size: 12px;
          color: $gray-dark;
          left: 80px;
        }
      }
      &__other {
        height: 255px;
        overflow-y: auto;
      }
      &__other-1 {
        height: 150px;
      }
      &__checkbox {
        margin-top: 0;
      }
    }
  }
}
.slider-box {
  display: flex;
  justify-content: center;
  align-items: center;
  &__carousel {
    // max-width: 1235px;
    margin: 0 auto;
  }
  &__item {
    user-select: none;
    box-shadow: none !important;
    border: 2px solid #dddddd !important;
    &:hover {
      border-color: #4646ab !important;
    }
    border-radius: 8px !important;
    padding-top: 12px;
    &__btns {
      display: flex;
      gap: 8px;
      padding: 0 8px;
      justify-content: center;
    }
    &__btn {
      background: none !important;
      height: auto !important;
      min-width: 140px !important;
      @media only screen and (max-width: 1925px) {
        min-width: 71px !important;
      }
      @media only screen and (max-width: 1570px) {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
      @media only screen and (max-width: 1440px) {
        min-width: 0 !important;
      }
      &::v-deep {
        .v-btn__content {
          flex-flow: column;
          justify-content: end;
          span {
            display: block;
          }
        }
      }
    }
    &--title {
      text-transform: uppercase;
      color: $black;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
    }
    &--count {
      font-size: 30px;
      font-weight: 700;
      letter-spacing: 0.75px;
      text-align: center;
      line-height: 39px;
    }
    &--subtitle {
      padding: 3px;
      text-transform: uppercase;
      font-size: 12px;
      color: $gray-dark;
      text-align: center;
      border-radius: 3px;
      min-width: 50px;
      &-active {
        background: #4646ab;
        color: #fff;
      }
      &.has-info {
        padding: 3px 25px 3px 3px;
      }
    }
  }
  &__is-active {
    border: 4px solid $primary !important;
    padding-top: 10px;
  }
}
.custom-menu {
  width: 330px !important;
  z-index: 50 !important;
  background: #fff;
}
.select-icon {
  transition: 0.3s;
}
.selected {
  transform: rotate(180deg);
}
::v-deep {
  .v-ripple__container {
    display: none !important;
  }
  .v-card--link:focus:before {
    display: none !important;
  }
  .v-input--selection-controls__input {
    i {
      font-size: 15px;
    }
  }
  .v-label {
    font-size: 12px;
    color: $gray-dark !important;
    font-weight: 500;
    letter-spacing: 0.3px;
  }
  .v-input--selection-controls__input {
    margin-right: 3px !important;
  }
}
.custom-loader {
  margin: 0 auto;
}
.custom-input {
  &::v-deep {
    .v-input__slot {
      padding-left: 15px;
      &::before,
      &::after {
        bottom: 5px !important;
        border-color: #e6e7eb !important;
        background-color: #e6e7eb !important;
      }
    }
  }
}
</style>
