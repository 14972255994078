<template>
  <div class="dropdown-list" v-if="value">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "DropdownList",
  props: ["value"],
  mounted() {
    document.addEventListener("click", this.outsideClickListener);
  },
  unmounted() {
    document.removeEventListener("click", this.outsideClickListener);
  },
  methods: {
    outsideClickListener(e) {
      if (
        !e.target.closest(".menu-open") &&
        !e.target.classList.value.includes("menu-open")
      ) {
        this.$emit("update", false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown {
  &-list {
    position: absolute;
    top: 100%;
    left: 5%;
    width: 90%;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
      0 3px 14px 2px rgb(0 0 0 / 12%);
    border-radius: 4px;
  }
}
</style>
