<template>
  <div class="body__container">
    <TabsHeader
      @change-tab="changeActiveTab"
      :active-project="activeProject"
      @refresh="$emit('refresh')"
      @change-date-filter="changeDateFilter"
      @change-project-info="changeProjectInfo"
      @is-sticky="changeSticky"
      class="position-sticky sticky-content z-10"
    />
    <TabsContainer
      :active-tab-name="activeTabName"
      @change-justification="changeJustification"
      @change-trial="changeTrial"
      :filter-date="filterDate"
      :is-sticky="isSticky"
      :projectId="activeProject.project_id"
      v-if="activeProject.project_id"
    />
  </div>
</template>

<script>
import TabsHeader from "@/components/TabsHeader.vue";
import TabsContainer from "@/components/TabsContainer.vue";
export default {
  name: "dashboard-body",
  components: {
    TabsHeader,
    TabsContainer,
  },
  props: {
    activeProject: Object,
  },
  data() {
    return {
      activeTabName: "ProjectData",
      filterDate: "last_7",
      isSticky: false,
    };
  },
  methods: {
    changeActiveTab(v) {
      this.activeTabName = v;
    },
    changeJustification(payload) {
      this.$emit("change-justification", payload);
    },
    changeProjectInfo(payload) {
      this.$emit("change-project-info", payload);
    },
    changeDateFilter(v) {
      this.filterDate = v;
    },
    changeSticky(v) {
      this.isSticky = v;
    },
    changeTrial(payload) {
      this.$emit("change-trial", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  &__container {
    width: 100%;
    padding-bottom: 60px;
    max-width: calc(100vw - 456px);
  }
}
.top-0 {
  top: 0px;
}
.z-10 {
  z-index: 10;
}
.sticky-content {
  top: 89px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
</style>
