<template>
  <div class="table-container">
    <div class="table-box">
      <div class="table-box__title">Advanced Information</div>
      <div class="table-box__subtitle">ATCS</div>
      <div class="table-box__chart-box">
        <CustomLineChart
          v-if="!loading"
          :chartOptions="chartOptions"
          :series="series"
        ></CustomLineChart>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomLineChart from "./CustomLineChart";

export default {
  name: "technical-info",
  props: {
    date: String,
  },
  components: {
    CustomLineChart,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters("operations", [
      "projectLoading",
      "projectSuccessData",
      "projectFailureData",
    ]),
    atcs() {
      let arr = [];
      if (
        this.projectSuccessData.data &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date].advanced_technical_info &&
        this.projectSuccessData.data[this.date].advanced_technical_info.atcs
      ) {
        arr =
          this.projectSuccessData.data[this.date].advanced_technical_info.atcs;
      }
      const compare = (a, b) => {
        if (a.date < b.date) {
          return -1;
        }
        if (a.date > b.date) {
          return 1;
        }
        // a must be equal to b
        return 0;
      };
      const result = arr.sort(compare);
      return result;
    },
    categories() {
      const arr = [];
      this.atcs.forEach((item) => {
        arr.push(item.date);
      });

      return arr;
    },
    maxValue() {
      let max = 0;
      this.lenPre.forEach((item) => {
        if (max < item) max = item;
      });
      this.lenPost.forEach((item) => {
        if (max < item) max = item;
      });
      this.nTo_Add.forEach((item) => {
        if (max < item) max = item;
      });
      return max;
    },
    chartOptions() {
      return {
        chart: {
          height: 200,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        grid: {
          row: {
            colors: ["transparent"],
            opacity: 0.5,
          },
        },
        colors: ["#1c8143", "#c45927", "#52a1e4"],
        xaxis: {
          categories: this.categories,
        },
        legend: {
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          min: 0,
          max: this.maxValue,
          tickAmount: 3,
        },
        markers: {
          size: 3,
        },
        tooltip: {
          custom: function ({ series, dataPointIndex, w }) {
            const title = w.globals.categoryLabels[dataPointIndex];
            const tmp = [];
            const compare = (a, b) => {
              if (a.value < b.value) {
                return -1;
              }
              if (a.value > b.value) {
                return 1;
              }
              // a must be equal to b
              return 0;
            };
            series.forEach((item, i) => {
              tmp.push({
                value: item[dataPointIndex],
                color: w.globals.colors[i],
                label: w.globals.initialSeries[i].name,
              });
            });
            const arr = [];
            tmp
              .sort(compare)
              .reverse()
              .forEach((item) => {
                arr.push(
                  `<li><span class="color" style="background:${item.color}"></span><span>${item.label}:</span><strong>${item.value}</strong></li>`
                );
              });
            return `<div class="custom-tooltip">
                <div class="text">${title}</div>
                <ul>
                  ${arr.join("")}
                </ul>
              </div>`;
          },
        },
      };
    },
    lenPre() {
      const arr = [];
      this.atcs.forEach((item) => {
        arr.push(item.len_pre);
      });
      return arr;
    },
    lenPost() {
      const arr = [];
      this.atcs.forEach((item) => {
        arr.push(item.len_post);
      });
      return arr;
    },
    nTo_Add() {
      const arr = [];
      this.atcs.forEach((item) => {
        arr.push(item.n_to_add);
      });
      return arr;
    },
    series() {
      return [
        {
          name: "len_pre",
          data: this.lenPre,
        },
        {
          name: "len_post",
          data: this.lenPost,
        },
        {
          name: "n_to_add",
          data: this.nTo_Add,
        },
      ];
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 100);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";

.table-box {
  padding: 16px 20px;
  border: 1px solid $border-color;
  border-radius: 4px;
  max-height: 100%;
  min-height: 200px;
  &__title {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: $black;
    text-transform: uppercase;
  }
  &__subtitle {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: $black;
    padding-left: 20px;
    padding-top: 16px;
  }
  &__chart-box {
    margin-top: 13.5px;
    height: 200px;
    border: 1px solid #d8d8d8;
  }
}
::v-deep {
  th {
    font-size: 12px;
    font-weight: 600 !important;
    color: $black !important;
    border-bottom: none !important;
  }
  td {
    // border: 1px solid $border-color !important;
    font-size: 11px !important;
    color: $gray-dark;
    height: 20px !important;
    line-height: 20px !important;
  }
  td:nth-child(2) {
    border-right: 1px solid $border-color !important;
  }
  td:nth-child(1) {
    border-left: 1px solid $border-color !important;
  }
  tr:first-child {
    td {
      border-top: 1px solid $border-color !important;
    }
  }
  tr:last-child {
    td {
      border-bottom: 1px solid $border-color !important;
    }
  }
}
::v-deep {
  .custom-tooltip {
    .text {
      padding: 8px 12px;
      font-size: 14px;
      border-bottom: 1px solid #ddd;
      background-color: #f5f5f5;
    }
    ul {
      list-style: none;
      margin: 0px;
      padding: 12px;
      li {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
    .color {
      width: 12px;
      height: 12px;
      border-radius: 100%;
    }
  }
}
</style>
