<template>
  <div class="table-container">
    <div class="table-flex-box">
      <div class="table-box">
        <div class="table-box__title">Candidates - Not Interested Reasons</div>
        <v-data-table
          :headers="rejectHeaders"
          :items="rejectItems"
          :items-per-page="10"
          class="table-box__table"
          must-sort
          :hide-default-footer="rejectItems.length <= 10"
        >
        </v-data-table>
      </div>
      <div class="table-box">
        <div class="table-box__title mb-3">
          Candidates - Rejected Reasons - Pie Chart
        </div>
        <div class="diagram-box">
          <CustomPieChart
            v-if="!loading && rejectItemsCount !== 0"
            :chartOptions="chartOptions"
            :series="series"
          ></CustomPieChart>
          <div class="no-data" v-else>No data available</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomPieChart from "./CustomPieChart";

export default {
  name: "reject-breakdown",
  props: {
    date: String,
  },
  components: {
    CustomPieChart,
  },
  data() {
    return {
      loading: true,
      rejectHeaders: [
        {
          text: "Reason",
          value: "reason",
          align: "start",
          sortable: false,
        },
        {
          text: "Count",
          value: "count",
          align: "center",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("operations", [
      "projectLoading",
      "projectSuccessData",
      "projectFailureData",
    ]),
    rejectItemsCount() {
      let count = 0;
      this.rejectItems.forEach((element) => {
        count += element.count;
      });
      return count;
    },
    rejectItems() {
      let reasons = {};
      if (
        this.projectSuccessData.data &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date]
          .candidates_rejection_breakdown &&
        this.projectSuccessData.data[this.date].candidates_rejection_breakdown
          .candidates_rejected_reasons
      ) {
        reasons =
          this.projectSuccessData.data[this.date].candidates_rejection_breakdown
            .candidates_rejected_reasons;
      }
      return [
        {
          reason: "COMPANY_FIT",
          count: reasons.COMPANY_FIT_COUNT,
        },
        {
          reason: "COMPENSATION",
          count: reasons.COMPENSATION_COUNT,
        },
        {
          reason: "MISCLASSIFICATION",
          count: reasons.MISCLASSIFICATION_COUNT,
        },
        {
          reason: "OTHER",
          count: reasons.OTHER_COUNT,
        },
        {
          reason: "REMOTE_POLICY",
          count: reasons.REMOTE_POLICY_COUNT,
        },
        {
          reason: "SENIORITY",
          count: reasons.SENIORITY_COUNT,
        },
        {
          reason: "TIMING",
          count: reasons.TIMING_COUNT,
        },
        {
          reason: "VISA",
          count: reasons.VISA_COUNT,
        },
      ];
    },
    series() {
      let reasons = {};
      if (
        this.projectSuccessData.data &&
        this.projectSuccessData.data[this.date] &&
        this.projectSuccessData.data[this.date]
          .candidates_rejection_breakdown &&
        this.projectSuccessData.data[this.date].candidates_rejection_breakdown
          .candidates_rejected_reasons
      ) {
        reasons =
          this.projectSuccessData.data[this.date].candidates_rejection_breakdown
            .candidates_rejected_reasons;
      }
      let arr = [
        reasons.COMPANY_FIT_COUNT,
        reasons.COMPENSATION_COUNT,
        reasons.MISCLASSIFICATION_COUNT,
        reasons.OTHER_COUNT,
        reasons.REMOTE_POLICY_COUNT,
        reasons.SENIORITY_COUNT,
        reasons.TIMING_COUNT,
        reasons.VISA_COUNT,
      ];
      arr = arr.filter((el) => el !== 0);
      return arr;
    },
    chartOptions() {
      var arr = [];
      this.rejectItems.forEach((elem) => {
        if (elem.count !== 0) {
          arr.push(elem.reason);
        }
      });
      return {
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        chart: {
          type: "pie",
        },
        labels: arr,
        colors: [
          "#00d2bc",
          "#ffa701",
          "#00aada",
          "#6e7d7d",
          "#e0e0e0",
          "#2596be",
          "#1e7898",
          "#1e7898",
        ],
        legend: {
          fontSize: "12px",
        },
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: false,
          },
          style: {
            colors: ["#111827"],
          },
          background: {
            foreColor: "#0000",
          },
        },
      };
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 100);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";
.table-box {
  padding: 16px 20px;
  border: 1px solid $border-color;
  border-radius: 4px;
  width: 50%;
  overflow-y: auto;
  // height: 372px;
  max-height: 100%;
  &__title {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: $black;
    text-transform: uppercase;
  }
}
.table-flex-box {
  display: flex;
  gap: 16px;
}

::v-deep {
  .apexcharts-tooltip {
    color: #000000 !important;
  }
  .apexcharts-legend-series {
    margin-bottom: 14px !important;
  }
  th {
    font-size: 12px;
    font-weight: 600 !important;
    color: $black !important;
    border-bottom: none !important;
  }
  td {
    // border: 1px solid $border-color !important;
    font-size: 11px !important;
    color: $gray-dark;
    height: 20px !important;
    line-height: 20px !important;
  }
  td:nth-child(2) {
    border-right: 1px solid $border-color !important;
  }
  td:nth-child(1) {
    border-left: 1px solid $border-color !important;
  }
  tr:first-child {
    td {
      border-top: 1px solid $border-color !important;
    }
  }
  tr:last-child {
    td {
      border-bottom: 1px solid $border-color !important;
    }
  }
}
.no-data {
  font-size: 13px;
  color: $gray-dark;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-align: center;
  margin-top: 100px;
}
::v-deep {
  .apexcharts-legend-series {
    margin-bottom: 14px !important;
  }
  .apexcharts-legend {
    max-width: 50%;
    width: 100%;
  }
  .apexcharts-legend-series {
    position: relative;
    padding-left: 20px;
  }
  .apexcharts-legend-series .apexcharts-legend-marker {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .apexcharts-legend-text {
    display: block;
  }
}
</style>
