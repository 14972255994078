import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../pages/Home.vue";
import ExportPage from "../pages/Export.vue";
import OperatorAuth from "../components/OperatorAuth";
import SubscriptionPage from "../pages/Subscription";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/export",
    name: "export",
    component: ExportPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/subscription",
    name: "subscription",
    component: SubscriptionPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: OperatorAuth,
  },
  {
    path: "*",
    name: "404",
    component: Home,
    meta: { requiresAuth: true },
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!localStorage.getItem("Operator-X-Parse-Session-Token")) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
