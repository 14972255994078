<template>
  <div class="dropdown-container" :class="{ 'menu-open': value }">
    <div class="dropdown-btn" @click="onClickButton">
      <slot name="button"></slot>
    </div>
    <DropdownList v-if="value" @update="$emit('update', $event)" :value="value">
      <slot></slot>
    </DropdownList>
  </div>
</template>
<script>
import DropdownList from "./DropdownList";

export default {
  name: "CustomDropdown",
  props: ["value"],
  components: {
    DropdownList,
  },
  methods: {
    onClickButton() {
      setTimeout(() => {
        this.$emit("update", !this.value);
      }, 10);
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown {
  &-container {
    position: relative;
    z-index: 1;
    &.menu-open {
      z-index: 9;
    }
  }
}
</style>
