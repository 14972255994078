<template>
  <div class="home" @mousewheel="scroll">
    <div v-if="isLogined">
      <Operations
        @filter-projects-companies="filterProjectCompanies"
        @filter-projects-status="filterProjectStatus"
        @filter-projects-owners="filterProjectOwner"
        @open-menu="openMenu"
        :filter-status-counts="filterStatusCounts"
        :close-menu="closeMenu"
        :defaultStatus="defaultStatus"
        :filterProjectId="filterProjectId"
        @updateFilterProjectId="filterProjectId = $event"
      />
      <div class="sticky-overlay"></div>
      <div class="d-flex home-container">
        <Sidebar
          :filterProjectId="filterProjectId"
          :filtred-companies="filtredCompanies"
          :active-status="activeStatus"
          :filtred-owners="filtredOwners"
          @change-project="changeProject"
          @update-status-counts="updateStatusCounts"
          @change-bip="changeInfo"
          @clear="activeProject = {}"
          :projectId="projectId"
        />
        <Body
          :active-project="activeProject"
          @refresh="refreshProject(activeProject.project_id)"
          @change-project-info="changeInfo"
          @change-trial="changeFreeTrial"
        />
      </div>
    </div>
    <OperatorAuth v-else />
  </div>
</template>

<script>
import Operations from "@/components/Operations.vue";
import OperatorAuth from "@/components/OperatorAuth";
import Sidebar from "@/components/Sidebar.vue";
import Body from "@/components/Body.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { Operations, Sidebar, Body, OperatorAuth },
  name: "Home-page",
  data() {
    return {
      activeProject: {},
      activeStatus: "",
      filtredCompanies: [],
      filtredOwners: [],
      filterStatusCounts: {},
      closeMenu: false,
      defaultStatus: "",
      projectId: "",
      filterProjectId: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["isLogined"]),
    ...mapGetters("operations", ["dashboardSuccessData"]),
  },
  methods: {
    ...mapActions("operations", [
      "getDashboardData",
      "getProjectInfo",
      "getChangeLogData",
      "changeProjectInfo",
      "refreshProject",
    ]),
    changeProject(project) {
      this.activeProject = project;
      if (project.project_id) {
        this.getProject(project.project_id);
        this.getChangeLogData(project.project_id);
      }
      // // For test
      //this.getChangeLogData("62d85244809303af8d460cb3");
    },
    filterProjectStatus(status) {
      this.activeStatus = status;
    },
    filterProjectCompanies(companies) {
      this.filtredCompanies = companies;
    },
    filterProjectOwner(owners) {
      this.filtredOwners = owners;
    },
    getProject(id) {
      this.getProjectInfo(id);
    },
    updateStatusCounts(v) {
      this.filterStatusCounts = v;
    },
    changeInfo(payload) {
      this.changeProjectInfo(payload).then(() => {
        this.getDashboardData();
      });
    },
    changeFreeTrial(payload) {
      setTimeout(() => {
        this.changeProjectInfo(payload).then((res) => {
          this.getDashboardData();
          this.defaultStatus = res.data.operations_status;
          this.projectId = res.data.project_id;
        });
      }, 500);
    },
    scroll() {
      this.closeMenu = true;
    },
    openMenu() {
      this.closeMenu = false;
    },
  },
  created() {
    // if (!this.dashboardSuccessData) {
    //   this.getDashboardData();
    // }
    this.getDashboardData();
  },
  watch: {
    isLogined(v) {
      if (v) {
        this.getDashboardData();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/main.scss";
.home-container {
  display: flex;
  margin-left: 36px;
  margin-right: 36px;
  gap: 24px;
}
.sticky-overlay {
  position: sticky;
  top: 60px;
  height: 29px;
  margin-top: -24px;
  width: 100%;
  z-index: 2;
  background: #fff;
}
</style>
