<template>
  <v-tooltip color="#F3F4F6" top :disabled="!isShowTooltip">
    <template v-slot:activator="{ on, attrs }">
      <div
        class="sidebar__body--item__content--item__name"
        v-bind="attrs"
        v-on="on"
      >
        {{ name }}
      </div>
    </template>
    <span>{{ name }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: "SidebarTitleItem",
  props: {
    name: String,
  },
  data() {
    return {
      max: 16,
    };
  },
  computed: {
    isShowTooltip() {
      return this.name.length > this.max;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/main.scss";
.sidebar__body--item__content--item__name {
  font-size: 14px;
  font-weight: 500;
  color: $black;
  padding-left: 7px;
  width: 125px;
  max-width: 125px;
  min-width: 125px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.sidebar__body--item__content--item-active {
  .sidebar__body--item__content--item__name {
    color: #fff;
    font-weight: 700;
  }
}
</style>
