var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar mt-2",attrs:{"id":"sidebar"}},[_c('div',{staticClass:"sidebar-sticky"},[_c('div',{staticClass:"sidebar__header"},[_c('div',{staticClass:"sidebar__header--title"},[_vm._v(" Companies / "+_vm._s(_vm.statusOptions[_vm.activeStatus])+" Projects ")]),_vm._m(0)]),_c('div',{staticClass:"sidebar__body"},[_c('div',{staticClass:"sidebar__body--container",class:{ 'sidebar__body--loader-box': _vm.dashboardLoading }},[(_vm.dashboardLoading)?_c('v-progress-circular',{staticClass:"custom-loader",attrs:{"indeterminate":"","color":"primary"}}):_vm._l((_vm.filtredItemsiesList),function(item,i){return _c('div',{key:("item_" + i),staticClass:"sidebar__body--item"},[(item.length)?_c('div',{staticClass:"sidebar__body--item__title"},[_vm._v(" "+_vm._s(i)+" ")]):_vm._e(),(item.length)?_c('div',{staticClass:"sidebar__body--item__content"},_vm._l((item),function(project,j){return _c('div',{key:("project_" + j),staticClass:"sidebar__body--item__content--item",class:{
                  'sidebar__body--item__content--item-active':
                    project.project_id === _vm.activeProject.project_id,
                },on:{"click":function($event){return _vm.changeActiveProject(project)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":project.isActive
                        ? '#fff'
                        : project.isAutopilot
                        ? '#4646AB'
                        : '#1DA1F2',"size":"16"}},[_vm._v(" "+_vm._s(project.isAutopilot ? "mdi-steering" : "mdi-compass-outline")+" ")]),(_vm.projectName(project))?_c('SidebarTitleItemVue',{attrs:{"name":_vm.projectName(project)}}):_vm._e()],1),_c('div',{staticClass:"d-flex justify-end align-center"},[_c('div',{staticClass:"sidebar__body--item__content--item__nums-first"},[_vm._v(" "+_vm._s(_vm.lastChange(project))+" ")]),_c('div',{staticClass:"sidebar__body--item__content--item__nums-first"},[_vm._v(" "+_vm._s(_vm.fixedUrgency(project.urgency))+" ")]),_c('div',{staticClass:"sidebar__body--item__content--item__nums"},[_vm._v(" "+_vm._s(project.bip || 0)+" ")]),_c('div',{staticClass:"sidebar__body--item__content--item__icon"},[[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"select-icon",attrs:{"color":"#384955","size":"19"}},'v-icon',attrs,false),on),[_vm._v("mdi-chevron-down")])]}}],null,true)},[_c('v-list',_vm._l((_vm.priorityItems),function(number){return _c('div',{key:number,staticClass:"select-item",class:{ 'is-active': project.bip === number },on:{"click":function($event){return _vm.changeBip(number)}}},[_vm._v(" "+_vm._s(number)+" ")])}),0)],1)],1)]],2)])])}),0):_vm._e()])})],2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar__header--buttons mt-3"},[_c('span',{staticClass:"sidebar__header--texts mr-2"},[_vm._v("Last Updated")]),_c('span',{staticClass:"sidebar__header--texts mr-2"},[_vm._v("Urgency")]),_c('span',{staticClass:"sidebar__header--texts mr-4"},[_vm._v("Priority")])])}]

export { render, staticRenderFns }