<template>
  <vue-apexchart
    type="line"
    height="200"
    :options="chartOptions"
    :series="series"
  ></vue-apexchart>
</template>

<script>
export default {
  name: "CustomLineChart",
  props: {
    chartOptions: {
      type: Object,
      default: () => {},
    },
    series: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/main.scss";
::v-deep {
  .apexcharts-xaxis-label {
    font-size: 11px;
  }
  .apexcharts-legend-text {
    color: $gray-dark !important;
    font-size: 11px !important;
  }
  .apexcharts-legend-series {
    margin-bottom: 14px !important;
  }
}
</style>
