var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('div',{staticClass:"table-flex-box"},[_c('div',{staticClass:"table-box"},[_c('div',{staticClass:"table-box__title"},[_vm._v("Hiring Manager Acceptance")]),_c('v-data-table',{staticClass:"table-box__table",attrs:{"headers":_vm.acceptanceHeaders,"items":_vm.hmAcceptance,"items-per-page":10,"hide-default-footer":_vm.hmAcceptance.length <= 10,"disable-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"table-box__candidate-name",attrs:{"href":item.linkedin_profile_url,"target":"_blanck"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.approval_score",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.approval_score ? item.approval_score : "N/A")+" ")]}},{key:"item.approval_score_other",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.approval_score_other ? item.approval_score_other : "N/A")+" ")]}},{key:"item.rating",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.rating)+"% ")]}},{key:"header.approval_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"sort-header",on:{"click":_vm.onSort}},[_c('v-btn',{attrs:{"icon":""}},[(!_vm.isSort)?_c('v-icon',[_vm._v("mdi-arrow-down")]):_vm._e(),(_vm.isSort)?_c('v-icon',[_vm._v("mdi-arrow-up")]):_vm._e()],1),_vm._v(" Approval Date ")],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }