<template>
  <div class="tabs-header">
    <v-tabs v-model="tab">
      <v-tab @click="goToHomePage">Operations Dashboard</v-tab>
      <v-tab @click="goToExportPage">Export Data</v-tab>
      <v-tab @click="goToSubscriptionPage">Subscription Summary</v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: "HeaderTab",
  data() {
    return {
      tab: 0,
    };
  },
  mounted() {
    if (this.$route.path === "/export") {
      this.tab = 1;
    }
    if (this.$route.path === "/subscription") {
      this.tab = 2;
    }
  },
  methods: {
    goToExportPage() {
      if (this.$route.path !== "/export") {
        this.$router.push("/export");
      }
    },
    goToHomePage() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
    goToSubscriptionPage() {
      if (this.$route.path !== "/subscription") {
        this.$router.push("/subscription");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs-header {
  display: flex;
  max-width: 890px;
  margin: 0 auto;
  position: sticky;
  top: 22px;
  z-index: 99;
  width: 100%;

  &::v-deep {
    .v-tabs {
      width: auto;
      flex: none;
    }
    .theme--light.v-tabs > .v-tabs-bar {
      background-color: transparent;
      height: 40px;
    }
    .v-tab {
      color: #fff !important;
      font-size: 14px;
      font-weight: 600;
      padding: 12px 16px 10px;
      line-height: 14px;
      border-radius: 8px 8px 0 0;
    }
    .v-tab--active {
      color: #384955 !important;
      background: #fff;
    }
    .v-tabs-slider-wrapper {
      display: none;
    }
  }
}
</style>
