<template>
  <vue-apexchart
    type="pie"
    width="100%"
    :options="chartOptions"
    :series="series"
  ></vue-apexchart>
</template>

<script>
export default {
  name: "CustomPieChart",
  props: {
    chartOptions: {
      type: Object,
      default: () => {},
    },
    series: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .apexcharts-tooltip {
    color: #000000 !important;
  }
  .apexcharts-legend-series {
    margin-bottom: 14px !important;
  }
  .apexcharts-legend {
    max-width: 50%;
    width: 100%;
  }
  .apexcharts-legend-series {
    position: relative;
    padding-left: 20px;
  }
  .apexcharts-legend-series .apexcharts-legend-marker {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .apexcharts-legend-text {
    display: block;
  }
}
</style>
